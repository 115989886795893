import React, { useState, useEffect } from "react";
import { CgCalendarToday } from "react-icons/cg";
import { FaPen, FaUserCheck } from "react-icons/fa";
import { IoClose, IoCloseCircle, IoColorPaletteSharp } from "react-icons/io5";
import { MdEditNote, MdEventRepeat, MdOutlineRepeatOn } from "react-icons/md";
import CheckAuth from "../components/ServiceFunctions/CheckAuth";
import "./admin.css";
import secureLocalStorage from "react-secure-storage";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import { IoIosArrowDroprightCircle } from "react-icons/io";

const AdminCalender = ({ adminCalender }) => {
  const { isAuthenticated, email, phone, pan } = CheckAuth();
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [coupons, setCoupons] = useState([]);
  const [holidays, setHolidays] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [formData, setFormData] = useState({
    title: "",
    date: new Date().toISOString().slice(0, 10),
    deputed: "",
    description: "",
  });

  useEffect(() => {
    const date = new Date();
    setCurrentDate(date);
    setCurrentMonth(date.getMonth());
    setCurrentYear(date.getFullYear());

    setFormData({
      ...formData,
      date: date.toISOString().slice(0, 10),
    });
  }, []);

  function formattedDate(date) {
    var newDate = new Date(date);
    const options = { day: "2-digit", month: "short", year: "2-digit" };
    newDate = newDate.toLocaleDateString("en-IN", options).replace(" ", "-");
    const formattedDate2 = newDate.replace(" ", "-");
    return formattedDate2;
  }

  const changeMonth = (direction) => {
    const newDate = new Date(currentYear, currentMonth + direction, 1);
    setCurrentMonth(newDate.getMonth());
    setCurrentYear(newDate.getFullYear());
  };

  const selectDate = (day) => {
    const newDate = new Date(currentYear, currentMonth, day);

    // Set events, coupons, and holidays
    const selectedDateStr = newDate.toLocaleDateString("en-CA");
    const couponsOnDate = [];
    const holidaysOnDate = [];

    adminCalender.forEach((event) => {
      if (event.National_holidays) {
        Object.keys(event.National_holidays).forEach((val) => {
          if (new Date(val).toLocaleDateString("en-CA") === selectedDateStr) {
            holidaysOnDate.push({
              date: val,
              name: event.National_holidays[val],
            });
          }
        });
      } else if (event.title === "Next Coupon Payment Dates") {
        if (event.dates.includes(selectedDateStr)) {
          couponsOnDate.push({
            date: selectedDateStr,
            name: "Coupon Payment Day",
          });
        }
      }
    });

    setCoupons(couponsOnDate);
    setHolidays(holidaysOnDate);

    setCurrentDate(newDate);

    // Create a new Date object for the next day
    const nextDay = new Date(newDate);
    nextDay.setDate(nextDay.getDate() + 1);

    setFormData({
      ...formData,
      date: nextDay.toISOString().slice(0, 10),
    });
  };

  const goToToday = () => {
    const today = new Date();
    setCurrentDate(today);
    setCurrentMonth(today.getMonth());
    setCurrentYear(today.getFullYear());
    setFormData({
      ...formData,
      date: today.toISOString().slice(0, 10),
    });
  };

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSave = async () => {
    fetch(`https://api-scdndestates.com/api/set-event-and-reminders/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        phone,
        formData,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to save event/reminder");
        }
        return response.json();
      })
      .then((response) => {
        if (response.message === "Success") {
          secureLocalStorage.setItem(
            "admin-calender",
            JSON.stringify(response.dates)
          );
          window.location.reload();
        }
      })
      .catch((error) => {
        // console.error("Error during logout:", error);
      });
  };

  const renderDays = () => {
    let firstDayOfMonth = new Date(currentYear, currentMonth, 1).getDay();
    if (firstDayOfMonth === 0) firstDayOfMonth = 7;

    const lastDateOfMonth = new Date(
      currentYear,
      currentMonth + 1,
      0
    ).getDate();
    const lastDateOfPrevMonth = new Date(
      currentYear,
      currentMonth,
      0
    ).getDate();

    const days = [];
    for (let i = firstDayOfMonth - 2; i >= 0; i--) {
      days.push(
        <div
          key={`prev-${i}`}
          className="w-full text-center text-sm md:text-base 2xl:text-lg py-1 font-bold my-1 mx-2 cursor-pointer next-month"
        >
          {lastDateOfPrevMonth - i}
        </div>
      );
    }

    const isDateHighlighted = (date) => {
      const dates = [];
      if (adminCalender) {
        adminCalender.forEach((event) => {
          if (event.National_holidays) {
            Object.keys(event.National_holidays).forEach((val) => {
              dates.push(val);
            });
          }
        });
      }
      let dateStr = date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
      dateStr = dateStr.toLocaleString().replace(", ", " ");
      return dates.includes(dateStr);
    };

    const couponHighlighted = (date) => {
      const couponDates = [];
      if (adminCalender) {
        adminCalender.forEach((event) => {
          if (event.title === "Next Coupon Payment Dates") {
            event.dates.forEach((val) => {
              couponDates.push(val);
            });
          }
        });
      }
      let dateStr = date.toLocaleDateString("en-CA");
      return couponDates.includes(dateStr);
    };

    const eventHighlighted = (date) => {
      let eventColors = [];

      if (adminCalender) {
        adminCalender.forEach((event) => {
          if (event.title !== "Next Coupon Payment Dates") {
            if (event.Start_date && event.End_date) {
              const startDate = new Date(event.Start_date);
              const endDate = new Date(event.End_date);
              const current = new Date(startDate);

              while (current <= endDate) {
                if (
                  current.toLocaleDateString("en-CA") ===
                  date.toLocaleDateString("en-CA")
                ) {
                  eventColors.push(event.Color || "");
                  break;
                }
                current.setDate(current.getDate() + 1);
              }
            }
          }
        });
      }

      return eventColors;
    };

    // Inside the rendering loop
    for (let i = 1; i <= lastDateOfMonth; i++) {
      const date = new Date(currentYear, currentMonth, i);
      const isSunday = date.getDay() === 0;
      const isHighlighted = isDateHighlighted(date);
      const coupon = couponHighlighted(date);
      const eventColors = eventHighlighted(date); // Get all event colors

      days.push(
        <div
          key={`current-${i}`}
          className={`w-full border-2 hover:border-[#1DA6C6] text-center text-sm md:text-base 2xl:text-lg relative py-1 font-bold my-1 mx-2 cursor-pointer rounded-lg ${
            i === currentDate.getDate() &&
            currentMonth === currentDate.getMonth() &&
            currentYear === currentDate.getFullYear()
              ? "border-2 border-[#1DA6C6] shadow-md"
              : ""
          } ${isSunday && "sunday"} ${
            isSunday &&
            i === currentDate.getDate() &&
            currentMonth === currentDate.getMonth() &&
            currentYear === currentDate.getFullYear() &&
            "border-2 border-[#DC4C64] shadow-md"
          }`}
          onClick={() => {
            selectDate(i);
            if (eventColors.length || coupon || isHighlighted) {
              setShow2(true);
            }
          }}
        >
          {isHighlighted && <div className="highlighted"></div>}
          {coupon && <div className="coupon-highlighted"></div>}
          {eventColors.length > 0 && (
            <div className="events-highlighted">
              {eventColors.map((color, index) => (
                <div key={index} className={`event-dot ${color}`}></div>
              ))}
            </div>
          )}
          {i}
        </div>
      );
    }

    const extraDays = 7 - (days.length % 7);
    for (let i = 1; i <= extraDays && extraDays < 7; i++) {
      days.push(
        <div
          key={`next-${i}`}
          className="w-full text-center text-sm md:text-base 2xl:text-lg py-1 font-bold my-1 mx-2 cursor-pointer next-month rounded-lg "
        >
          {i}
        </div>
      );
    }

    return days;
  };

  return (
    <>
      {show && (
        <>
          <div className="demo2-container flex justify-center items-center rounded-lg bg-gray-100 p-4">
            <div
              className="bg-white  rounded-xl shadow-lg overflow-hidden pop-up-animation transform transition-transform duration-300 ease-in-out"
              style={{
                width: "400px",
              }}
            >
              <div className="flex items-center justify-between px-4 py-2 border-b bg-[#0284C7] ">
                <div className="text-white font-semibold text-lg">
                  Add Events
                </div>
                <IoClose
                  className="p-1 bg-white rounded-full text-red-600 hover:bg-red-100 hover:scale-95 transition-transform duration-300 cursor-pointer"
                  size={24}
                  onClick={() => {
                    setShow(false);
                  }}
                />
              </div>

              <div className="flex items-center px-4 py-2 gap-2 text-[#212121] font-medium text-base mt-2">
                <img
                  className="w-8 h-8"
                  src="/images/title-admin.png"
                  alt=""
                />
                <input
                  type="text"
                  value={formData.title}
                  className="w-full border rounded-lg px-3 py-1.5 focus:outline-none focus:ring-2 focus:ring-blue-400"
                  placeholder="Add Title"
                  name="title"
                  onChange={handleInputChange}
                />
              </div>

              <div className="flex items-center px-4 py-2 gap-2 text-[#212121] font-medium text-base">
                <img
                  className="w-8 h-8 "
                  src="/images/calen-admin.png"
                  alt=""
                />
                <input
                  type="date"
                  value={formData.date}
                  className="border rounded-lg px-3 py-1.5 w-full focus:outline-none focus:ring-2 focus:ring-blue-400"
                  name="date"
                  onChange={handleInputChange}
                />
              </div>

              <div className="flex items-center px-4 py-2 gap-2 text-[#212121] font-medium text-base">
                <img
                  className="w-8 h-8"
                  src="/images/assign-admin.png"
                  alt=""
                />
                <input
                  type="text"
                  value={formData.deputed}
                  className="w-full border rounded-lg px-3 py-1.5 focus:outline-none focus:ring-2 focus:ring-blue-400"
                  placeholder="Assigned to"
                  name="deputed"
                  onChange={handleInputChange}
                />
              </div>

              <div className="flex items-center px-4 py-2 gap-2 text-[#212121] font-medium text-base">
                <img className="w-8 h-8" src="/images/desc-admin.png" alt="" />
                <input
                  type="text"
                  value={formData.description}
                  className="w-full border rounded-lg px-3 py-1.5 focus:outline-none focus:ring-2 focus:ring-blue-400"
                  placeholder="Describe here..."
                  name="description"
                  onChange={handleInputChange}
                />
              </div>

              <div className="flex justify-end px-4 py-2 mb-2">
                <button
                  className="bg-sky-400 font-medium text-base text-white  rounded-md px-3 py-1.5 hover:bg-sky-500 transition-colors duration-200"
                  onClick={() => {
                    setShow(false);
                    handleSave();
                  }}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      {show2 && (
        <>
          <div className="popup-container">
            <div className="flex flex-col justify-center items-center relative bg-white border p-4 rounded-lg pop-up-animation w-[500px]">
              <img src="/images/contact-popup.png" alt="" className="w-32" />
              <div className="flex flex-col items-center text-base font-bold mt-2 gap-2">
                {/* events */}
                {/* <div className="w-[450px]">
                  <div className="flex items-center px-2.5 text-black text-base xl:text-lg font-bold rounded-t-lg">
                    <span>Events</span>
                  </div>
                  <div className="overflow-y-scroll p-2  max-h-[260px] flex flex-col gap-2">
                    {events.length > 0 ? (
                      events.map((event, index) => (
                        <>
                          <div
                            key={index}
                            className="flex justify-between items-center px-2 w-full py-1 max-h-[250px]"
                          >
                            <div className="flex justify-start items-center">
                              <span
                                className={`${event.color} h-3 w-3 border rounded-full`}
                              ></span>
                              <span className="text-sm text-primary font-bold  mx-1">
                                {formattedDate(event.date)} :&nbsp;
                              </span>
                              <span className="text-sm font-bold">
                                {event.name}
                              </span>
                            </div>
                            <span
                              className="text-danger cursor-pointer"
                              onClick={() => handleEvent(event.name)}
                            >
                              <IoCloseCircle size={20} />
                            </span>
                          </div>
                          <div className="flex justify-start items-center px-2">
                            <span className="text-xs text-[#8c8c8c] font-bold  mx-1">
                              Deputed to :&nbsp;
                            </span>
                            <span className="text-xs text-[#8c8c8c]">
                              {event.deputed}
                            </span>
                          </div>
                          <div className="flex justify-start items-center px-2">
                            <span className="text-xs text-[#8c8c8c] font-bold  mx-1">
                              Description :&nbsp;
                            </span>
                            <span className="text-xs text-[#8c8c8c]">
                              {event.desc}
                            </span>
                          </div>
                        </>
                      ))
                    ) : (
                      <div className="flex text-[#9c9c9c] text-xs py-1 justify-between items-center pl-2 h-full">
                        No Events
                      </div>
                    )}
                  </div>
                </div> */}
                {/*  */}
                {/* holidays */}
                <div className="w-[450px]">
                  <div className="flex items-center px-2.5 text-black text-base xl:text-lg font-bold rounded-t-lg">
                    <span className="h-3 w-3 bg-[#DC4C64] border rounded-full  mr-3"></span>
                    <span>Holidays</span>
                  </div>
                  <div
                    className="overflow-y-scroll p-2  max-h-[260px] flex flex-col gap-2 
border rounded-md"
                  >
                    {holidays.length > 0 ? (
                      holidays.map((holiday, index) => (
                        <div
                          key={index}
                          className="flex justify-between items-center pl-2 h-full"
                        >
                          <div className="flex justify-start items-center w-full py-1 max-h-[250px]">
                            <span className="text-xs text-primary font-bold mr-2">
                              {formattedDate(holiday.date)} :&nbsp;
                            </span>
                            <span className="text-xs  font-bold">
                              {holiday.name}
                            </span>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="flex py-1 text-[#9c9c9c] text-xs justify-between items-center pl-2 h-full">
                        No Holidays
                      </div>
                    )}
                  </div>
                </div>
                {/*  */}
                {/* coupons */}
                <div className="w-[450px]">
                  <div className="flex items-center px-2.5 text-black text-base xl:text-lg font-bold rounded-t-lg">
                    <span className="h-3 w-3 bg-[#4cdc94] border rounded-full  mr-3"></span>
                    <span>Coupons</span>
                  </div>
                  <div
                    className="overflow-y-scroll p-2 max-h-[260px] flex flex-col gap-2 
border rounded-md"
                  >
                    {coupons.length > 0 ? (
                      coupons.map((coupon, index) => (
                        <div
                          key={index}
                          className="flex justify-between items-center pl-2 h-full"
                        >
                          <div className="flex justify-start items-center w-full py-1 max-h-[250px]">
                            <span className="text-xs text-primary font-bold">
                              {formattedDate(coupon.date)} : &nbsp;
                            </span>
                            <span className="text-xs font-bold">
                              {coupon.name}
                            </span>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="flex py-1 text-[#9c9c9c] text-xs justify-between items-center pl-2 h-full">
                        No Coupons
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* Close button */}
              <span
                className="absolute top-3 right-3 hover:bg-zinc-200 p-1 rounded-full duration-200 cursor-pointer"
                onClick={() => setShow2(false)}
              >
                <span className="text-lg text-black">
                  <IoCloseCircle />
                </span>
              </span>
            </div>
          </div>
        </>
      )}

      <div className="admin-calender border shadow-md rounded-lg h-fit min-h-[400px]">
        <div className="flex flex-wrap items-center justify-between rounded-t-lg bg-[#1DA6C6] px-2 xl:px-3 py-2.5">
          {/* Calender Head */}
          <div className="flex justify-between items-center min-w-[200px] xl:w-[40%]">
            <div
              className="rounded-full h-fit cursor-pointer text-white text-lg xl:text-xl flex items-center justify-center scale-100 hover:scale-[95%] duration-100 ease-in-out"
              onClick={() => changeMonth(-1)}
            >
              <IoIosArrowDropleftCircle />
            </div>

            <div className="text-lg xl:text-xl font-bold text-white">{`${new Intl.DateTimeFormat(
              "en-us",
              {
                month: "long",
              }
            ).format(
              new Date(currentYear, currentMonth)
            )} ${currentYear}`}</div>

            <div
              className="rounded-full h-fit cursor-pointer text-white text-lg xl:text-xl flex items-center justify-center scale-100 hover:scale-[95%] duration-100 ease-in-out"
              onClick={() => changeMonth(1)}
            >
              <IoIosArrowDroprightCircle />
            </div>
          </div>

          <div className="flex gap-4 items-center justify-between text-black">
            <div
              className="flex items-center h-9 w-auto xl:w-[100px] bg-zinc-100 px-1.5 py-1 justify-between gap-1 rounded-md cursor-pointer scale-100 hover:scale-[95%] duration-100 ease-in-out"
              onClick={goToToday}
            >
              <span>
                <svg
                  id="fi_12113063"
                  viewBox="0 0 512 512"
                  xmlns="http://www.w3.org/2000/svg"
                  data-name="Layer 1"
                  className="w-6 xl:w-7 h-fit"
                >
                  <g>
                    <path
                      d="m462.51 53.469h-44.246v-30.469c0-8.284-6.716-15-15-15s-15 6.716-15 15v30.469h-117.264v-30.469c0-8.284-6.716-15-15-15s-15 6.716-15 15v30.469h-117.264v-30.469c0-8.284-6.716-15-15-15s-15 6.716-15 15v30.469h-44.274c-18.916 0-34.305 15.389-34.305 34.305v381.922c0 18.916 15.389 34.305 34.305 34.305h413.048c18.932 0 34.333-15.389 34.333-34.305v-381.923c0-18.916-15.401-34.305-34.333-34.305z"
                      fill="#e4e9f4"
                    ></path>
                    <path
                      d="m462.51 53.469h-44.246v59.767c0 8.284-6.716 15-15 15s-15-6.716-15-15v-59.767h-117.264v59.767c0 8.284-6.716 15-15 15s-15-6.716-15-15v-59.767h-117.264v59.767c0 8.284-6.716 15-15 15s-15-6.716-15-15v-59.767h-44.274c-18.916 0-34.305 15.389-34.305 34.305v103.552h481.686v-103.552c0-18.916-15.401-34.305-34.333-34.305z"
                      fill="#ff7e8e"
                    ></path>
                    <path
                      d="m466.843 191.327v278.368c0 18.916-15.401 34.305-34.333 34.305h30c18.932 0 34.333-15.389 34.333-34.305v-278.368z"
                      fill="#d1dbed"
                    ></path>
                    <path
                      d="m462.51 53.469h-30c18.932 0 34.333 15.389 34.333 34.305v52.241c0 14.936-12.108 27.044-27.044 27.044h-424.642v24.267h451.686v.002h30v-103.554c0-18.916-15.401-34.305-34.333-34.305z"
                      fill="#fb5f7a"
                    ></path>
                    <g fill="#615260">
                      <path d="m108.736 8c-8.284 0-15 6.716-15 15v90.235c0 8.284 6.716 15 15 15s15-6.716 15-15v-90.235c0-8.284-6.716-15-15-15z"></path>
                      <path d="m256 8c-8.284 0-15 6.716-15 15v90.235c0 8.284 6.716 15 15 15s15-6.716 15-15v-90.235c0-8.284-6.716-15-15-15z"></path>
                      <path d="m403.264 8c-8.284 0-15 6.716-15 15v90.235c0 8.284 6.716 15 15 15s15-6.716 15-15v-90.235c0-8.284-6.716-15-15-15z"></path>
                    </g>
                    <g fill="#fb5f7a">
                      <path d="m123.736 107.448v5.788c0 8.284-6.716 15-15 15s-15-6.716-15-15v-5.789c-7.831.501-14.032 6.994-14.032 14.951 0 8.284 6.716 15 15 15h28.035c8.284 0 15-6.716 15-15 0-7.948-6.186-14.434-14.003-14.95z"></path>
                      <path d="m418.264 107.448v5.788c0 8.284-6.716 15-15 15s-15-6.716-15-15v-5.788c-7.817.515-14.003 7.002-14.003 14.95 0 8.284 6.716 15 15 15h28.007c8.284 0 15-6.716 15-15 0-7.948-6.187-14.435-14.004-14.95z"></path>
                      <path d="m271 107.448v5.788c0 8.284-6.716 15-15 15s-15-6.716-15-15v-5.789c-7.831.501-14.031 6.994-14.031 14.951 0 8.284 6.716 15 15 15h28.035c8.284 0 15-6.716 15-15 0-7.948-6.187-14.435-14.004-14.95z"></path>
                    </g>
                  </g>
                  <path
                    d="m235.69 401.697c-3.843 0-7.682-1.467-10.607-4.394l-40.679-40.678c-5.858-5.857-5.858-15.355 0-21.213 5.857-5.858 15.355-5.858 21.213 0l30.285 30.285 70.697-67.887c5.976-5.739 15.472-5.545 21.209.43 5.738 5.975 5.546 15.471-.43 21.208l-81.3 78.068c-2.905 2.79-6.648 4.181-10.389 4.181z"
                    fill="#615260"
                  ></path>
                </svg>
              </span>
              <span className="text-[11px] xl:text-xs  font-semibold duration-200">
                <p className="leading-3">Today</p>
                <p>Date</p>
              </span>
            </div>

            <div
              className="flex items-center h-9 w-auto xl:w-[100px] bg-zinc-100 px-1.5 py-1 justify-between gap-1 rounded-md cursor-pointer scale-100 hover:scale-[95%] duration-100 ease-in-out"
              onClick={() => setShow(true)}
            >
              <span>
                <svg
                  id="fi_10691802"
                  viewBox="0 0 512 512"
                  xmlns="http://www.w3.org/2000/svg"
                  data-name="Layer 1"
                  className="w-6 xl:w-7 h-fit"
                >
                  <path
                    d="m455.667 53.932h-48.039v-21.452c0-10.923-11.5-19.48-26.17-19.48-14.691 0-26.2 8.557-26.2 19.48v21.452h-73.058v-21.452c0-10.923-11.51-19.48-26.2-19.48s-26.2 8.557-26.2 19.48v21.452h-73.032v-21.452c0-10.923-11.507-19.48-26.2-19.48s-26.2 8.557-26.2 19.48v21.452h-48.007a31.817 31.817 0 0 0 -31.782 31.781v381.505a31.819 31.819 0 0 0 31.782 31.782h399.306a31.8 31.8 0 0 0 31.754-31.782v-381.505a31.8 31.8 0 0 0 -31.754-31.781zm-84.407-21.332c.49-1.05 4.17-3.6 10.2-3.6 6.012 0 9.682 2.554 10.17 3.6v21.332h-20.37zm-125.46.009c.5-1.053 4.184-3.609 10.2-3.609s9.7 2.556 10.2 3.609v21.323h-20.4zm-125.43 0c.5-1.053 4.183-3.609 10.2-3.609s9.7 2.556 10.2 3.609v21.323h-20.4z"
                    fill="#eae8e8"
                  ></path>
                  <path
                    d="m238.12 205.162v35.914a8 8 0 0 1 -8 8h-40.7a8 8 0 0 1 -8-8v-35.914a8 8 0 0 1 8-8h40.7a8 8 0 0 1 8 8zm84.464-8h-40.7a8 8 0 0 0 -8 8v35.914a8 8 0 0 0 8 8h40.7a8 8 0 0 0 8-8v-35.914a8 8 0 0 0 -8-8zm-184.9 87.871h-40.732a8 8 0 0 0 -8 8v35.914a8 8 0 0 0 8 8h40.733a8 8 0 0 0 8-8v-35.914a8 8 0 0 0 -8-8zm92.435 0h-40.7a8 8 0 0 0 -8 8v35.914a8 8 0 0 0 8 8h40.7a8 8 0 0 0 8-8v-35.914a8 8 0 0 0 -7.999-8zm92.464 0h-40.7a8 8 0 0 0 -8 8v35.914a8 8 0 0 0 8 8h40.7a8 8 0 0 0 8-8v-35.914a8 8 0 0 0 -7.999-8zm-184.9 87.9h-40.731a8 8 0 0 0 -8 8v35.885a8 8 0 0 0 8 8h40.733a8 8 0 0 0 8-8v-35.884a8 8 0 0 0 -8-8zm92.435 0h-40.7a8 8 0 0 0 -8 8v35.885a8 8 0 0 0 8 8h40.7a8 8 0 0 0 8-8v-35.884a8 8 0 0 0 -7.998-8zm-92.433-175.771h-40.733a8 8 0 0 0 -8 8v35.914a8 8 0 0 0 8 8h40.733a8 8 0 0 0 8-8v-35.914a8 8 0 0 0 -8-8zm277.363 87.871h-40.7a8 8 0 0 0 -8 8v35.914a8 8 0 0 0 8 8h40.7a8 8 0 0 0 8-8v-35.914a8 8 0 0 0 -8-8zm-92.464 87.9h-40.7a8 8 0 0 0 -8 8v35.885a8 8 0 0 0 8 8h40.7a8 8 0 0 0 8-8v-35.884a8 8 0 0 0 -8-8z"
                    fill="#484868"
                  ></path>
                  <g fill="#3d3d54">
                    <path d="m137.685 197.162h-10a8 8 0 0 1 8 8v35.914a8 8 0 0 1 -8 8h10a8 8 0 0 0 8-8v-35.914a8 8 0 0 0 -8-8z"></path>
                    <path d="m230.12 372.934h-10a8 8 0 0 1 8 8v35.885a8 8 0 0 1 -8 8h10a8 8 0 0 0 8-8v-35.885a8 8 0 0 0 -8-8z"></path>
                    <path d="m230.12 285.033h-10a8 8 0 0 1 8 8v35.914a8 8 0 0 1 -8 8h10a8 8 0 0 0 8-8v-35.914a8 8 0 0 0 -8-8z"></path>
                    <path d="m137.685 372.934h-10a8 8 0 0 1 8 8v35.885a8 8 0 0 1 -8 8h10a8 8 0 0 0 8-8v-35.885a8 8 0 0 0 -8-8z"></path>
                    <path d="m137.685 285.033h-10a8 8 0 0 1 8 8v35.914a8 8 0 0 1 -8 8h10a8 8 0 0 0 8-8v-35.914a8 8 0 0 0 -8-8z"></path>
                    <path d="m322.584 372.934h-10a8 8 0 0 1 8 8v35.885a8 8 0 0 1 -8 8h10a8 8 0 0 0 8-8v-35.885a8 8 0 0 0 -8-8z"></path>
                    <path d="m322.584 197.162h-10a8 8 0 0 1 8 8v35.914a8 8 0 0 1 -8 8h10a8 8 0 0 0 8-8v-35.914a8 8 0 0 0 -8-8z"></path>
                    <path d="m230.12 197.162h-10a8 8 0 0 1 8 8v35.914a8 8 0 0 1 -8 8h10a8 8 0 0 0 8-8v-35.914a8 8 0 0 0 -8-8z"></path>
                    <path d="m415.048 285.033h-10a8 8 0 0 1 8 8v35.914a8 8 0 0 1 -8 8h10a8 8 0 0 0 8-8v-35.914a8 8 0 0 0 -8-8z"></path>
                    <path d="m322.584 285.033h-10a8 8 0 0 1 8 8v35.914a8 8 0 0 1 -8 8h10a8 8 0 0 0 8-8v-35.914a8 8 0 0 0 -8-8z"></path>
                  </g>
                  <path
                    d="m389.649 246.78a8 8 0 0 1 -5.632-2.318l-16.44-16.3a8 8 0 0 1 11.265-11.362l10.3 10.208 20.939-24.721a8 8 0 0 1 12.209 10.342l-26.532 31.322a8 8 0 0 1 -5.758 2.821c-.119.005-.235.008-.351.008z"
                    fill="#d22e2e"
                  ></path>
                  <path
                    d="m417.446 473.242a8 8 0 0 1 -5.658 2.345h-355.427a31.819 31.819 0 0 1 -31.782-31.782v23.413a31.819 31.819 0 0 0 31.782 31.782h399.306a31.8 31.8 0 0 0 31.754-31.782v-67.293a8 8 0 0 1 -2.342 5.655z"
                    fill="#d1c9c9"
                  ></path>
                  <path
                    d="m487.421 399.925v-1.049h-60.48a16 16 0 0 0 -16 16v60.711h.847a8 8 0 0 0 5.658-2.345l67.633-67.662a8 8 0 0 0 2.342-5.655z"
                    fill="#bcb3b3"
                  ></path>
                  <path
                    d="m455.667 53.932h-84.407v30.282c.47 1.05 4.138 3.619 10.2 3.619a8 8 0 0 1 0 16c-14.691 0-26.2-8.569-26.2-19.509v-30.392h-109.46v30.276c.478 1.054 4.151 3.625 10.2 3.625a8 8 0 0 1 0 16c-14.691 0-26.2-8.569-26.2-19.509v-30.392h-109.428v30.276c.478 1.054 4.151 3.625 10.2 3.625a8 8 0 0 1 0 16c-14.69 0-26.2-8.569-26.2-19.509v-30.392h-48.011a31.817 31.817 0 0 0 -31.782 31.781v68.207h462.842v-68.207a31.8 31.8 0 0 0 -31.754-31.781z"
                    fill="#d22e2e"
                  ></path>
                  <path
                    d="m24.579 153.92h462.842v12.492h-462.842z"
                    fill="#d1c9c9"
                  ></path>
                  <path
                    d="m264 95.833a8 8 0 0 1 -8 8c-14.691 0-26.2-8.569-26.2-19.509v-51.844c0-10.923 11.509-19.48 26.2-19.48s26.2 8.557 26.2 19.48v21.452h-16v-21.323c-.5-1.053-4.185-3.609-10.2-3.609s-9.7 2.556-10.2 3.609v51.6c.478 1.054 4.151 3.625 10.2 3.625a8 8 0 0 1 8 7.999zm-133.43-8c-6.047 0-9.72-2.571-10.2-3.625v-51.6c.5-1.053 4.183-3.609 10.2-3.609s9.7 2.556 10.2 3.609v21.324h16v-21.452c0-10.923-11.507-19.48-26.2-19.48s-26.2 8.557-26.2 19.48v51.844c0 10.94 11.508 19.509 26.2 19.509a8 8 0 0 0 0-16zm250.888 0c-6.06 0-9.728-2.569-10.2-3.619v-51.614c.49-1.05 4.17-3.6 10.2-3.6 6.012 0 9.682 2.554 10.17 3.6v21.332h16v-21.452c0-10.923-11.5-19.48-26.17-19.48-14.691 0-26.2 8.557-26.2 19.48v51.844c0 10.94 11.507 19.509 26.2 19.509a8 8 0 0 0 0-16z"
                    fill="#484868"
                  ></path>
                  <path
                    d="m130.62 80.834a14.926 14.926 0 0 0 -9.949 3.8c1.068 1.194 4.553 3.2 9.9 3.2a8 8 0 0 1 0 16 33.375 33.375 0 0 1 -14.062-2.966 14.992 14.992 0 1 0 14.111-20.034z"
                    fill="#ad1e1e"
                  ></path>
                  <path
                    d="m256 80.834a14.925 14.925 0 0 0 -9.921 3.771c1.039 1.191 4.535 3.228 9.921 3.228a8 8 0 0 1 0 16 33.362 33.362 0 0 1 -14.123-2.994 14.992 14.992 0 1 0 14.123-20z"
                    fill="#ad1e1e"
                  ></path>
                  <path
                    d="m381.444 80.834a14.921 14.921 0 0 0 -9.916 3.767c1.023 1.186 4.519 3.232 9.93 3.232a8 8 0 0 1 0 16 33.357 33.357 0 0 1 -14.14-3 14.992 14.992 0 1 0 14.126-20z"
                    fill="#ad1e1e"
                  ></path>
                </svg>
              </span>
              <span className="text-[11px] xl:text-xs  font-semibold duration-200">
                <p className="leading-3">Schedule</p>
                <p>Event</p>
              </span>
            </div>
          </div>
        </div>
        {/* Mon to Sun */}
        <div className="w-100 d-flex flex-column justify-content-around">
          <div className="flex justify-around items-center text-black  text-base 2xl:text-lg">
            <div className="w-[10%] text-center p-2 font-bold">Mon</div>
            <div className="w-[10%] text-center p-2 font-bold">Tue</div>
            <div className="w-[10%] text-center p-2 font-bold">Wed</div>
            <div className="w-[10%] text-center p-2 font-bold">Thur</div>
            <div className="w-[10%] text-center p-2 font-bold">Fri</div>
            <div className="w-[10%] text-center p-2 font-bold">Sat</div>
            <div className="w-[10%] text-center p-2 font-bold text-danger">
              Sun
            </div>
          </div>
          <hr className="mb-2" />
          <div className="flex justify-around items-center">
            {renderDays().slice(0, 7)}
          </div>
          <div className="flex justify-around items-center">
            {renderDays().slice(7, 14)}
          </div>
          <div className="flex justify-around items-center">
            {renderDays().slice(14, 21)}
          </div>
          <div className="flex justify-around items-center">
            {renderDays().slice(21, 28)}
          </div>
          <div className="flex justify-around items-center">
            {renderDays().slice(28, 35)}
          </div>
          {renderDays().length > 35 && (
            <div className="flex justify-around items-center">
              {renderDays().slice(35)}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AdminCalender;
