const AgentInfoApp = [
  {
    id: 2,
    AgentName: "Aditya Malhotra",
    AgentCode: 2,
    Address: "Sweet Kunj, Chocolate Vihar, Uttar Pradesh-201890",
    email: "aditya02@gmail.com",
    mobile: "6299716789",
  },
  {
    id: 3,
    AgentName: "Anjali Shinde",
    AgentCode: 3,
    Address: "Barfi Nagar, Uttar Pradesh-201890",
    email: "brucoffee02@gmail.com",
    mobile: "6299712451",
  },
  {
    id: 4,
    AgentName: "Richa Chadda",
    AgentCode: 4,
    Address: "Barfi Nagar, Uttar Pradesh-201890",
    email: "brucoffee02@gmail.com",
    mobile: "6299657686",
  },
  {
    id: 4,
    AgentName: "Richa",
    AgentCode: 5,
    Address: "Barfi Nagar, Uttar Pradesh-201890",
    email: "brucoffee02@gmail.com",
    mobile: "6299657686",
  },
];
export default AgentInfoApp;
