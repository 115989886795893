import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FaCoins, FaSearch } from "react-icons/fa";
import "../PopUp1/ConfirmPopUp.css";
import CheckAuth from "../../components/ServiceFunctions/CheckAuth";
import BidConfirmationBox from "./BidConfirmationBox";
import "../../pages/Extras/Page404.css";
import secureLocalStorage from "react-secure-storage";
import "./Bidbox.css";
import AlertRed from "../Alerts/AlertRed.jsx"
import AlertYellow from "../Alerts/AlertYellow.jsx"
import AlertGreen from "../Alerts/AlertGreen.jsx"

function BidBox({
  Action2,
  timestamp,
  orderId,
  product,
  cover,
  oldUnit,
  option,
  btn,
  head,
  onClose2,
  bidStatus,
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const [series, setSeries] = useState([]);
  const [cities, setCities] = useState([]);
  const [loader2, setLoader2] = useState(0);
  const [bidConfirmation, setBidConfirmation] = useState(false);
  const [status, setStatus] = useState(0);
  const [errorMessage, setErrorMessage] = useState(0);
  const [errorMessage2, setErrorMessage2] = useState(false);
  const [showBuySell, setShowBuySell] = useState(-1);
  const [showBtn, setShowBtn] = useState(-1);
  const [searchQuery, setSearchQuery] = useState(location?.state?.pro || "");
  const [searchListA, setSearchList] = useState(false);
  const [unit, setUnit] = useState(0);
  const [perUnit, setPerUnit] = useState(0);
  const [callOption, setCallOption] = useState("");
  const [callAmount, setCallAmount] = useState(0);
  const [key, setKey] = useState("");
  const { isAuthenticated, pan, cash, walr, residex } = CheckAuth();
  const [isHighlighted, setIsHighlighted] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [showModal3, setShowModal3] = useState(false);
  const [showModal4, setShowModal4] = useState(false);
  const [showModal5, setShowModal5] = useState(false);

  useEffect(() => {
    if (searchQuery) {
      setIsHighlighted(true);

      const timeout = setTimeout(() => {
        setIsHighlighted(false);
      }, 2000);

      return () => clearTimeout(timeout);
    }
  }, [searchQuery]);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/userLost");
      return; // Prevent further execution if not authenticated
    }
    if (pan && isAuthenticated) {
      fetch(`https://api-scdndestates.com/api/primary-bid-box/`, {
        method: "POST",
        body: JSON.stringify({}),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to fetch data");
          }
          return response.json();
        })
        .then((data) => {
          setSeries(data.data);
          setCities(data.data2);
          const lastSeries = data.data[data.data.length - 1];
          setKey(Object.keys(lastSeries)[0]);
        })
        .catch((error) => {
          // console.error("Error: ", error);
        });
    }
  }, [pan, isAuthenticated]);

  useEffect(() => {
    if (head === "WALR Exchange") {
      if (btn === 2 || btn === -1) {
        setCallOption();
        setPerUnit(0);
        setUnit(0);
        if (
          location?.state?.pro &&
          location?.state?.pro !== "WALR Series undefined"
        ) {
          setSearchQuery(location?.state?.pro || "");
          if (location?.state?.pro === `WALR Series ${key}`) {
            setShowBuySell(1);
          } else {
            setShowBuySell(0);
          }
        } else {
          setSearchQuery("");
        }
      } else {
        setSearchQuery(product !== "" ? "WALR Series " + product : "");
        setCallOption("");
        setCallAmount(0);
        setPerUnit(1000);
        setUnit(0);
        setShowBuySell(btn);
      }
    } else if (head === "RESIDEX Exchange") {
      if (btn !== 2 || btn === -1) {
        setPerUnit(0);
        setShowBuySell(-1);
        setUnit(0);
        if (
          location?.state?.pro &&
          location?.state?.pro !== "RESIDEX City undefined"
        ) {
          setSearchQuery(location?.state?.pro || "");
        } else {
          setSearchQuery("");
        }
      } else {
        setPerUnit(0);
        setCallOption("");
        setCallAmount(0);
        setSearchQuery(product !== "" ? "RESIDEX City " + product : "");
        setShowBuySell(btn);
        setUnit(0);
      }
    } else if (head === "Open Orders") {
      setStatus(1);
      setSearchQuery(product !== "" ? product : "");
      setCallOption(cover);
      setShowBuySell(btn);
      if (product.slice(0, 4) === "WALR") {
        setCallAmount(option);
        setPerUnit(1000);
      } else {
        if (cover === "futures") {
          setPerUnit(0);
          setCallAmount(option / 1000);
        } else {
          setPerUnit(option);
          setCallAmount(0);
        }
      }
    } else if (Action2 === "Delete Order") {
      setBidConfirmation(true);
      setUnit(unit);
    }
  }, [Action2, timestamp, orderId, product, btn, head, cover, key]);

  // Search Box Title
  let TitleArray;
  if (head === "WALR Exchange") {
    TitleArray = series
      .map((item, index) => "WALR Series " + Object.keys(item))
      .filter((q) => q.toUpperCase().includes(searchQuery.toUpperCase()));
  }
  if (head === "RESIDEX Exchange") {
    TitleArray = cities
      .map((item, index) => "RESIDEX City " + Object.keys(item))
      .filter((q) => q.toUpperCase().includes(searchQuery.toUpperCase()));
  }
  // Search Box Title

  // onChange Search input
  const handleSearch = (m) => {
    setSearchList(false);
    setCallOption("");
    setCallAmount(0);
    setPerUnit(0);
    setUnit(0);
    if (head === "WALR Exchange") {
      setSearchQuery(m);
      setPerUnit(1000);
      if (m === `WALR Series ${key}`) {
        setShowBuySell(1);
      } else {
        setShowBuySell(0);
      }
    } else {
      setSearchQuery(m);
    }
  };
  // onChange Search input

  // Onchange Call Option
  const handleCallOption = (value) => {
    if (head === "WALR Exchange" || searchQuery.slice(0, 4) === "WALR") {
      setCallOption(value);
      const p = searchQuery.split(" ").pop();
      const productData = series.find((item) => Object.keys(item)[0] === p);
      if (value === "yes") {
        if (productData) {
          setCallAmount(productData[p]["Option Price"]);
        }
      } else {
        setCallAmount(0);
      }
    } else {
      setCallOption(value);
      const p = searchQuery.slice(13);
      const productData = cities.find((item) => Object.keys(item)[0] === p);
      if (value === "futures") {
        if (productData) {
          setCallAmount(productData[p]["3 mth Premium"]);
          setPerUnit(0);
          setShowBuySell(1);
        }
      } else {
        setCallAmount(0);
        setPerUnit(productData[p]["Price"]);
        setShowBuySell(2);
      }
    }
  };
  // Onchange Call Option

  const handleBuy = (productName, units, coverAmount, price1unit) => {
    const now = new Date();
    const currentHours = now.getHours();
    const currentMinutes = now.getMinutes();
    const cutoffHours = 10;
    const cutoffMinutes = 30;
    const cutoffHours2 = 18;
    const cutoffMinutes2 = 30;
    const isBeforeFirstCutoff =
      currentHours < cutoffHours ||
      (currentHours === cutoffHours && currentMinutes < cutoffMinutes);
    const isAfterSecondCutoff =
      currentHours > cutoffHours2 ||
      (currentHours === cutoffHours2 && currentMinutes > cutoffMinutes2);

    if (isBeforeFirstCutoff || isAfterSecondCutoff) {
      setErrorMessage2(true);
    } else {
      setShowBtn(1);
      setLoader2(1);
      if (searchQuery.slice(0, 4) === "WALR") {
        const totalAmount = unit * (price1unit + coverAmount);
        if (units > 0) {
          if (showBuySell !== 0 && walr === null && totalAmount < 1000000) {
            setShowModal1(true);
            setShowModal2(false);
            setShowModal3(false);
            setShowModal4(false);
            setShowModal5(false);
            setTimeout(() => {
              setShowModal1(false);
            }, 5000);
            setLoader2(0);
          } else if (
            callOption === "index" ||
            callOption === "futures" ||
            callOption === ""
          ) {
            setShowModal1(false);
            setShowModal2(true);
            setShowModal3(false);
            setShowModal4(false);
            setShowModal5(false);
            setTimeout(() => {
              setShowModal2(false);
            }, 5000);
            setLoader2(0);
          } else {
            var total = 0;
            const totalUnits = units - oldUnit;
            const p = productName.split(" ").pop();
            const p_name = productName.slice(0, 4);
            if (p_name === "WALR") {
              total = totalUnits * (price1unit + coverAmount);
            }
            var inventory = 0;
            const productData = series.find(
              (item) => Object.keys(item)[0] === p
            );
            if (productData) {
              inventory = productData[p]["Inventory"];
            }

            // Process E
            fetch(`https://api-scdndestates.com/api/get-blocked-unit/`, {
              method: "POST",
              body: JSON.stringify({
                Security: productName,
              }),
              headers: {
                "Content-type": "application/json; charset=UTF-8",
              },
            })
              .then((response) => {
                if (!response.ok) {
                  throw new Error("Failed to fetch data");
                }
                return response.json();
              })
              .then((data) => {
                secureLocalStorage.setItem(
                  "Blocked Units",
                  JSON.stringify(data.data)
                );
                if (inventory >= totalUnits + data.data) {
                  // Process F
                  fetch(
                    `https://api-scdndestates.com/api/get-blocked-balance/`,
                    {
                      method: "POST",
                      body: JSON.stringify({
                        pan,
                      }),
                      headers: {
                        "Content-type": "application/json; charset=UTF-8",
                      },
                    }
                  )
                    .then((response) => {
                      if (!response.ok) {
                        throw new Error("Failed to fetch data");
                      }
                      return response.json();
                    })
                    .then((data) => {
                      if (total <= cash - data.data) setBidConfirmation(true);
                      else setErrorMessage(1);
                      setLoader2(0);
                    })
                    .catch((error) => {
                      // console.error("Error: ", error);
                    });
                  // Process F
                } else {
                  setErrorMessage(2);
                }
              })
              .catch((error) => {
                // console.error("Error: ", error);
              });
            // Process E
          }
        } else {
          setShowModal1(false);
            setShowModal2(false);
            setShowModal3(true);
            setShowModal4(false);
            setShowModal5(false);
            setTimeout(() => {
              setShowModal3(false);
            }, 5000);
          setLoader2(0);
        }
      } else {
        const totalAmount =
          callOption === "futures"
            ? unit * 1000 * (price1unit + coverAmount)
            : unit * (price1unit + coverAmount);

        if (units > 0) {
          if (showBuySell !== 0 && residex === null && totalAmount < 1000000) {
            setShowModal1(true);
            setShowModal2(false);
            setShowModal3(false);
            setShowModal4(false);
            setShowModal5(false);
            setTimeout(() => {
              setShowModal1(false);
            }, 5000);
            setLoader2(0);
          } else if (
            callOption === "yes" ||
            callOption === "no" ||
            callOption === ""
          ) {
            setShowModal1(false);
            setShowModal2(false);
            setShowModal3(false);
            setShowModal4(true);
            setShowModal5(false);
            setTimeout(() => {
              setShowModal4(false);
            }, 5000);
            setLoader2(0);
          } else {
            var total = 0;
            const totalUnits = units - oldUnit;
            const p = productName.slice(13);
            const p_name = productName.slice(0, 7);
            if (p_name === "RESIDEX") {
              total =
                callOption === "futures"
                  ? totalUnits * 1000 * (price1unit + coverAmount)
                  : totalUnits * (price1unit + coverAmount);
            }
            var inventory = 0;
            const productData = cities.find(
              (item) => Object.keys(item)[0] === p
            );
            if (productData) {
              inventory = productData[p]["Inventory"];
            }

            // Process E
            fetch(`https://api-scdndestates.com/api/get-blocked-unit/`, {
              method: "POST",
              body: JSON.stringify({
                Security: productName,
              }),
              headers: {
                "Content-type": "application/json; charset=UTF-8",
              },
            })
              .then((response) => {
                if (!response.ok) {
                  throw new Error("Failed to fetch data");
                }
                return response.json();
              })
              .then((data) => {
                secureLocalStorage.setItem(
                  "Blocked Units",
                  JSON.stringify(data.data)
                );
                if (inventory >= totalUnits + data.data) {
                  // Process F
                  fetch(
                    `https://api-scdndestates.com/api/get-blocked-balance/`,
                    {
                      method: "POST",
                      body: JSON.stringify({
                        pan,
                      }),
                      headers: {
                        "Content-type": "application/json; charset=UTF-8",
                      },
                    }
                  )
                    .then((response) => {
                      if (!response.ok) {
                        throw new Error("Failed to fetch data");
                      }
                      return response.json();
                    })
                    .then((data) => {
                      if (total <= cash - data.data) setBidConfirmation(true);
                      else setErrorMessage(1);
                      setLoader2(0);
                    })
                    .catch((error) => {
                      // console.error("Error: ", error);
                    });
                  // Process F
                } else {
                  setErrorMessage(2);
                }
              })
              .catch((error) => {
                // console.error("Error: ", error);
              });
            // Process E
          }
        } else {
          setShowModal1(false);
            setShowModal2(false);
            setShowModal3(true);
            setShowModal4(false);
            setShowModal5(false);
            setTimeout(() => {
              setShowModal3(false);
            }, 5000);
          setLoader2(0);
        }
      }
    }
  };

  const handleSell = (productName, units, coverAmount) => {
    const now = new Date();
    const currentHours = now.getHours();
    const currentMinutes = now.getMinutes();
    const cutoffHours = 10;
    const cutoffMinutes = 30;
    const cutoffHours2 = 18;
    const cutoffMinutes2 = 30;
    const isBeforeFirstCutoff =
      currentHours < cutoffHours ||
      (currentHours === cutoffHours && currentMinutes < cutoffMinutes);
    const isAfterSecondCutoff =
      currentHours > cutoffHours2 ||
      (currentHours === cutoffHours2 && currentMinutes > cutoffMinutes2);

    if (isBeforeFirstCutoff || isAfterSecondCutoff) {
      setErrorMessage2(true);
    } else {
      setShowBtn(0);
      setLoader2(2);
      if (
        (callOption === "no" || callOption === "yes" || callOption === "") &&
        searchQuery.slice(0, 7) === "RESIDEX"
      ) {
        setShowModal1(false);
            setShowModal2(false);
            setShowModal3(false);
            setShowModal4(true);
            setShowModal5(false);
            setTimeout(() => {
              setShowModal4(false);
            }, 5000);
        setLoader2(0);
      } else if (
        (callOption === "index" ||
          callOption === "futures" ||
          callOption === "") &&
        searchQuery.slice(0, 4) === "WALR"
      ) {
        setShowModal1(false);
            setShowModal2(true);
            setShowModal3(false);
            setShowModal4(false);
            setShowModal5(false);
            setTimeout(() => {
              setShowModal2(false);
            }, 5000);
        setLoader2(0);
      } else if (units > 0) {
        // Process H
        fetch(`https://api-scdndestates.com/api/get-lien-unit/`, {
          method: "POST",
          body: JSON.stringify({
            pan: pan,
            Cover: coverAmount,
            Security: productName,
          }),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error("Failed to fetch data");
            }
            return response.json();
          })
          .then((data) => {
            if (data.data < units - oldUnit) {
              setErrorMessage(3);
            } else {
              setBidConfirmation(true);
            }
            setLoader2(0);
          })
          .catch((error) => {
            // console.error("Error: ", error);
          });
        // Process H
      } else {
        setShowModal1(false);
            setShowModal2(false);
            setShowModal3(true);
            setShowModal4(false);
            setShowModal5(false);
            setTimeout(() => {
              setShowModal3(false);
            }, 5000);
        setLoader2(0);
      }
    }
  };

  const handleCloseBidConfirmation = () => {
    if (isAuthenticated) {
      setBidConfirmation(false);
      onClose2();
    }
  };

  return (
    <>
      {bidConfirmation ? (
        <BidConfirmationBox
          formName={Action2}
          BidUnit={unit}
          OldBidUnit={oldUnit}
          Time={timestamp}
          OrderNo={orderId}
          PerUnit={perUnit}
          Product={searchQuery}
          Cover={callOption}
          CoverPrice={callAmount}
          Btn={showBtn}
          onClose={handleCloseBidConfirmation}
          bidStatus={bidStatus}
        />
      ) : errorMessage !== 0 ? (
        <>
          <div className="demo1-container ">
            <div className="border error rounded-lg bg-light border-primary w-[350px] md:w-[550px] h-[420px] items-center overflow-hidden flex flex-col justify-center">
              <div className="object-cover h-[300px] flex items-center">
                <img className="" src="/images/404.gif" alt="" />
              </div>
              <div className="px-3 font-semibold text-base">
                {errorMessage === 1 ? (
                  <p className="text-center">
                    You do not have sufficient Free Balance to Place Order.
                  </p>
                ) : errorMessage === 2 ? (
                  <p className="text-center">
                    The remaining quantity of DeAs offered by SCDND is
                    insufficient for this Order. Please Buy through the P-2-P
                    portal
                  </p>
                ) : (
                  errorMessage === 3 && (
                    <>
                      <p className="text-center">
                        You do not have sufficient DeAs balance to Place Order.
                      </p>
                    </>
                  )
                )}
              </div>
              <div className="row m-0 p-3 ">
                <div className="flex justify-center p-2 align-items-center gap-2">
                  {errorMessage === 2 ? (
                    <>
                      <button
                        className="cursor-pointer flex w-32 md:w-44  h-8 md:h-10  items-center justify-center rounded-md bg-[#5ab2ff] px-2 py-1 text-[11px] md:text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[#3aa0ff]"
                        onClick={() => {
                          onClose2();
                          setLoader2(0);
                          navigate("/PrimaryMarket/walr", {
                            state: { selectedCard: "Open Orders" },
                          });
                        }}
                      >
                        Cancel Open Orders
                      </button>
                      <button
                        className="cursor-pointer flex w-28 md:w-36  h-8 md:h-10  items-center justify-center rounded-md bg-[#5ab2ff] px-2 py-1 text-xs md:text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[#3aa0ff]"
                        onClick={() => {
                          onClose2();
                          setLoader2(0);
                          navigate("/TradingPortal");
                        }}
                      >
                        Trading Portal
                      </button>
                    </>
                  ) : (
                    errorMessage === 1 && (
                      <button
                        className="cursor-pointer flex w-24 md:w-36  h-8 md:h-10  items-center justify-center rounded-md bg-[#5ab2ff] px-2 py-1 text-xs md:text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[#3aa0ff]"
                        onClick={() => {
                          onClose2();
                          setLoader2(0);
                          navigate("/moneyWallet");
                        }}
                      >
                        Add Money
                      </button>
                    )
                  )}
                  <button
                    type="button"
                    className="cursor-pointer flex  w-20 h-8 md:h-10  items-center  justify-center rounded-md border-2 hover:shadow-[inset_0px_0px_2px_0px_#5ab2ff] border-[#5ab2ff] px-2 py-1 text-xs md:text-sm font-semibold leading-6 duration-300 text-[#212121] shadow-sm hover:bg-[#ddedfd]"
                    data-mdb-ripple-init
                    data-mdb-ripple-color="dark"
                    onClick={() => {
                      setErrorMessage(0);
                      setLoader2(0);
                      onClose2();
                    }}
                  >
                    Go Back
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            className={`p-3 m-0 rounded-3 w-full ${
              isHighlighted
                ? "duration-500 ease-in border-2 bg-sky-50 border-sky-200 shadow-4"
                : "border-2 shadow-4 bg-white duration-500 ease-in"
            }`}
          >
            <div className="flex gap-2 items-center bid-heading">
              <FaCoins />
              <span className="text-lg 2xl:text-xl">Bid Details</span>
            </div>
            <hr className="my-2" />

            <form action="" className="m-0 text-black font-semibold">
              {/* Search Field */}
              <div className="flex flex-col items-start">
                <div className="flex-shrink-0">
                  <label className="text-sm lg:text-xs 2xl:text-sm pr-1">
                    Series:<span className="text-danger">*</span>
                  </label>
                </div>
                <div className="w-full flex mt-1 sm:mt-0">
                  <input
                    className="form-control text-dark fw-bold text-sm lg:text-xs 2xl:text-sm py-1 px-2 w-full"
                    type="text"
                    placeholder="Search"
                    disabled={status === 1}
                    onChange={(e) => {
                      setSearchQuery(e.target.value);
                      setSearchList(true);
                    }}
                    value={searchQuery}
                  />
                  <span className="input-group-text text-sm lg:text-xs 2xl:text-sm py-0 px-2 opacity-75 bid-searchIcon-style">
                    <FaSearch size={15} />
                  </span>
                </div>
                {searchQuery && searchListA && (
                  <div className="SearchList_Container2 w-full sm:w-auto">
                    {TitleArray.length > 0 ? (
                      TitleArray.map((m) => (
                        <div
                          key={m}
                          onClick={(e) => handleSearch(m)}
                          className="SearchList_Item"
                        >
                          <div className="SearchList_Item_Name">{m}</div>
                        </div>
                      ))
                    ) : (
                      <div className="SearchList_Item">
                        <div className="SearchList_Item_Name">
                          Invalid Security Name
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>

              {/* Bid Unit and Price per Unit */}
              <div className="flex max-sm:flex-col sm:flex-row justify-between mt-3 space-y-2 sm:space-y-0">
                <div className="flex flex-col w-full sm:w-5/12">
                  <label className="text-sm lg:text-xs 2xl:text-sm pr-1">
                    Bid Unit:<span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control text-sm lg:text-xs 2xl:text-sm"
                    type="number"
                    min={0}
                    value={unit}
                    onChange={(e) => {
                      setUnit(parseInt(e.target.value));
                    }}
                    placeholder="0"
                  />
                </div>
                <div className="flex flex-col w-full sm:w-6/12">
                  <div>
                    <label className="text-sm lg:text-xs 2xl:text-sm pr-1">
                      Price per Unit:
                    </label>
                    <input
                      className="form-control text-sm lg:text-xs 2xl:text-sm"
                      type="text"
                      value={perUnit.toLocaleString("en-IN", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                      disabled
                      placeholder="1000"
                    />
                  </div>
                </div>
              </div>

              {/* Conditional Fields based on Search */}
              {searchQuery.slice(0, 4) === "WALR" && (
                <div className="flex max-sm:flex-col sm:flex-row justify-between mt-3">
                  <div className="flex flex-col w-full sm:w-5/12">
                    <label className="text-sm lg:text-xs 2xl:text-sm">
                      Cover:<span className="text-danger">*</span>
                    </label>
                    <select
                      className="border-[1px] rounded border-gray-400 px-2 text-sm lg:text-xs 2xl:text-sm py-1 "
                      value={callOption}
                      disabled={status === 1}
                      onChange={(e) => handleCallOption(e.target.value)}
                    >
                      <option value="">Select</option>
                      <option value="no">No</option>
                      <option value="yes">Yes</option>
                    </select>
                  </div>
                  <div className="flex flex-col w-full sm:w-6/12 max-md:mt-3">
                    <label className="text-sm lg:text-xs 2xl:text-sm">
                      Option Price:
                    </label>
                    <input
                      className="form-control text-sm lg:text-xs 2xl:text-sm"
                      value={parseFloat(callAmount).toFixed(2)}
                      type="text"
                      disabled
                    />
                  </div>
                </div>
              )}

              {searchQuery.slice(0, 7) === "RESIDEX" && (
                <div className="flex max-sm:flex-col sm:flex-row justify-between mt-3">
                  <div className="flex flex-col w-full sm:w-5/12">
                    <label className="text-sm lg:text-xs 2xl:text-sm">
                      Class:<span className="text-danger">*</span>
                    </label>
                    <select
                      className="border-[1px] rounded border-gray-400 px-2 text-sm lg:text-xs 2xl:text-sm py-1"
                      value={callOption}
                      disabled={status === 1}
                      onChange={(e) => handleCallOption(e.target.value)}
                    >
                      <option value="">Select</option>
                      <option value="index">Index</option>
                      <option value="futures">Futures</option>
                    </select>
                  </div>
                  <div className="flex flex-col w-full sm:w-6/12 max-md:mt-3">
                    <label className="text-sm lg:text-xs 2xl:text-sm">
                      3 mth Premium:
                    </label>
                    <input
                      className="form-control text-sm lg:text-xs 2xl:text-sm"
                      value={parseFloat(callAmount).toFixed(2)}
                      type="text"
                      disabled
                    />
                  </div>
                </div>
              )}

              {/* Total Bid Amount */}
              <div className="flex flex-col items-start justify-between mt-3">
                <label className="text-sm lg:text-xs 2xl:text-sm">
                  Total Bid Amount:
                </label>
                <input
                  className="form-control text-sm lg:text-xs 2xl:text-sm"
                  type="text"
                  value={
                    callOption === "futures"
                      ? (unit * 1000 * (perUnit + callAmount)).toLocaleString(
                          "en-IN",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )
                      : (unit * (perUnit + callAmount)).toLocaleString(
                          "en-IN",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )
                  }
                  disabled
                  placeholder="1000"
                />
                {showBuySell !== 0 &&
                  (head === "WALR Exchange"
                    ? walr === null
                    : residex === null) &&
                  (callOption === "futures"
                    ? unit * 1000 * (perUnit + callAmount) < 1000000
                    : unit * (perUnit + callAmount) < 1000000) && (
                    <div className="p-0 m-0 text-danger font-size2">
                      *Minimum Holding Value is Rs. 10,00,000/-
                    </div>
                  )}
              </div>

              {/* Buttons */}
              <div className="flex justify-end gap-3 mt-3">
                <div className="col-2 m-0 p-0">
                  {loader2 === 1 ? (
                    <input
                      type="image"
                      src="/images/btn-transparent.gif"
                      alt=""
                      height="30"
                      className="btn-success mt-1 btn btn-sm"
                      disabled
                    />
                  ) : (
                    <input
                      type="button"
                      value="Buy"
                      className={`${
                        showBuySell !== 1 ? "btn-secondary" : "btn-success"
                      } mt-1 btn btn-sm bg-success text-white`}
                      disabled={showBuySell === 0 || showBuySell === -1}
                      onClick={() =>
                        handleBuy(searchQuery, unit, callAmount, perUnit)
                      }
                    />
                  )}
                </div>
                <div className="col-2 m-0 p-0 ps-2">
                  {loader2 === 2 ? (
                    <input
                      type="image"
                      src="/images/btn-transparent.gif"
                      alt=""
                      height="30"
                      className="btn-danger mt-1 btn btn-sm"
                      disabled
                    />
                  ) : (
                    <input
                      type="button"
                      value="Sell"
                      className={`${
                        showBuySell !== 0 ? "btn-secondary" : "btn-danger"
                      } mt-1 btn btn-sm bg-danger text-white`}
                      disabled={showBuySell === 1 || showBuySell === -1}
                      onClick={() =>
                        handleSell(
                          searchQuery,
                          unit,
                          callOption === "futures"
                            ? callAmount
                            : callAmount * 1000
                        )
                      }
                    />
                  )}
                </div>
              </div>
            </form>
          </div>

          {errorMessage2 && (
            <>
              <div className="demo10-container">
                <div className="border flex-col justify-center p-4 items-center rounded bg-light border-primary">
                  <img src="/images/overdue.gif" alt="" className="h-52 w-52" />
                  <div className="">
                    <h4 className="text-center">
                      Sorry you can only place or modify orders while the
                      Auction window is open. <br />
                      Kindly try again tomorrow between 10:30 am to 6:30 pm.
                    </h4>
                  </div>
                  <div className="row m-0 p-2">
                    <div className="d-flex justify-content-end p-3 align-items-center">
                      <button
                        className="btn-primary btn btn-sm"
                        onClick={() => {
                          setErrorMessage2(false);
                        }}
                      >
                        Go back
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
      {showModal1 && (
      <AlertYellow
        message="Minimum Holding Value is Rs. 10,00,000/-."
        onClose={() => setShowModal1(false)}
      />
    )}
      {showModal2 && (
      <AlertYellow
        message="Please select the Cover for the Series."
        onClose={() => setShowModal2(false)}
      />
    )}
      {showModal3 && (
      <AlertYellow
        message="Bid Unit should be a natural number."
        onClose={() => setShowModal3(false)}
      />
    )}
      {showModal4 && (
      <AlertYellow
        message="Please select the Class for the City."
        onClose={() => setShowModal4(false)}
      />
    )}
      {/* {showModal5 && (
      <AlertYellow
        message="Please verify your PAN first!"
        onClose={() => setShowModal5(false)}
      />
    )} */}
    </>
  );
}

export default BidBox;
