import React from 'react'

function AlertYellow({message, onClose}) {
  return (
  <>
  <div className="fixed w-full left-0 flex items-start justify-center z-[9999999] top-20 alert-animation">
    <div
    class="bg-yellow-100 max-w-lg mx-3 border-l-4 border-yellow-500 text-yellow-900 p-2 rounded-r-lg flex items-center transition duration-300 ease-in-out hover:bg-yellow-200 transform  scale-100 hover:scale-105 justify-between"
  >
    <div className="flex items-center justify-start">
    <svg height="24" viewBox="0 0 32 32" width="24" xmlns="http://www.w3.org/2000/svg" id="fi_3756712"><g id="_48_Warning" data-name="48 Warning"><path d="m29.764 23.453-11.188-17.933a3.027 3.027 0 0 0 -5.146-.009l-11.206 17.963a3.027 3.027 0 0 0 2.583 4.526h22.386a3.028 3.028 0 0 0 2.571-4.547z" fill="#ffd54f"></path></g><g id="Layer_52" data-name="Layer 52"><path d="m27.193 28h-22.386a3.027 3.027 0 0 1 -2.583-4.526l11.206-17.963a3.027 3.027 0 0 1 5.146.009l11.188 17.933a3.028 3.028 0 0 1 -2.571 4.547zm-12.054-21.451-11.205 17.963a1.008 1.008 0 0 0 .873 1.488h22.386a1.009 1.009 0 0 0 .861-1.509s-11.188-17.936-11.193-17.942a1.02 1.02 0 0 0 -1.722 0z"></path><path d="m15.087 18.624-.266-3.976c-.052-.8-.325-2.093.286-2.743.465-.5 1.566-.586 1.9.107a4.873 4.873 0 0 1 .182 2.536l-.356 4.093a3.221 3.221 0 0 1 -.249 1.12.708.708 0 0 1 -1.254.013 3.763 3.763 0 0 1 -.243-1.15zm.921 5.463a1.24 1.24 0 0 1 -.142-2.471 1.24 1.24 0 1 1 .142 2.471z"></path></g></svg>
    <p class="pl-2 text-xs font-semibold">
    {message}
    </p></div>
    <button onClick={onClose} className="flex items-center ml-3" ><svg className="fill-gray-900 hover:fill-red-500 scale-100 hover:scale-90 duration-200 ease-in-out" height="20" viewBox="0 0 32 32" width="20" xmlns="http://www.w3.org/2000/svg" id="fi_2734822"><g id="Layer_22" data-name="Layer 22"><path d="m21 12.46-3.59 3.54 3.59 3.54a1 1 0 0 1 0 1.46 1 1 0 0 1 -.71.29 1 1 0 0 1 -.7-.29l-3.59-3.59-3.54 3.59a1 1 0 0 1 -.7.29 1 1 0 0 1 -.71-.29 1 1 0 0 1 0-1.41l3.54-3.59-3.54-3.54a1 1 0 0 1 1.41-1.41l3.54 3.54 3.54-3.54a1 1 0 0 1 1.46 1.41zm4.9 13.44a14 14 0 1 1 0-19.8 14 14 0 0 1 0 19.8zm-1.41-18.39a12 12 0 1 0 0 17 12 12 0 0 0 0-17z"></path></g></svg></button>
  </div>
  </div>
  </>
  )
}

export default AlertYellow;