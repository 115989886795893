import React, { useRef, useState, useEffect } from "react";
import "./Welcome.css";
import CheckAuth from "../ServiceFunctions/CheckAuth";
import { FaCircle } from "react-icons/fa";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

function Welcome() {
  const { isAuthenticated } = CheckAuth();

  const stepRefs = useRef([]);

  useEffect(() => {
    stepRefs.current.forEach((step, index) => {
      gsap.fromTo(
        step,
        { opacity: 0 },
        {
          opacity: 1,
          duration: 1,
          delay: 0.4,
          ease: "none",
        }
      );
    });
  }, []);
  const [activeIndex, setActiveIndex] = useState(0);
  const lines = [
    "We create and market-make decentralized versions of Mutual Funds (or synthetic ETFs/REITs).",
    "Unit NAVs and quarterly Coupons directly linked to public financial indices or real economic survey-data.",
    "Client funds are protected by verifiable liquid collateral held in Escrow, valuation changes reported in real-time.",
    "Ownership records stored securely & immutably, Peer-2-Peer transactions processed instantly & cheaply.",
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % lines.length);
    }, 3000);

    return () => clearInterval(interval); // Clean up on component unmount
  }, [lines.length]);

  return (
    <>
      <div
        className="flex justify-center after-header
          h-max "
      >
        <div className="hero-section  2xl:mt-10 py-2 px-2 md:px-20  bg-cover w-[85%]  flex flex-col justify-start items-center  ">
          <div
            className="header-title  flex flex-col text-center  leading-tight  font-semibold tracking-wide text-gray-800 p-2 mx-0   "
            ref={(el) => (stepRefs.current[0] = el)}
          >
            <p className="font-bold leading-none lg:leading-snug  mx-0 text-sm  md:text-xl lg:text-2xl xl:text-3xl 2xl:text-text-[34px]">
              Invest with{" "}
              <span className="text-sky-400">Performance Certainty</span>
            </p>
            <p className="font-bold leading-none lg:leading-snug  xl:mt-3 text-sm  md:text-xl lg:text-2xl xl:text-3xl 2xl:text-text-[34px]">
              Trade <span className="text-sky-400">Peer-2-Peer</span> on
              Blockchain
            </p>
          </div>
          <div
            className="header-main-section max-md:mt-2 md:mt-10 xl:mt-8  flex flex-col-reverse items-center justify-center xl:flex-row leading-3"
            ref={(el) => (stepRefs.current[1] = el)}
          >
            <div className="header-left-section w-full xl:w-[55%] mt-4 md:mt-10 xl:mt-0">
              <div className="text-start lg:mx-16 xl:mx-4 xl:mr-12 ">
                <div>
                  {lines.map((text, index) => (
                    <p
                      key={index}
                      className={`mt-4 2xl:mt-6 text-xs md:text-sm 2xl:text-base font-semibold text-neutral-800 flex items-start duration-300 ${
                        activeIndex === index
                          ? ""
                          : ""
                      }`}
                      style={
                        activeIndex === index ? { fontWeight: "bold" } : {}
                      }
                    >
                      <span className="text-xs w-max h-max font-semibold inline-block mt-1 uppercase fill-sky-400 mr-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-4 h-4"
                          id="Layer_1"
                          data-name="Layer 1"
                          viewBox="0 0 24 24"
                        >
                          <path d="m23.126,9.868h0l-2.151-2.154v-1.718c0-1.651-1.342-2.995-2.991-2.995h-1.716l-2.151-2.153c-1.131-1.131-3.101-1.131-4.231,0l-2.151,2.153h-1.716c-1.65,0-2.991,1.343-2.991,2.995v1.718l-2.152,2.154c-1.165,1.168-1.165,3.067,0,4.235l2.151,2.154v1.718c0,1.651,1.342,2.995,2.991,2.995h1.716l2.151,2.153c.565.565,1.317.877,2.116.877s1.55-.312,2.115-.877l2.151-2.153h1.716c1.65,0,2.991-1.343,2.991-2.995v-1.718l2.152-2.154c1.165-1.168,1.165-3.067,0-4.235Zm-4.922.343l-5.054,4.995c-.614.61-1.423.916-2.231.916s-1.613-.305-2.229-.913l-2.599-2.499c-.392-.389-.396-1.021-.007-1.414.39-.391,1.021-.396,1.415-.007l2.598,2.498c.453.449,1.19.45,1.644,0l5.055-4.996c.394-.39,1.026-.386,1.415.007s.385,1.025-.007,1.414Z" />
                        </svg>
                      </span>
                      <span>{text}</span>
                    </p>
                  ))}
                </div>

                <div className="mt-10 2xl:mt-20  lg:float-l flex sm:flex-row items-center justify-center md:justify-start lg:float-left gap-x-4 sm:gap-x-6">
                  <a
                    href="/login"
                    className="rounded-md duration-200 bg-sky-400 max-lg:px-4 max-lg:py-1.5 lg:px-7 lg:py-2.5 text-xs lg:text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#3698ee]"
                  >
                    Get started
                  </a>
                  <a
                    class="explore-btn text-xs lg:text-sm group items-center inline-flex w-max text-center font-semibold text-sky-400 hover:text-sky-500  duration-200 bg-white/5  rounded-3xl  "
                    href="/Blogs"
                  >
                    Learn More
                    <div class="arrow">
                      <svg
                        className="arrow-i fill-sky-400 group-hover:fill-sky-500"
                        xmlns="http://www.w3.org/2000/svg"
                        id="Outline"
                        viewBox="0 0 24 24"
                        width="30"
                        height="30"
                      >
                        <path d="M18,12h0a2,2,0,0,0-.59-1.4l-4.29-4.3a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.42L15,11H5a1,1,0,0,0,0,2H15l-3.29,3.29a1,1,0,0,0,1.41,1.42l4.29-4.3A2,2,0,0,0,18,12Z" />
                      </svg>
                    </div>
                  </a>
                </div>
              </div>
            </div>

            {/* Image */}
            <div class="w-full object-cover lg:w-[60%] xl:w-[45%]  flex justify-center items-center">
              <div
                id="carouselExampleIndicators"
                className="carousel slide relative "
                data-mdb-ride="carousel"
                data-mdb-carousel-init
                data-mdb-interval="3000"
              >
                {/*  */}
                <img
                  src="/images/hero-mockup.png"
                  className="absolute top-0 left-0  w-[100%] z-[1000]"
                  alt=""
                />

                <div className="carousel-inner">
                  <div className="carousel-item relative left-4 top-4 md:left-7  md:top-5 lg:left-10 lg:top-8 xl:left-8 xl:top-6 active">
                    <img
                      src="/images/hero1.webp"
                      className="rounded-xl w-[90%] h-[90%]"
                      alt="PitchDeck- 1"
                    />
                  </div>
                  <div className="carousel-item relative left-4 top-4 md:left-7  md:top-5 lg:left-10 lg:top-8 xl:left-8 xl:top-6">
                    <img
                      src="/images/hero2.webp"
                      className="rounded-xl w-[90%] h-[90%]"
                      alt="PitchDeck- 2"
                    />
                  </div>
                  <div className="carousel-item relative left-4 top-4 md:left-7  md:top-5 lg:left-10 lg:top-8 xl:left-8 xl:top-6">
                    <img
                      src="/images/hero3.png"
                      className="rounded-xl w-[90%] h-[90%]"
                      alt="PitchDeck- 3"
                    />
                  </div>
                  <div className="carousel-item relative left-4 top-4 md:left-7  md:top-5 lg:left-10 lg:top-8 xl:left-8 xl:top-6">
                    <img
                      src="/images/hero4.webp"
                      className="rounded-xl w-[90%] h-[90%]"
                      alt="PitchDeck- 4"
                    />
                  </div>
                  <div className="carousel-item relative left-4 top-4 md:left-7  md:top-5 lg:left-10 lg:top-8 xl:left-8 xl:top-6">
                    <img
                      src="/images/hero5.webp"
                      className="rounded-xl w-[90%] h-[90%]"
                      alt="PitchDeck- 5"
                    />
                  </div>
                  <div className="carousel-item relative left-4 top-4 md:left-7  md:top-5 lg:left-10 lg:top-8 xl:left-8 xl:top-6">
                    <img
                      src="/images/hero6.webp"
                      className="rounded-xl w-[90%] h-[90%]"
                      alt="PitchDeck- 6"
                    />
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div
            class="btn-conteiner mt-10"
            ref={(el) => (stepRefs.current[2] = el)}
          >
            <div href="" class="btn-scroll">
              <span class="icon-scroll">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 66 43"
                  className="w-8 h-8 lg:w-12 lg:h-12 rotate-90"
                >
                  <g
                    fill-rule="evenodd"
                    fill="none"
                    strokeWidth="1"
                    stroke="none"
                    id="scroll"
                  >
                    <path
                      fill="#9ee5fa"
                      d="M40.1543933,3.89485454 L43.9763149,0.139296592 C44.1708311,-0.0518420739 44.4826329,-0.0518571125 44.6771675,0.139262789 L65.6916134,20.7848311 C66.0855801,21.1718824 66.0911863,21.8050225 65.704135,22.1989893 C65.7000188,22.2031791 65.6958657,22.2073326 65.6916762,22.2114492 L44.677098,42.8607841 C44.4825957,43.0519059 44.1708242,43.0519358 43.9762853,42.8608513 L40.1545186,39.1069479 C39.9575152,38.9134427 39.9546793,38.5968729 40.1481845,38.3998695 C40.1502893,38.3977268 40.1524132,38.395603 40.1545562,38.3934985 L56.9937789,21.8567812 C57.1908028,21.6632968 57.193672,21.3467273 57.0001876,21.1497035 C56.9980647,21.1475418 56.9959223,21.1453995 56.9937605,21.1432767 L40.1545208,4.60825197 C39.9574869,4.41477773 39.9546013,4.09820839 40.1480756,3.90117456 C40.1501626,3.89904911 40.1522686,3.89694235 40.1543933,3.89485454 Z"
                      id="scroll-icon-one"
                    ></path>
                    <path
                      fill="#9ee5fa"
                      d="M20.1543933,3.89485454 L23.9763149,0.139296592 C24.1708311,-0.0518420739 24.4826329,-0.0518571125 24.6771675,0.139262789 L45.6916134,20.7848311 C46.0855801,21.1718824 46.0911863,21.8050225 45.704135,22.1989893 C45.7000188,22.2031791 45.6958657,22.2073326 45.6916762,22.2114492 L24.677098,42.8607841 C24.4825957,43.0519059 24.1708242,43.0519358 23.9762853,42.8608513 L20.1545186,39.1069479 C19.9575152,38.9134427 19.9546793,38.5968729 20.1481845,38.3998695 C20.1502893,38.3977268 20.1524132,38.395603 20.1545562,38.3934985 L36.9937789,21.8567812 C37.1908028,21.6632968 37.193672,21.3467273 37.0001876,21.1497035 C36.9980647,21.1475418 36.9959223,21.1453995 36.9937605,21.1432767 L20.1545208,4.60825197 C19.9574869,4.41477773 19.9546013,4.09820839 20.1480756,3.90117456 C20.1501626,3.89904911 20.1522686,3.89694235 20.1543933,3.89485454 Z"
                      id="scroll-icon-two"
                    ></path>
                    <path
                      fill="#9ee5fa"
                      d="M0.154393339,3.89485454 L3.97631488,0.139296592 C4.17083111,-0.0518420739 4.48263286,-0.0518571125 4.67716753,0.139262789 L25.6916134,20.7848311 C26.0855801,21.1718824 26.0911863,21.8050225 25.704135,22.1989893 C25.7000188,22.2031791 25.6958657,22.2073326 25.6916762,22.2114492 L4.67709797,42.8607841 C4.48259567,43.0519059 4.17082418,43.0519358 3.97628526,42.8608513 L0.154518591,39.1069479 C-0.0424848215,38.9134427 -0.0453206733,38.5968729 0.148184538,38.3998695 C0.150289256,38.3977268 0.152413239,38.395603 0.154556228,38.3934985 L16.9937789,21.8567812 C17.1908028,21.6632968 17.193672,21.3467273 17.0001876,21.1497035 C16.9980647,21.1475418 16.9959223,21.1453995 16.9937605,21.1432767 L0.15452076,4.60825197 C-0.0425130651,4.41477773 -0.0453986756,4.09820839 0.148075568,3.90117456 C0.150162624,3.89904911 0.152268631,3.89694235 0.154393339,3.89485454 Z"
                      id="scroll-icon-three"
                    ></path>
                  </g>
                </svg>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Welcome;
