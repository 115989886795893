import React, { useState, useEffect } from "react";
import AgentInfoApp from "../API/AgentInfoApi";
import { IoMdArrowDropdownCircle } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import { IoMdAddCircle } from "react-icons/io";
import AlertGreen from "../../src/components/Alerts/AlertGreen.jsx"



function AgentsCommissions() {
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [combinedData, setCombinedData] = useState([]);
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  const [data_193, setData_193] = useState([]);
  const [data_195, setData_195] = useState([]);
  const [dataFetched, setDataFetched] = useState(false);
  const [show, setShow] = useState(-1);
  const [show2, setShow2] = useState("");
  const [show3, setShow3] = useState(false);
  const [payDate, setPayDate] = useState("");
  const [invoice, setInvoice] = useState("");
  const [utrNo, setUtrNo] = useState("");
  const [payAmount, setPayAmount] = useState(0);
  const [expandedIndex0, setExpandedIndex0] = useState(null);
  const [selectedTab, setSelectedTab] = useState("section_193");
  const [showModal1, setShowModal1] = useState(false);

  const handleChange = (event) => {
    setSelectedTab(event.target.id);
  };

  function date_to_quarter(date) {
    const year = new Date(date).getFullYear();
    const month = new Date(date).getMonth() + 1; // Get month (1-12)

    // Adjust month for fiscal quarters starting in April
    let fiscalYear = year;
    let quarter;

    if (month >= 4 && month <= 6) {
      quarter = 1; // April to June
    } else if (month >= 7 && month <= 9) {
      quarter = 2; // July to September
    } else if (month >= 10 && month <= 12) {
      quarter = 3; // October to December
    } else {
      quarter = 4; // January to March
      fiscalYear -= 1; // Since it's Q4, we subtract 1 from the fiscal year
    }

    return `Q${quarter}FY${fiscalYear}-${(fiscalYear + 1) % 100}`;
  }

  function date_to_quarter2(date) {
    const year = new Date(date).getFullYear();
    const month = new Date(date).getMonth() + 1; // Get month (1-12)

    // Adjust month for fiscal quarters starting in April
    let fiscalYear = year;
    let quarter;

    if (month >= 4 && month <= 6) {
      quarter = 1; // April to June
    } else if (month >= 7 && month <= 9) {
      quarter = 2; // July to September
    } else if (month >= 10 && month <= 12) {
      quarter = 3; // October to December
    } else {
      quarter = 4; // January to March
      fiscalYear -= 1; // Since it's Q4, we subtract 1 from the fiscal year
    }

    return `Quarter ${quarter}`;
  }

  useEffect(() => {
    fetch(`https://api-scdndestates.com/api/get-admin-management-fee/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to save management fee");
        }
        return response.json();
      })
      .then((response) => {
        setData3(response.data3);
        setData(response.data);
        setData2(response.data2);

        const sorted_data_193 = Object.entries(response.data_193)
          .sort(([dateA], [dateB]) => new Date(dateB) - new Date(dateA))
          .reduce((acc, [date, entries]) => {
            acc[date] = entries;
            return acc;
          }, {});

        const sorted_data_195 = Object.entries(response.data_195)
          .sort(([dateA], [dateB]) => new Date(dateB) - new Date(dateA))
          .reduce((acc, [date, entries]) => {
            acc[date] = entries;
            return acc;
          }, {});
        setData_193(sorted_data_193);
        setData_195(sorted_data_195);
        setDataFetched(true);
      })
      .catch((error) => {
        console.error("Error during fetch:", error);
      });

    const combinedData = AgentInfoApp.map((agent) => {
      const agentCode = agent.AgentCode;

      // Find corresponding entry in onboardingFee
      const feeData = data2?.find((fee) => {
        const feeKey = Object.keys(fee)[0];
        return fee[feeKey].Code === agentCode.toString();
      });

      // total paid amount to a agent

      let totalAmount = 0;

      const feeData2 = data3
        .filter((item) => item.AgentCode === agentCode)
        .flatMap((item) =>
          Object.values(item)
            .filter((ledgerItem) => typeof ledgerItem === "object") // Ensure to filter out non-object values like AgentCode and StarRating
            .map((ledgerItem) => parseInt(ledgerItem.Pay_amount))
        );

      totalAmount = feeData2.reduce((acc, amount) => acc + amount, 0);

      let amt = 0;
      // Find all corresponding fee in AgentPaymentApp
      data2?.filter((fee) => {
        const feeKey = Object.keys(fee)[0];
        if (parseInt(fee[feeKey].Code) === agentCode) {
          amt = amt + fee[feeKey].Amount;
        }
      });

      // Construct combined data object
      return {
        AgentCode: agentCode,
        AgentName: agent.AgentName,
        Amount: amt,
        TotalPayAmount: totalAmount,
        ClientName: feeData ? Object.keys(feeData)[0] : "Direct",
      };
    });

    setCombinedData(combinedData);
  }, [dataFetched]);

  const transformData = (data) => {
    const transformed = {};

    for (const [date, entries] of Object.entries(data)) {
      const result = date_to_quarter(date);

      if (!transformed[result]) {
        transformed[result] = {
          total: 0,
          tableData: [],
        };
      }

      const totalForDate = entries.reduce(
        (sum, entry) => sum + entry.amount,
        0
      );
      transformed[result].total += totalForDate;

      for (const entry of entries) {
        transformed[result].tableData.push({
          name: entry.name || "N/A",
          pan: entry.pan,
          rate: "10%", // Add rate if available
          dateOfDeduction: date,
          tdsAmount: entry.amount,
        });
      }
    }

    return Object.entries(transformed).map(([fiscalYear, data]) => ({
      fiscalYear,
      total: data.total,
      tableData: data.tableData,
    }));
  };

  const transformedData = transformData(data_193);

  const transformData2 = (data) => {
    const transformed = {};

    for (const [date, entries] of Object.entries(data)) {
      const result = date_to_quarter(date);
      if (!transformed[result]) {
        transformed[result] = {
          total: 0,
          tableData: [],
        };
      }

      const totalForDate = entries.reduce(
        (sum, entry) => sum + entry.amount,
        0
      );
      transformed[result].total += totalForDate;

      for (const entry of entries) {
        transformed[result].tableData.push({
          name: entry.name || "N/A",
          pan: entry.pan,
          rate: "", // Add rate if available
          dateOfDeduction: date,
          tdsAmount: entry.amount,
        });
      }
    }

    return Object.entries(transformed).map(([fiscalYear, data]) => ({
      fiscalYear,
      total: data.total,
      tableData: data.tableData,
    }));
  };

  const getAgentname = (code) => {
    const agent = AgentInfoApp.find(
      (data) => data.AgentCode === parseInt(code)
    );
    return agent ? agent.AgentName : "No Manager";
  };

  const transformedData2 = transformData2(data_195);

  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1; // January is 0
  const initialYear = currentMonth >= 4 ? currentYear : currentYear - 1; // If after April, start financial year in current year
  const lastYear = new Date().getFullYear() - 1;
  const lastMonth = new Date().getMonth() + 1; // January is 0
  const FinalYear = lastMonth >= 4 ? lastYear : lastYear - 1; // If after April, start financial year in current year

  const [financialYear, setFinancialYear] = useState(initialYear);

  // Calculate the financial year string for display, e.g. "2023-24"
  const getFinancialYearString = (year) =>
    `${year}-${(year + 1).toString().slice(2)}`;

  // Function to filter dates for the current financial year
  const filterDatesByFinancialYear = (dates, year) => {
    const startDate = new Date(`${year}-04-01`);
    const endDate = new Date(`${year + 1}-03-31`);
    return dates.filter((date) => {
      const currentDate = new Date(date);
      return currentDate >= startDate && currentDate <= endDate;
    });
  };

  // Paginate between financial years
  const prevPage = () => {
    setFinancialYear(financialYear - 1);
  };

  const nextPage = () => {
    setFinancialYear(financialYear + 1);
  };

  const dates = Object.keys(data);
  const filteredDates = filterDatesByFinancialYear(dates, financialYear).sort(
    (a, b) => new Date(b) - new Date(a)
  );

  const handleClientTable = (val) => {
    setShow(val);
  };

  const handleInvoiceTable = (val) => {
    setShow2(val);
  };

  const UploadNewInvoice = () => {
    fetch(`https://api-scdndestates.com/api/upload-new-invoice/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        payDate: payDate,
        invoice: invoice,
        utrNo: utrNo,
        payAmount: payAmount,
        agentCode: show2,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to upload invoice");
        }
        return response.json();
      })
      .then((response) => {
        if (response.message === "Success") {
          setShowModal1(true);
      setTimeout(() => {
        setShowModal1(false);
      }, 5000);
          setPayDate("");
          setInvoice("");
          setUtrNo("");
          setPayAmount("");
          setShow3(false);
          setShow2("");
          setData2(response.data);
        }
      })
      .catch((error) => {
        console.error("Error during fetch:", error);
      });
  };

  const [activeCard, setActiveCard] = useState(null);

  const handleCardClick = (date) => {
    setActiveCard(date);
  };

  return (
    <>
      <div className="flex gap-2.5">
        {/* Left section */}

        <div className="w-[50%] 2xl:w-[55%] border shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] rounded-lg  bg-white">
          <div className="bg-sky-500 w-full flex justify-between items-center p-2 text-white rounded-t-md">
            <p className="text-base font-bold">TDS Deduction</p>
            <div className="flex items-center gap-4 font-bold">
              {/* Buttons */}
              <div className="flex justify-center">
                <div className="w-full md:w-fit flex justify-center">
                  <div className="segmented-control relative flex items-center rounded-lg w-52 h-7 px-1">
                    <input
                      type="radio"
                      name="radio2"
                      value="3"
                      id="section_193"
                      className="hidden"
                      checked={selectedTab === "section_193"}
                      onChange={handleChange}
                    />
                    <label
                      htmlFor="section_193"
                      className="segmented-control__1  py-2 flex-1 flex justify-center items-center transition-all duration-500 cursor-pointer"
                      style={{
                        width: "6rem",
                      }}
                    >
                      <p className="m-0 font-bold text-sm text-white py-2">
                        Section 193
                      </p>
                    </label>

                    <input
                      type="radio"
                      name="radio2"
                      value="4"
                      id="section_195"
                      className="hidden"
                      checked={selectedTab === "section_195"}
                      onChange={handleChange}
                    />
                    <label
                      htmlFor="section_195"
                      className="segmented-control__2 py-2 flex-1 flex justify-center items-center transition-all duration-500 cursor-pointer z-1"
                      style={{
                        width: "7.6rem",
                      }}
                    >
                      <p className="m-0 font-bold text-sm text-white py-2">
                        Section 195
                      </p>
                    </label>

                    <div
                      className="absolute h-8 z-0 rounded-md bg-[#ffffff80]  border-[1px] border-sky-600 shadow-inner pointer-events-none transition-transform duration-300 ease-in-out py-2 "
                      style={{
                        transform:
                          selectedTab === "section_193"
                            ? "translateX(0)"
                            : selectedTab === "section_195"
                            ? "translateX(6.2rem)"
                            : "translateX(13.8rem)",
                        width:
                          selectedTab === "section_193"
                            ? "6.2rem"
                            : selectedTab === "section_195"
                            ? "6.2rem"
                            : "0rem",
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {selectedTab === "section_193" ? (
            <div className="m-2 space-y-2 overflow-y-scroll h-[710px]">
              {transformedData.length > 0 ? (
                transformedData.map((item, index) => (
                  <div
                    key={index}
                    className="group flex flex-col gap-2 rounded-lg border-2 text-black cursor-pointer transition ease-in-out"
                  >
                    <div className="flex flex-col text-sm">
                      <div
                        className="flex items-center justify-between bg-[#E0F2FE] p-1.5"
                        onClick={() =>
                          setExpandedIndex(
                            expandedIndex === index ? null : index
                          )
                        }
                      >
                        <span className="font-bold flex items-center gap-2">
                          <span
                            className={`transition-all duration-500 ${
                              expandedIndex === index ? "-rotate-180" : ""
                            }`}
                          >
                            <IoMdArrowDropdownCircle size={18} />
                          </span>
                          <span>{item.fiscalYear}</span>
                        </span>
                        <div className="bg-white text-black border text-sm font-semibold rounded-md min-w-[140px] py-1 px-2 flex items-center justify-center gap-2">
                          <p>Total:</p>
                          <p>
                            ₹{" "}
                            {item.total.toLocaleString("en-IN", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </p>
                        </div>
                      </div>

                      <div
                        className={`transition-all duration-700 ease-in-out overflow-hidden ${
                          expandedIndex === index
                            ? "max-h-screen opacity-100 "
                            : "max-h-0 opacity-0"
                        }`}
                      >
                        <div className="min-w-full overflow-x-auto shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)]">
                          {/* Header Row */}
                          <div className="bg-[#0284C7] text-white min-w-[700px] ">
                            <div className="grid grid-cols-5 gap-4 py-2 px-2 text-center text-sm font-semibold">
                              <p>Name</p>
                              <p>PAN</p>
                              <p>Rate</p>
                              <p>Date</p>
                              <p>TDS Amount</p>
                            </div>
                          </div>

                          {/* Table Data with Scroll */}
                          <div className="bg-white max-h-[185px] min-w-[700px] overflow-y-auto overflow-x-auto rounded-b-lg ">
                            <div className="flex flex-col gap-1 px-2 py-2">
                              {item.tableData.map((row, rowIndex) => (
                                <div
                                  key={rowIndex}
                                  className="grid grid-cols-5 items-center bg-white hover:bg-gray-100  rounded-lg  shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] transition-all duration-300 mt-1"
                                >
                                  <p className="px-4 py-1 text-center font-semibold text-[#313131] text-sm">
                                    {row.name}
                                  </p>
                                  <p className="px-4 py-1 text-center font-semibold text-[#313131] text-sm">
                                    {row.pan}
                                  </p>
                                  <p className="px-4 py-1 text-center font-semibold text-[#313131] text-sm">
                                    {row.rate}
                                  </p>
                                  <p className="px-4 py-1 text-center font-semibold text-[#313131] text-sm">
                                    {row.dateOfDeduction}
                                  </p>
                                  <p className="px-4 py-1 text-center font-semibold text-[#313131] text-sm">
                                    {row.tdsAmount.toLocaleString("en-IN", {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })}
                                  </p>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <>No Transactions Yet</>
              )}
            </div>
          ) : (
            <div className="m-2 space-y-2 overflow-y-scroll h-[710px]">
              {transformedData2.length > 0 ? (
                transformedData2.map((item, index) => (
                  <div
                    key={index}
                    className="group flex flex-col gap-2 rounded-lg border-2 text-black cursor-pointer transition ease-in-out"
                  >
                    <div className="flex flex-col text-sm">
                      <div
                        className="flex items-center justify-between bg-[#E0F2FE] p-1.5"
                        onClick={() =>
                          setExpandedIndex(
                            expandedIndex === index ? null : index
                          )
                        }
                      >
                        <span className="font-bold flex items-center gap-2">
                          <span
                            className={`transition-all duration-500 ${
                              expandedIndex === index ? "-rotate-180" : ""
                            }`}
                          >
                            <IoMdArrowDropdownCircle size={18} />
                          </span>
                          <span>{item.fiscalYear}</span>
                        </span>
                        <div className="bg-white text-black border text-sm font-semibold rounded-md py-1 px-2 flex items-center gap-2">
                          <p>Total</p>
                          <p>{item.total}</p>
                        </div>
                      </div>

                      <div
                        className={`transition-all duration-1000 ease-in-out overflow-hidden ${
                          expandedIndex === index
                            ? "max-h-screen opacity-100 m-3"
                            : "max-h-0 opacity-0"
                        }`}
                      >
                        <div className="min-w-full border-collapse overflow-x-scroll">
                          <div className="bg-[#0284C7] text-white rounded-t-lg min-w-[700px]">
                            <div className="grid grid-cols-5 gap-2 p-1 text-center text-sm md:text-sm font-bold">
                              <p className="px-2 py-1 md:px-4 md:py-2">Name</p>
                              <p className="px-2 py-1 md:px-4 md:py-2">PAN</p>
                              <p className="px-2 py-1 md:px-4 md:py-2">Rate</p>
                              <p className="px-2 py-1 md:px-4 md:py-2">Date</p>
                              <p className="px-2 py-1 md:px-4 md:py-2">
                                TDS Amt
                              </p>
                            </div>
                          </div>
                          <div className="bg-white rounded-b-lg overflow-y-scroll max-h-[185px] min-w-[700px]">
                            <div className="min-w-full border-x border-b rounded-b-lg border-gray-200 flex flex-col gap-2 p-2">
                              {item.tableData.map((row, rowIndex) => (
                                <div
                                  key={rowIndex}
                                  className="flex items-center bg-white border hover:bg-[#1b4b6c] rounded-md"
                                >
                                  <p className="flex-1 px-2 py-1 md:px-4 md:py-2 text-center">
                                    {row.name}
                                  </p>
                                  <p className="flex-1 px-2 py-1 md:px-4 md:py-2 text-center">
                                    {row.pan}
                                  </p>
                                  <p className="flex-1 px-2 py-1 md:px-4 md:py-2 text-center">
                                    {row.rate}
                                  </p>
                                  <p className="flex-1 px-2 py-1 md:px-4 md:py-2 text-center">
                                    {row.dateOfDeduction}
                                  </p>
                                  <p className="flex-1 px-2 py-1 md:px-4 md:py-2 text-center">
                                    {row.tdsAmount.toLocaleString("en-IN", {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })}
                                  </p>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <>No Transactions Yet</>
              )}
            </div>
          )}
        </div>

        {/* Right Section */}
        <div className="w-[50%] 2xl:w-[45%] p-2 relative bg-white h-[710px]">
          <div className="w-full p-2  border rounded-lg relative bg-white shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)]">
            <h3 className="text-base font-bold text-black absolute -top-3 bg-white px-2 py-1 rounded-lg border">
              Onboarding Fees
            </h3>
            <div className="table-responsive border-dark mt-4 w-full">
              <div className="table table-borderless table-sm w-full">
                <div className="w-full rounded-full">
                  <div className="w-full bg-[#4E83D9] text-white rounded-t-lg flex justify-around">
                    <p
                      scope="col"
                      className="text-sm font-bold text-center py-2 w-[40%]"
                    >
                      Name
                    </p>
                    <p
                      scope="col"
                      className="text-sm font-bold text-center py-2 w-[30%]"
                    >
                      Amount Payable
                    </p>
                    <p
                      scope="col"
                      className="text-sm font-bold text-center py-2 w-[30%]"
                    >
                      Amount Paid
                    </p>
                  </div>
                </div>
                <div className="border rounded-b-lg shadow-sm">
                  {combinedData.length > 0 ? (
                    combinedData.map((item, index) => (
                      <p
                        key={index}
                        className="text-black my-2 border font-semibold bg-white hover:bg-zinc-200 flex items-center justify-around bg-light mb-1 "
                      >
                        <p className="text-center text-sm px-2 py-2 w-[40%]">
                          {item.AgentName}
                        </p>
                        <div className="text-center cursor-default text-sm px-2 py-2 min-w-[30%]">
                          <div class="relative z-10 inline-flex items-center justify-center w-[100px] py-1 overflow-hidden font-bold transition-all duration-75 ease-in ">
                            <span className="z-10 text-black">
                              ₹{" "}
                              {item.Amount.toLocaleString("en-IN", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </span>
                            <img
                              src="/images/red-details.png"
                              className="w-5 h-5 ml-2 cursor-pointer animate-pulse"
                              alt=""
                              onClick={() => handleClientTable(item.AgentCode)}
                            />
                          </div>
                        </div>
                        <p className="text-center text-sm px-2 py-2 min-w-[30%] ">
                          <div class="relative z-10 cursor-default inline-flex items-center justify-center w-[100px] py-1 overflow-hidden font-bold transition-all duration-75 ease-in focus:outline-none">
                            <span className="z-10 text-black">
                              ₹{" "}
                              {item.TotalPayAmount.toLocaleString("en-IN", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </span>
                            <img
                              src="/images/green-details.png"
                              className="w-5 h-5 ml-2 cursor-pointer animate-pulse"
                              alt=""
                              onClick={() => handleInvoiceTable(item.AgentCode)}
                            />
                          </div>
                        </p>
                      </p>
                    ))
                  ) : (
                    <div className="border-top">
                      <p
                        colSpan={3}
                        className="text-center bg-light text-sm py-4"
                      >
                        <div className="flex justify-center items-center">
                          <img
                            src="/images/ghost2.gif"
                            alt="No Data"
                            className="h-6 w-6"
                          />
                          <span className="text-center text-primary mx-2">
                            No Onboarding Fee Yet.
                          </span>
                          <img
                            src="/images/ghost2.gif"
                            alt="No Data"
                            className="h-6 w-6"
                          />
                        </div>
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {show !== -1 && (
              <>
                <div className="demo2-container">
                  <div className="bg-light rounded border-0 m-4 px-3 py-2.5 overflow-hidden pop-up-animation w-[650px]">
                    <div className="flex justify-end mb-2 ">
                      <IoClose
                        className="text-white bg-red-700 hover:bg-red-800 duration-300 font-bold p-0.5 rounded-full text-xl cursor-pointer"
                        onClick={() => setShow(-1)}
                      />
                    </div>
                    <table className="table-responsive table-borderless table-sm border-dark w-full min-h-[200px]">
                      <thead className="bg-[#386BC0] text-white font-semibold rounded-t-lg">
                        <tr className="flex justify-around">
                          <th
                            scope="col"
                            className="text-sm font-semibold w-1/3 text-center"
                          >
                            Clients
                          </th>
                          <th
                            scope="col"
                            className="text-sm font-semibold w-1/3 text-center"
                          >
                            Relationship Date
                          </th>
                          <th
                            scope="col"
                            className="text-sm font-semibold w-1/3 text-center"
                          >
                            Amount
                          </th>
                        </tr>
                      </thead>
                      <tbody className="max-h-[160px] overflow-y-scroll border rounded-b-lg p-1 flex flex-col gap-1.5">
                        {data2.length > 0 ? (
                          data2
                            .filter(
                              (item) =>
                                parseInt(item[Object.keys(item)[0]].Code) ===
                                show
                            )
                            .map((entry, index) => {
                              const key = Object.keys(entry)[0];
                              const item = entry[key];
                              return (
                                <tr
                                  key={index}
                                  className="hover:bg-[#DBEAFE] text-black font-semibold border shadow-sm rounded-lg  cursor-pointer flex justify-around"
                                >
                                  <td className="text-sm text-center break-words w-1/3">
                                    {item.Name}
                                  </td>
                                  <td className="text-sm text-center w-1/3">
                                    {item.Date}
                                  </td>
                                  <td className="text-sm text-center w-1/3">
                                    ₹ {item.Amount.toLocaleString("en-IN")}
                                  </td>
                                </tr>
                              );
                            })
                        ) : (
                          <tr className="flex justify-center items-center">
                            <td
                              colSpan={3}
                              className="text-center bg-light text-sm flex items-center gap-2"
                            >
                              <img src="/images/ghost2.gif" alt="" />
                              <span className="text-primary">
                                No Onboarding Fee Yet.
                              </span>
                              <img src="/images/ghost2.gif" alt="" />
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
            )}
            {show2 !== "" && (
              <>
                <div className="demo2-container ">
                  <div className="bg-light rounded border-0 m-4 p-4 overflow-hidden pop-up-animation w-[600px]">
                    <div className="flex justify-between mb-2">
                      <button
                        className="bg-green-600 text-white font-bold text-[12px] px-2 py-1 rounded-md flex items-center gap-1"
                        onClick={() => setShow3(!show3)}
                      >
                        Add <IoMdAddCircle />
                      </button>

                      <IoClose
                        className="text-white bg-red-700 hover:bg-red-800 duration-300 font-bold p-0.5 rounded-full text-xl cursor-pointer"
                        onClick={() => setShow2("")}
                      />
                    </div>
                    <div className="table-responsive table table-hover table-borderless table-sm border-dark">
                      <div className="rounded">
                        <div className="font-semibold bg-[#386BC0] text-white flex justify-around rounded-t-lg">
                          <p
                            scope="col"
                            className="text-sm font-semibold text-center flex-1"
                          >
                            Invoice
                          </p>
                          <p
                            scope="col"
                            className="text-sm font-semibold text-center flex-1"
                          >
                            Date
                          </p>
                          <p
                            scope="col"
                            className="text-sm font-semibold text-center flex-[2] min-w-[120px]" // Adjusted width for UTR No
                          >
                            UTR No
                          </p>
                          <p
                            scope="col"
                            className="text-sm font-semibold text-center flex-1"
                          >
                            Amount
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-col gap-1.5 border rounded-b-lg p-1.5 max-h-[100px] overflow-y-scroll">
                        {data3.length > 0 ? (
                          data3
                            .filter((item) => item.AgentCode === show2)
                            .flatMap((item) =>
                              Object.keys(item)
                                .filter(
                                  (key) =>
                                    key !== "AgentCode" && key !== "StarRating"
                                )
                                .map((key, index) => (
                                  <div
                                    key={index}
                                    className="shadow-sm flex items-center justify-around bg-[#ffffff] hover:bg-[#DBEAFE]  text-black font-semibold rounded-lg border hover:scale-100 duration-100 cursor-pointer"
                                  >
                                    <p className="text-sm text-center flex-1">
                                      {key}
                                    </p>
                                    <p className="text-sm text-center flex-1">
                                      {new Date(
                                        item[key].Pay_date
                                      ).toLocaleDateString()}
                                    </p>
                                    <p className="text-sm text-center flex-[2] min-w-[120px]">
                                      {" "}
                                      {/* Adjusted width for UTR No */}
                                      {item[key].Utr_no}
                                    </p>
                                    <p className="text-sm text-center flex-1">
                                      ₹
                                      {parseInt(
                                        item[key].Pay_amount
                                      ).toLocaleString("en-IN")}
                                    </p>
                                  </div>
                                ))
                            )
                        ) : (
                          <div className="border-top">
                            <div
                              colSpan={4}
                              className="text-center bg-light text-sm"
                            >
                              <div className="flex justify-center items-center">
                                <img src="/images/ghost2.gif" alt="" />
                                <span className="text-center text-primary">
                                  No Onboarding Fee Yet.
                                </span>
                                <img src="/images/ghost2.gif" alt="" />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    {show3 && (
                      <>
                        <div className="border shadow-sm rounded-lg p-3 bg-white text-black ">
                          <h3 className="font-bold flex items-center gap-1">
                            <img
                              src="/images/bill.png"
                              className="w-7 h-7"
                              alt=""
                            />
                            Add New Invoice
                          </h3>
                          <hr className="my-1 py-0" />
                          <div className="row m-0 p-0">
                            <div className="col-6 m-0 p-0 row">
                              <div className="col-6 p-0 m-0 my-1">
                                <label className="text-sm">
                                  Payment Date:
                                  <span className="text-danger">*</span>
                                </label>
                              </div>
                              <div className="col-6 p-0 m-0 my-1">
                                <input
                                  className="form-control col-10 text-sm"
                                  type="date"
                                  value={payDate}
                                  onChange={(e) => setPayDate(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="col-6 m-0 p-0 ps-3 row">
                              <div className="col-6 p-0 m-0 my-1">
                                <label className="text-sm">
                                  Invoice No:
                                  <span className="text-danger">*</span>
                                </label>
                              </div>
                              <div className="col-6 p-0 m-0 my-1">
                                <input
                                  className="form-control col-10 text-sm"
                                  type="text"
                                  value={invoice}
                                  onChange={(e) => setInvoice(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="col-6 m-0 p-0 row">
                              <div className="col-6 p-0 m-0 my-1">
                                <label className="text-sm">
                                  UTR No:
                                  <span className="text-danger">*</span>
                                </label>
                              </div>
                              <div className="col-6 p-0 m-0 my-1">
                                <input
                                  className="form-control col-10 text-sm"
                                  type="text"
                                  value={utrNo}
                                  onChange={(e) => setUtrNo(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="col-6 m-0 p-0 ps-3 row">
                              <div className="col-6 p-0 m-0 my-1">
                                <label className="font-size1">
                                  Paid Amount:
                                  <span className="text-danger">*</span>
                                </label>
                              </div>
                              <div className="col-6 p-0 m-0 my-1">
                                <input
                                  className="form-control col-10 font-size1"
                                  type="number"
                                  value={payAmount}
                                  onChange={(e) => setPayAmount(e.target.value)}
                                />
                              </div>
                            </div>
                            <hr className="my-2" />
                            <div className="flex justify-end p-0">
                              <button
                                className="bg-green-600 px-2 py-1 rounded-md text-sm text-white font-semibold hover:scale-95 duration-200"
                                onClick={() => {
                                  UploadNewInvoice();
                                }}
                              >
                                Upload Invoice
                              </button>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
          {/* Bottom */}
          <div className="w-full p-2 pt-2 mt-4 border rounded-lg bg-white shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)]">
            <div className="text-base font-bold text-black relative -top-5 flex justify-between">
              <p className="rounded-lg border bg-white px-2 py-1 w-max">
                Management Fees
              </p>
              <div className="flex gap-2 rounded-lg  bg-white px-2 w-max items-center">
                <button
                  className="px-2  disabled:opacity-50 disabled:cursor-not-allowed"
                  onClick={prevPage}
                  disabled={financialYear === FinalYear} // Disable if too far back
                >
                  <img
                    className="w-6 h-6 rotate-180"
                    src="/images/nexticon.png"
                    alt=""
                  />
                </button>
                <div>{getFinancialYearString(financialYear)}</div>
                <button
                  className="px-2  disabled:opacity-50 disabled:cursor-not-allowed"
                  onClick={nextPage}
                  disabled={financialYear === initialYear} // Disable if at current financial year
                >
                  <img className="w-6 h-6 " src="/images/nexticon.png" alt="" />
                </button>
              </div>
            </div>

            {/* Card Content */}
            <div className="w-full flex flex-wrap justify-between gap-y-5">
              {filteredDates.length > 0 ? (
                filteredDates.map((date, index) => (
                  <div
                    key={index}
                    onClick={() => handleCardClick(date)}
                    className={`cookieCard w-[49%] h-24 p-2 rounded-lg cursor-pointer text-[#313131] border-2 ${
                      activeCard === date
                        ? "border-sky-300 bg-gradient-to-b from-sky-200 to-sky-100"
                        : "border-gray-300 bg-gradient-to-b from-slate-200 to-slate-100"
                    }`}
                  >
                    <div className="cookieHeading w-full text-lg font-semibold text-center flex items-center justify-center">
                      <div
                        className={`rounded-md p-1 px-2 border-2 text-sm absolute -top-3  ${
                          activeCard === date
                            ? "border-sky-400 bg-gradient-to-tr from-sky-200 to-sky-100"
                            : "border-slate-400 bg-gradient-to-tr from-slate-200 to-slate-100"
                        }`}
                      >
                        {date_to_quarter2(date)}
                      </div>
                    </div>
                    <div className="flex items-center justify-center w-full font-bold gap-2 mt-3">
                      <div className="text-sm flex flex-col items-center justify-between w-full bg-white rounded-md py-2">
                        <div className="font-bold text-xs text-sky-800">
                          WALR
                        </div>
                        ₹
                        {data[date].walr.toLocaleString("en-IN", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </div>
                      <div className="text-sm flex flex-col items-center justify-between w-full bg-white rounded-md py-2">
                        <div className="font-bold text-xs text-sky-800">
                          RESIDEX
                        </div>
                        ₹
                        {data[date].residex.toLocaleString("en-IN", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </div>
                      <div className="text-sm flex flex-col items-center justify-between w-full bg-white rounded-md py-2">
                        <div className="font-bold text-xs text-sky-800">
                          GSTR-1
                        </div>
                        ₹
                        {(
                          (data[date].walr + data[date].residex) *
                          0.18
                        ).toLocaleString("en-IN", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <></>
              )}
            </div>

            {/* Example agent data below */}
            <div className="transition-all duration-1000 ease-in-out overflow-hidden  opacity-100 my-2.5 ">
              <div className="min-w-full border-collapse">
                <div className="bg-sky-300 text-[#212121] rounded-t-lg">
                  <div className="flex justify-around p-1 text-center text-sm md:text-sm font-bold">
                    <div className="py-1 md:py-2 w-1/3 text-center">
                      Agent Name
                    </div>
                    <div className="py-1 md:py-2 w-1/3 text-center">
                      Agent Code
                    </div>
                    <div className="py-1 md:py-2 w-1/3 text-center pr-4">
                      Amount
                    </div>
                  </div>
                </div>
                <div className="bg-white rounded-b-lg  overflow-y-auto">
                  {data[activeCard]?.agents &&
                    Object.entries(data[activeCard].agents).map(
                      ([key, val], index) => (
                        <div
                          key={index}
                          className="min-w-full border-x border-b rounded-b-lg border-gray-200 font-semibold text-[#212121] flex flex-col gap-y-2 p-2"
                        >
                          <div className="flex items-center justify-center text-sm w-full bg-white border hover:bg-[#1b4b6c] rounded-md">
                            <p className="py-1 md:py-2 text-center w-1/3">
                              {getAgentname(key)}
                            </p>
                            <p className="py-1 md:py-2 text-center w-1/3">
                              {key}
                            </p>
                            <p className="py-1 md:py-2 text-center w-1/3">
                              ₹{" "}
                              {val.toLocaleString("en-IN", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </p>
                          </div>
                          {/* Additional agent data goes here */}
                        </div>
                      )
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showModal1 && (
        <AlertGreen
          message="Uploaded Successfully"
          onClose={() => setShowModal1(false)}
        />
      )}
    </>
  );
}

export default AgentsCommissions;
