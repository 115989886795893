import React from "react";
import Header from "../../components/Header/Header";
import { TbPointFilled } from "react-icons/tb";

function TermsConditions() {
  return (
    <>
      <Header />
      <div className="after-header pb-5 max-w-[1800px] mx-auto">
        <div className="w-[90%] mx-auto">
          <div className="w-full flex flex-wrap gap-4 justify-start mt-4 ">
            <div className="justify-center border rounded-lg shadow-md w-full p-[1%]">
              <div className="col-12">
                <div className="row justify-content-center p-3 text-black">
                  <h4 className="text-center fw-bold mb-2 text-xl md:text-2xl flex items-center justify-center gap-2">
                    <span>
                      <svg
                        id="fi_14682660"
                        enableBackground="new 0 0 68 68"
                        viewBox="0 0 68 68"
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-9 h-fit"
                      >
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <path
                                        d="m56.1049805 19.9151611v42.2099609c0 2.0898438-1.6901855 3.7797852-3.7800293 3.7797852h-40.9899903c-2.0900879 0-3.7800293-1.6899414-3.7800293-3.7797852v-52.8601074c0-2.0900879 1.6899414-3.7900391 3.7800293-3.7900391h30.0200195z"
                                        fill="#b9b9b9"
                                      ></path>
                                    </g>
                                  </g>
                                </g>
                                <g>
                                  <g>
                                    <g>
                                      <path
                                        d="m60.4450684 16.5350342v42.2099609c0 2.0900879-1.7001953 3.7800293-3.7900391 3.7800293h-40.9902344c-2.0898438 0-3.7797852-1.6899414-3.7797852-3.7800293v-52.8601074c0-2.0898438 1.6899414-3.7897949 3.7797852-3.7897949h30.0200195z"
                                        fill="#dbdbdb"
                                      ></path>
                                    </g>
                                  </g>
                                </g>
                                <g>
                                  <g>
                                    <g>
                                      <path
                                        d="m60.4450684 16.5350342h-10.9799805c-2.0900879 0-3.7802734-1.6999512-3.7802734-3.7900391v-10.6499023z"
                                        fill="#304056"
                                      ></path>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <path d="m22.6320934 8.2247419h-5.9760742c-.2109375 0-.3979492.0786133-.5410156.2270508-.1396475.1459961-.213378.3325195-.213378.5390625 0 .2080078.0751953.3935547.2172842.5366211.1420898.1420898.3276367.2172852.5371094.2172852h2.1977539v6.6259775c0 .2172852.0791016.4111328.2294922.5605469.1494141.1499023.3432617.2294922.5605469.2294922.215332 0 .4101563-.078125.5629883-.2260742.1567383-.1499023.2392578-.3447266.2392578-.5639648v-6.6259776h2.1860352c.2070313 0 .3935547-.0737305.5390625-.2138672.1479492-.1425781.2265625-.3291016.2265625-.5400391 0-.2094727-.0771484-.3969727-.2231445-.5429688s-.3334962-.2231445-.5424806-.2231445z"></path>
                                  </g>
                                </g>
                              </g>
                              <g>
                                <g>
                                  <g>
                                    <path d="m31.9777393 15.888279-.6499023-.6206055c.1801758-.2700195.3393555-.5522461.472168-.8388672.0576172-.1142578.0869141-.2329102.0869141-.3520508 0-.2114258-.0766602-.3964844-.2216797-.534668-.25-.2387695-.6533203-.2788086-.9536133-.081543-.1225586.0820313-.2148438.1899414-.2739258.3193359-.0795898.171875-.1533203.3203125-.2216797.4438477l-2.2851563-2.1542969c-.3364258-.3125-.581543-.5537109-.7285156-.7163086-.1333008-.1479492-.2333984-.2983398-.2978516-.4482422-.0620117-.1430664-.09375-.3154297-.09375-.512207 0-.2270508.0869141-.4213867.265625-.59375.3569336-.3447266 1.0673828-.362793 1.784668.0253906.3261719.1630859.6118164.0898438.8095703-.1176758.1230469-.1303711.1884766-.2910156.1884766-.4658203 0-.1201172-.0356445-.2368164-.1064453-.3457031-.0664063-.1025391-.1459961-.1806641-.2211914-.222168-1.050293-.7001953-2.2319336-.7089844-3.0498047-.2568359-.3798828.2099609-.6748047.4990234-.8764648.8579102-.2006836.3564453-.3022461.7529297-.3022461 1.1787109 0 .3793945.0708008.722168.2099609 1.0185547.1020508.2167969.2402344.4414063.4121094.6704102-.2753906.2133789-.5341797.4848633-.7719727.8095703-.3144531.4301758-.4741211.9501953-.4741211 1.5454102 0 .4643555.1132813.9082031.3364258 1.3193359.2260742.4179688.5854492.7607422 1.0673828 1.019043.4750977.2548828 1.0756836.3837891 1.7861328.3837891.4941406 0 .9692383-.0908203 1.4135742-.2700195.3686523-.1489258.7148438-.3452148 1.03125-.5844727l.6494141.6201172c.3085938.2851563.8222656.2709961 1.0864277-.0151367.1308594-.1430664.1972656-.3168945.1972656-.5166016 0-.1313477-.0273438-.2456055-.0810547-.340332-.0463867-.0805665-.1123047-.1586915-.1879902-.2241211zm-2.746582-.538086c-.4453125.2988281-.9248047.4443359-1.4584961.4443359-.4570313 0-.8271484-.1206055-1.1318359-.3696289-.2895508-.2358398-.4301758-.527832-.4301758-.8920898 0-.3271484.0800781-.6030273.2441406-.8427734.1342773-.1953125.2910156-.3642578.4682617-.5029297z"></path>
                                  </g>
                                </g>
                              </g>
                              <g>
                                <g>
                                  <g>
                                    <path d="m40.1021805 15.1412458c-.3339844.1748047-.6655273.3095703-.9848633.4013672-.3134766.0888672-.6821289.1342773-1.0957031.1342773-.6396484 0-1.1884766-.1318359-1.6313477-.3920898-.4394531-.2573242-.7739258-.6137695-.9941406-1.0595703-.2260742-.4545898-.340332-.9799805-.340332-1.5625s.1142578-1.1079102.340332-1.5629883c.2202148-.4448242.5546875-.8012695.9941406-1.0595703.4428711-.2597656.9916992-.3916016 1.6313477-.3916016.4135742 0 .7822266.0454102 1.0952148.1342773.3178711.0913086.6494141.2260742.9887695.402832.2739258.1386719.6464844.0810547.8569336-.1401367.1313477-.1376953.2011719-.3071289.2011719-.4907227 0-.3007813-.1362305-.534668-.390625-.6738281-.4370117-.2470703-.8842773-.4404297-1.3300781-.5742188-.4506836-.1342773-.9648438-.2021484-1.5292969-.2021484-.8383789 0-1.6030273.1860352-2.2739258.5527344-.675293.3696289-1.2128906.9067383-1.5981445 1.597168-.3833008.6865234-.5776367 1.496582-.5776367 2.4082031 0 .9121094.1943359 1.722168.5776367 2.4082031.3852539.6904297.9228516 1.22754 1.5981445 1.5971689.6708984.3666992 1.4355469.5527344 2.2739258.5527344.5644531 0 1.0786133-.0678711 1.5297852-.2021484.4433594-.1328125.890625-.3256836 1.3266602-.5727539.2573242-.140625.3935547-.3745117.3935547-.6752939 0-.1933594-.0708008-.3662109-.2055664-.5009766-.2119142-.2114257-.576172-.2695311-.8559571-.1284179z"></path>
                                  </g>
                                </g>
                              </g>
                            </g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <path
                                        d="m26.1049805 23.7249756v5.920166c0 .9299316-.7399902 1.6799316-1.670166 1.6799316h-5.9199219c-.9299316 0-1.6799316-.75-1.6799316-1.6799316v-5.920166c0-.9299316.75-1.6799316 1.6799316-1.6799316h5.9199219c.9301757-.0000001 1.670166.7499999 1.670166 1.6799316z"
                                        fill="#488ff7"
                                      ></path>
                                    </g>
                                  </g>
                                </g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <path d="m55.4870605 23.9317627h-18.2617187c-.2763672 0-.5-.2236328-.5-.5s.2236328-.5.5-.5h18.2617188c.2763672 0 .5.2236328.5.5s-.2236329.5-.5000001.5z"></path>
                                      </g>
                                    </g>
                                  </g>
                                  <g>
                                    <g>
                                      <g>
                                        <path d="m34.1994629 23.9317627h-3.8515625c-.2763672 0-.5-.2236328-.5-.5s.2236328-.5.5-.5h3.8515625c.2763672 0 .5.2236328.5.5s-.2236328.5-.5.5z"></path>
                                      </g>
                                    </g>
                                  </g>
                                  <g>
                                    <g>
                                      <g>
                                        <path d="m55.4870605 27.1832275h-7.8764648c-.2763672 0-.5-.2236328-.5-.5s.2236328-.5.5-.5h7.8764648c.2763672 0 .5.2236328.5.5s-.2236328.5-.5.5z"></path>
                                      </g>
                                    </g>
                                  </g>
                                  <g>
                                    <g>
                                      <g>
                                        <path d="m43.8967285 27.1832275h-13.5488281c-.2763672 0-.5-.2236328-.5-.5s.2236328-.5.5-.5h13.5488281c.2763672 0 .5.2236328.5.5s-.2236328.5-.5.5z"></path>
                                      </g>
                                    </g>
                                  </g>
                                  <g>
                                    <g>
                                      <g>
                                        <path d="m47.197998 30.4346924h-7.633789c-.2763672 0-.5-.2236328-.5-.5s.2236328-.5.5-.5h7.6337891c.2763672 0 .5.2236328.5.5s-.2236329.5-.5000001.5z"></path>
                                      </g>
                                    </g>
                                  </g>
                                  <g>
                                    <g>
                                      <g>
                                        <path d="m36.5378418 30.4346924h-6.1899414c-.2763672 0-.5-.2236328-.5-.5s.2236328-.5.5-.5h6.1899414c.2763672 0 .5.2236328.5.5s-.2236328.5-.5.5z"></path>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                              <g>
                                <g>
                                  <g>
                                    <path d="m20.6804199 28.3907471c-.1328125 0-.2597656-.0527344-.3535156-.1464844l-.8305664-.8310547c-.1953125-.1953125-.1953125-.512207 0-.7070313.1953125-.1953125.5117188-.1953125.7070313 0l.4770508.4775391 2.0615234-2.0615234c.1953125-.1953125.5117188-.1953125.7070313 0s.1953125.5117188 0 .7070313l-2.4150391 2.4150391c-.0937501.0937499-.2207032.1464843-.3535157.1464843z"></path>
                                  </g>
                                </g>
                              </g>
                            </g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <path
                                        d="m26.1049805 36.3250732v5.9199219c0 .9299316-.7399902 1.6799316-1.670166 1.6799316h-5.9199219c-.9299316 0-1.6799316-.75-1.6799316-1.6799316v-5.9199219c0-.9299316.75-1.6799316 1.6799316-1.6799316h5.9199219c.9301757 0 1.670166.75 1.670166 1.6799316z"
                                        fill="#488ff7"
                                      ></path>
                                    </g>
                                  </g>
                                </g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <path d="m55.4870605 36.5313721h-18.2617187c-.2763672 0-.5-.2236328-.5-.5s.2236328-.5.5-.5h18.2617188c.2763672 0 .5.2236328.5.5s-.2236329.5-.5000001.5z"></path>
                                      </g>
                                    </g>
                                  </g>
                                  <g>
                                    <g>
                                      <g>
                                        <path d="m34.1994629 36.5313721h-3.8515625c-.2763672 0-.5-.2236328-.5-.5s.2236328-.5.5-.5h3.8515625c.2763672 0 .5.2236328.5.5s-.2236328.5-.5.5z"></path>
                                      </g>
                                    </g>
                                  </g>
                                  <g>
                                    <g>
                                      <g>
                                        <path d="m55.4870605 39.7833252h-7.8764648c-.2763672 0-.5-.2236328-.5-.5s.2236328-.5.5-.5h7.8764648c.2763672 0 .5.2236328.5.5s-.2236328.5-.5.5z"></path>
                                      </g>
                                    </g>
                                  </g>
                                  <g>
                                    <g>
                                      <g>
                                        <path d="m43.8967285 39.7833252h-13.5488281c-.2763672 0-.5-.2236328-.5-.5s.2236328-.5.5-.5h13.5488281c.2763672 0 .5.2236328.5.5s-.2236328.5-.5.5z"></path>
                                      </g>
                                    </g>
                                  </g>
                                  <g>
                                    <g>
                                      <g>
                                        <path d="m47.197998 43.03479h-7.633789c-.2763672 0-.5-.2236328-.5-.5s.2236328-.5.5-.5h7.6337891c.2763672 0 .5.2236328.5.5s-.2236329.5-.5000001.5z"></path>
                                      </g>
                                    </g>
                                  </g>
                                  <g>
                                    <g>
                                      <g>
                                        <path d="m36.5378418 43.03479h-6.1899414c-.2763672 0-.5-.2236328-.5-.5s.2236328-.5.5-.5h6.1899414c.2763672 0 .5.2236328.5.5s-.2236328.5-.5.5z"></path>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                              <g>
                                <g>
                                  <g>
                                    <path d="m20.6804199 40.9908447c-.1328125 0-.2597656-.0527344-.3535156-.1464844l-.8305664-.8310547c-.1953125-.1953125-.1953125-.512207 0-.7070313.1953125-.1953125.5117188-.1953125.7070313 0l.4770508.4775391 2.0615234-2.0615234c.1953125-.1953125.5117188-.1953125.7070313 0s.1953125.5117188 0 .7070313l-2.4150391 2.4150391c-.0937501.09375-.2207032.1464843-.3535157.1464843z"></path>
                                  </g>
                                </g>
                              </g>
                            </g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <path
                                        d="m26.1049805 48.9249268v5.920166c0 .9299316-.7399902 1.6799316-1.670166 1.6799316h-5.9199219c-.9299316 0-1.6799316-.75-1.6799316-1.6799316v-5.920166c0-.9299316.75-1.6799316 1.6799316-1.6799316h5.9199219c.9301757-.0000001 1.670166.7499999 1.670166 1.6799316z"
                                        fill="#488ff7"
                                      ></path>
                                    </g>
                                  </g>
                                </g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <path d="m55.4870605 49.1314697h-18.2617187c-.2763672 0-.5-.2236328-.5-.5s.2236328-.5.5-.5h18.2617188c.2763672 0 .5.2236328.5.5s-.2236329.5-.5000001.5z"></path>
                                      </g>
                                    </g>
                                  </g>
                                  <g>
                                    <g>
                                      <g>
                                        <path d="m34.1994629 49.1314697h-3.8515625c-.2763672 0-.5-.2236328-.5-.5s.2236328-.5.5-.5h3.8515625c.2763672 0 .5.2236328.5.5s-.2236328.5-.5.5z"></path>
                                      </g>
                                    </g>
                                  </g>
                                  <g>
                                    <g>
                                      <g>
                                        <path d="m55.4870605 52.3834229h-7.8764648c-.2763672 0-.5-.2236328-.5-.5s.2236328-.5.5-.5h7.8764648c.2763672 0 .5.2236328.5.5s-.2236328.5-.5.5z"></path>
                                      </g>
                                    </g>
                                  </g>
                                  <g>
                                    <g>
                                      <g>
                                        <path d="m43.8967285 52.3834229h-13.5488281c-.2763672 0-.5-.2236328-.5-.5s.2236328-.5.5-.5h13.5488281c.2763672 0 .5.2236328.5.5s-.2236328.5-.5.5z"></path>
                                      </g>
                                    </g>
                                  </g>
                                  <g>
                                    <g>
                                      <g>
                                        <path d="m47.197998 55.6348877h-7.633789c-.2763672 0-.5-.2236328-.5-.5s.2236328-.5.5-.5h7.6337891c.2763672 0 .5.2236328.5.5s-.2236329.5-.5000001.5z"></path>
                                      </g>
                                    </g>
                                  </g>
                                  <g>
                                    <g>
                                      <g>
                                        <path d="m36.5378418 55.6348877h-6.1899414c-.2763672 0-.5-.2236328-.5-.5s.2236328-.5.5-.5h6.1899414c.2763672 0 .5.2236328.5.5s-.2236328.5-.5.5z"></path>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                              <g>
                                <g>
                                  <g>
                                    <path d="m20.6804199 53.5904541c-.1279297 0-.2558594-.0488281-.3535156-.1464844l-.8305664-.8305664c-.1953125-.1953125-.1953125-.5117188 0-.7070313s.5117188-.1953125.7070313 0l.4770508.4770508 2.0615234-2.0610352c.1953125-.1953125.5117188-.1953125.7070313 0s.1953125.5117188 0 .7070313l-2.4150391 2.4145508c-.0976563.0976563-.225586.1464844-.3535157.1464844z"></path>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </svg>
                    </span>
                    <span>Terms & Conditions</span>
                  </h4>
                  <p className=" text-sm mt-2 text-justify m-0">
                    The users of this website understand that any use of the
                    information / contents and any investments and investment
                    related decisions are at the sole discretion &amp; risk of
                    such persons. The information presented under newsletter and
                    blogs is solely for informational purposes. 
                  </p>
                  <h6 className="m-0 text-base mt-4 mb-2 fw-bold">
                    Terms and Conditions for Client:
                  </h6>
                  <hr className="px-4" />
                  <p className="mt-3 text-sm  ">
                    <ol type="1" className="leading-relaxed">
                      <li>
                        <h6 className="m-0 text-base fw-bold">Accounts</h6>
                        To use our Services, you must provide accurate and
                        up-to-date information to open an Account. You may need
                        to provide details and documents such as PAN card
                        details, address proof, bank account details, and KYC
                        proof. It is your responsibility to keep your Account
                        and password secure. Unauthorized use of your Account
                        due to your inability to secure your account and
                        password will be your responsibility and may result in
                        losses incurred by SCDND Estates or third-party users.
                      </li>
                      <br />
                      <li>
                        <h6 className="m-0 text-base fw-bold">Subscription</h6>
                        Any User subscribing to, using, or accessing the
                        services available through this site, is registered as a
                        single user of the services. The applicable rate of the
                        Subscription Fees for some of the services provided
                        herein shall be such as mentioned on the site and/or as
                        discussed with the concerned users from time to time.
                        User’s Liability for the Subscription Fees, if any,
                        shall accrue from the Date of Commencement.
                      </li>
                      <br />
                      <li>
                        <h6 className="m-0 text-base fw-bold">
                          Processing of Transactions
                        </h6>
                        All transactions/changes, as applied to/requested, by
                        the user shall be processed as per the applicable rules,
                        laws and regulations, business practices and as agreed
                        with the users/clients. <br /> Revision of Tax Details,
                        Agency Code, and Nominee shall be authorized exclusively
                        through OTP verification utilizing the registered mobile
                        number. SCDND Estates shall deem the User to have
                        comprehended and consented to said modifications upon
                        completion of OTP-based authentication.
                      </li>{" "}
                      <br />
                      <li>
                        <h6 className="m-0 text-base fw-bold">
                          Use of Password
                        </h6>
                        <ol type="i">
                          <li>
                            In the event a User is given a password, the User
                            shall ensure that such password is kept in a secure
                            manner. To protect the secrecy of his user
                            identification and/or password the user shall take
                            all such measures as may be necessary and shall not
                            reveal the same to any other person(s).
                          </li>
                          <li>
                            Since a user identification is necessary to access
                            some of the services available through the site; the
                            user shall use only his own user identification
                            while availing such services. In the event of theft
                            and/or loss of user identification and/or password
                            and/or security word, the user shall notify the
                            Company or its management/representatives
                            immediately by telephone and/or concurrently provide
                            SCDND Estates with a written notice to that effect.
                            The user shall remain solely liable for use/misuse
                            of the services by any third party until such theft
                            or loss is duly notified.
                          </li>
                          <li>
                            The password and username being made available to
                            the user shall be used only by the user
                            /organization named as the user and the authorised
                            employees of said organization. The password and
                            username being made available to the user shall be
                            used only by the user /organization named as the
                            user and the authorised employees of said
                            organization.
                          </li>
                          <li>
                            Password reset shall be authorized solely through
                            OTP verification via the user&#39;s registered email
                            and mobile number. The Company disclaims any
                            liability in the event of compromise of said OTPs by
                            the User or if the User loses access to the
                            registered email or mobile number through any means.
                          </li>
                        </ol>
                      </li>
                      <br />
                      <li>
                        <h6 className="m-0 text-base fw-bold">
                          Unauthorised Access
                        </h6>
                        As a condition for your use of this site, you will not
                        use the site for any purpose that is unlawful or
                        prohibited by these terms, conditions, and notices. You
                        may not use the site in any manner that could damage,
                        disable, overburden, or impair the network(s) connected
                        to this site or interfere with any other party’s use and
                        enjoyment of this site. You may not attempt to gain
                        unauthorised access to this site, other accounts,
                        computer systems or networks connected to the site,
                        through hacking, password mining or any other means. You
                        may not obtain or attempt to obtain any materials or
                        information through any means not intentionally made
                        available through the site.
                      </li>
                      <br />
                      <li>
                        <h6 className="m-0 text-base fw-bold">
                          Discontinuation or Modification to Services
                        </h6>
                        SCDND Estates reserves the unilateral right to add to
                        /and/or change and/or delete and/or end the service (s)
                        available through the site at any time with or without
                        notice to the User. Except for paid services, a
                        pro-rated refund shall be affected for the remaining
                        unused period.
                      </li>
                      <br />
                      <li>
                        <h6 className="m-0 text-base fw-bold">
                          Suspension of Service
                        </h6>
                        If any monies payable by the user to SCDND Estates are
                        not paid on the due date or if at its sole discretion
                        any of the users of this site/portal is found to be
                        using this site for illegal purposes or is observed
                        having violated any of the terms and conditions as are
                        applicable for usage of this site, SCDND Estates may
                        without prejudice to any other rights or remedies that
                        may be available to it suspend the service (s) provided
                        to such user. Upon subsequent payment by the user of
                        such monies as demanded by SCDND Estates, SCDND Estates
                        may at its discretion and subject to such terms as it
                        deems proper, reconnect the service(s).
                      </li>
                      <br />
                      <li>
                        <h6 className="m-0 text-base fw-bold">
                          User Obligations
                        </h6>
                        The user undertakes to:
                        <ol type="i">
                          <li>
                            be responsible for maintaining the confidentiality
                            of your account and password and for restricting
                            access to your computer to prevent unauthorised
                            access to your account and confidential information.
                          </li>
                          <li>
                            be responsible for all activities that occur under
                            your account or password.
                          </li>
                          <li>
                            take all necessary steps to ensure that the password
                            is kept confidential and secure and should inform us
                            immediately if you have any reason to believe that
                            your password has become known to anyone else, or if
                            the password is being, or is likely to be, used in
                            an unauthorised manner.
                          </li>
                          <li>
                            ensure that the details you provide us with are
                            true, correct and complete and inform the Company
                            immediately of any changes to the information that
                            you provided when registering.
                          </li>
                          <li>
                            not use the service for any illegal purposes or for
                            the violation of any law or statutory regulation,
                            including, but not restricted to indulging in; (i)
                            any unfair, manipulative or fraudulent practices or
                            (ii) any insider trading and/or communication of
                            unpublished price sensitive information.
                          </li>
                          <li>
                            not to copy, reproduce, recompile, decompile,
                            assemble, disassemble, distribute, publish, display,
                            modify or upload to, create derivative works from,
                            transmit, or in any way exploit any part of the
                            Service, except for downloading material from the
                            Service for his /her own personal, non-commercial
                            use.
                          </li>
                          <li>
                            agree to receive periodic alerts and newsletters.
                          </li>
                          <li>
                            ensure that you obtain all necessary rights to the
                            content you post or share on the Website.
                          </li>
                          <li>
                            Ensure upon entering the PAN/Aadhaar Number, Date of
                            Birth, and Full Name, users acknowledge that these
                            details cannot be modified thereafter. It is the
                            user&#39;s sole responsibility to ensure the
                            accuracy of information provided in their
                            government- issued Photo IDs. Account activation on
                            this portal is contingent upon successful KYC
                            verification.
                          </li>
                        </ol>
                      </li>
                      <br />
                      <li>
                        <h6 className="m-0 text-base fw-bold">
                          Confidentiality
                        </h6>
                        <ol type="i">
                          <li>
                            The Receiving Party/User shall keep confidential and
                            not disclose to any third party the Confidential
                            Information nor any part of it, unless upon prior
                            permission in writing from SCDND Estates. The
                            Receiving Party/User agrees to take all possible
                            precautions with regard to protecting the
                            confidential information from any third party.
                          </li>
                          <li>
                            Further no use, reproduction, transformation or
                            storage of the Proprietary Information shall be made
                            by the user without the prior written permission of
                            SCDND Estates, except where required to be disclosed
                            pursuant to any applicable laws or legal process
                            issued by any court or the rules of any competent
                            regulatory body.
                          </li>
                        </ol>
                      </li>
                      <br />
                      <li>
                        <h6 className="m-0 text-base fw-bold">
                          Non-exclusive Remedy
                        </h6>
                        Termination or expiration of this arrangement, in part
                        or in whole, shall not limit either party from pursuing
                        other remedies available to it, nor shall either party
                        be relieved of its obligation to pay all fees that are
                        due and owing under this arrangement through the
                        effective date of termination. Neither party shall be
                        liable to the other for any damages resulting solely
                        from termination as permitted herein.
                      </li>
                      <br />
                      <li>
                        <h6 className="m-0 text-base fw-bold">Jurisdiction</h6>
                        Any dispute, whatsoever, arising out of this arrangement
                        shall be governed by the Laws of India. The Courts of
                        law at Kolkata shall have exclusive jurisdiction over
                        any disputes arising under this arrangement.
                      </li>
                      <br />
                      <li>
                        <h6 className="m-0 text-base fw-bold">
                          Exclusion of Warranties
                        </h6>
                        <ol type="i">
                          <li>
                            The Company does not provide any warranty, express
                            or implied, for the accuracy or completeness of the
                            Platform or the Services, and expressly disclaims
                            any liability for any errors or omissions;
                          </li>
                          <li>
                            The use of the Platform and the Services is at your
                            own risk and consequence. In no event shall the
                            Company be held liable for any damages or claims
                            whatsoever in connection with any inaccuracy, error,
                            failure, omission, delay or otherwise;
                          </li>
                          <li>
                            Nothing in these Terms shall exclude or limit your
                            warranty or liability for losses unless they are
                            excluded or limited by applicable law. Some
                            jurisdictions do not allow the exclusion of certain
                            warranties or conditions or the limitation or
                            exclusion of liability for loss or damage caused by
                            negligence, breach of contract or breach of implied
                            terms, or incidental or consequential damages.
                            Accordingly, only the limitations which are lawful
                            in your jurisdiction will apply to you and our
                            liability will be limited to the maximum extent
                            permitted by law.
                          </li>
                          <br />
                          <h6 className="fw-bold m-0 text-base">
                            We further DO NOT represent or warrant to you that:
                          </h6>
                          <li>
                            Your use of our Platform and Services will meet your
                            requirements;
                          </li>
                          <li>
                            Your use of the Platform and Services will be
                            uninterrupted, timely, secure or free from error,
                            including specifically from server downtime;
                          </li>
                          <li>
                            Any information obtained by you as a result of your
                            use of the Platform and Services will be accurate or
                            reliable.
                          </li>
                          <li>
                            You agree that any material downloaded or otherwise
                            obtained through the use of the Services and
                            Platform is done at your own discretion and risk and
                            we shall not be liable for any damage to your
                            computer system or other device or loss of data that
                            results from the download of any such material.
                          </li>
                          <li>
                            No advice or information, whether oral or written,
                            obtained by you from us or from the use of Services
                            shall create any warranty not expressly stated in
                            these Terms.
                          </li>
                          <li>
                            We further expressly disclaim all warranties and
                            conditions of any kind, whether express or implied,
                            including, but not limited to the implied warranties
                            and conditions of merchantability, fitness for a
                            particular purpose and non-infringement.
                          </li>
                        </ol>
                      </li>
                      <br />
                      <li>
                        <h6 className="m-0 text-base fw-bold">Restrictions</h6>
                        <ol type="i">
                          <li>
                            Access to the Platform and use of the Services shall
                            always be subject to the following restrictions,
                            failing which the Company may take any action it
                            deems necessary at its sole discretion:
                          </li>
                          <li>
                            You agree not to attempt to reverse-engineer,
                            disseminate, copy, exploit, or represent yourself as
                            an agent or reseller of the Platform and the
                            Services, or any part thereof;
                          </li>
                          <li>
                            You agree not to misrepresent yourself as or
                            impersonate any other person, or otherwise cause
                            harm to any person;
                          </li>
                          <li>
                            You agree not to upload or transmit any vulgar,
                            obscene, abusive, defamatory, libellous, invasive,
                            or unlawful content, including content which is
                            objectionable or results in a breach of privacy of
                            any other person;
                          </li>
                          <li>
                            You agree not to use or abuse the Platform for or in
                            relation to any illegal or unlawful purposes
                            including fraud, embezzlement, money laundering,
                            etc.;
                          </li>
                          <li>
                            You agree not to carry out or facilitate any actions
                            which result in a detrimental effect on the Platform
                            or the Services, including DDoS attacks, data
                            security breaches, or otherwise any disruption to
                            the servers, hardware, or network of any other
                            person or the Platform or the Services;
                          </li>
                          <li>
                            You agree that you are granted a non-exclusive
                            license for the purpose of enabling you to use and
                            access our Platform and Services. Except with our
                            prior written consent, you may not assign (or grant
                            a sub-license of) your rights to use the Service or
                            otherwise transfer any part of your rights to use
                            the Service.
                          </li>
                          <li>
                            You agree not to violate any applicable laws,
                            whether or not intended by you;
                          </li>
                          <li>
                            You represent and warrant that you possess the legal
                            right and ability to enter into these Terms and to
                            use the Platform and the Services in accordance with
                            these Terms.
                          </li>
                          <li>
                            You agree not to upload or transmit any vulgar,
                            obscene, abusive, defamatory, libellous,
                            pornographic, paedophilic, invasive of another’s
                            privacy including bodily privacy, or unlawful
                            content, including content which is harmful to
                            children, objectionable or results in a breach of
                            privacy of any other person, content which is
                            insulting or harassing on the basis of gender,
                            libelous, racially or ethnically objectionable,
                            relating or encouraging money laundering or
                            gambling, or otherwise inconsistent with or contrary
                            to the laws in force in India;
                          </li>
                          <li>
                            You agree not to carry out or facilitate any actions
                            which result in a detrimental effect on the Website
                            or the Services or any related computer resource,
                            including DDoS attacks, data security breaches, or
                            otherwise any disruption to the servers, hardware,
                            or network of any other person or the Website or the
                            Services;
                          </li>
                          <li>
                            You agree that you shall have no right to the
                            intellectual property and any proprietary
                            information made available or accessible through the
                            Website or the Services;
                          </li>
                          <li>
                            You agree not to violate any applicable laws,
                            whether or not intended by you;
                          </li>
                          <li>
                            You represent and warrant that you possess the legal
                            right and ability to enter into these terms and to
                            use the Website and the Services in accordance with
                            these terms
                          </li>
                        </ol>
                      </li>
                      <br />
                      <li>
                        <h6 className="m-0 text-base fw-bold">
                          Intellectual Property Rights
                        </h6>
                        <ol type="i">
                          <li>
                            You acknowledge and agree that the Company,
                            Associate Companies or their respective licensors,
                            own all legal rights, title and interest in and to
                            the Services, including any Intellectual Property
                            Rights which subsist in the Service.
                          </li>
                          <li>
                            Unless you receive our express authorization in
                            writing, you agree that in using the Services or
                            accessing our Platform, you shall not use any
                            trademark, service mark, trade name, the logo of any
                            company or organization in a way that is likely or
                            intended to cause confusion about the owner or
                            authorized user of such marks, names or logos.
                          </li>
                          <li>
                            You further acknowledge that our Services may
                            contain information that is designated as
                            confidential by us and that you shall not disclose
                            such information without our prior written consent.
                          </li>
                        </ol>
                      </li>
                      <br />
                      <li>
                        <h6 className="m-0 text-base fw-bold">Miscellaneous</h6>
                        In order to provide the Services and access to the
                        Platform, the Company may be required to create a user
                        profile that may be linked to third-party platforms or
                        Associate Company’s platforms as may be necessary or
                        requested by you subject to verification. The
                        registration of the user profile shall require the
                        Company to retain certain User Data for providing a safe
                        and efficient user experience;
                        <br />
                        The Company, and their respective affiliates,
                        subsidiaries, directors, employees, promoters and agents
                        shall not be liable for:
                        <ol type="i">
                          <li>
                            any direct, indirect, incidental, special,
                            consequential or exemplary loss or damage suffered
                            by the User, however, caused and shall include any
                            loss of profit (whether incurred directly or
                            indirectly), any loss of goodwill or business
                            reputation, any loss of data suffered, cost of
                            procurement of substitute goods or Services, or
                            other intangible loss, arising out of the use of, or
                            inability to use or access of our Platform or
                            Service or any security breach or any virus, bug,
                            unauthorised intervention, technical malfunctioning
                            or defect, whether or not forceable and whether or
                            not we have been advised of the possibility of such
                            damage.
                          </li>
                          <li>
                            Any loss or damage incurred by you owing to any
                            temporary disablement or permanent discontinuance of
                            our Platform or Services and any deletion,
                            corruption or failure to store any content or other
                            communications maintained by your use of our
                            Services; and
                          </li>
                          <li>
                            which may be incurred by you, including but not
                            limited to loss or damage as a result of any
                            reliance placed by you on the completeness, accuracy
                            or existence of any advertising, or as a result of
                            any relationship or transaction between you and any
                            advertiser or sponsor whose advertisement appears on
                            the Services.
                          </li>
                          <li>
                            You agree to indemnify and keep the Company,
                            Associate Company, their respective affiliates and
                            licensors indemnified against all costs, expenses,
                            damages, losses and liabilities incurred or suffered
                            by such party related to any User Content posted or
                            transmitted by User during the use of the Platform
                            or Service or any unauthorised use of the Platform
                            or Service in contravention of this Terms.
                          </li>
                          <li>
                            The Privacy Policy and the Associate Company Terms
                            shall be read to form a part of these Terms;
                          </li>
                          <li>
                            These Terms shall be governed by and under the laws
                            of India and shall be subject to the exclusive
                            jurisdiction of Bengaluru. Any failure or delay by
                            the Company to exercise or enforce any right or
                            provision of the Terms shall not constitute a waiver
                            of such right or provision. If any part of the Terms
                            is found by a court of competent jurisdiction to be
                            invalid, the parties agree that the court should
                            endeavour to give effect to the parties’ intentions
                            as reflected in the provision, and the other
                            provisions of the Terms shall remain in full force
                            and effect. You agree that regardless of any
                            statutory period of limitation, any claim shall be
                            filed within one (1) year after the relevant cause
                            of action arose; s
                          </li>
                          <li>
                            The Company shall be absolutely entitled to
                            terminate your use of the Platform and the Services
                            in the event of any breach of these Terms by you, or
                            otherwise, any event which gives rise to any reason
                            for the Company to discontinue the offering of the
                            Platform or Services to you, without any notice or
                            obligations whatsoever;
                          </li>
                        </ol>
                      </li>
                    </ol>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TermsConditions;
