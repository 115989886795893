import React, { useState } from "react";
import { IoIosArrowDropdown } from "react-icons/io";
import { IoIosArrowDropup } from "react-icons/io";
import { BsQuestionCircleFill } from "react-icons/bs";
import "./Product.css";

function FAQ() {
  const partnershipFAQ = [
    {
      id: 0,
      ques: "Which regulatory bodies oversee your Company's operations e.g., RBI, SEBI or MCA?",
      ans: "Prices not Controlled i.e., by SCDND or by any centralized authority/operator. It is decided transparently and independently by the peer-to-peer transactions of the Clients themselves.",
    },
    {
      id: 1,
      ques: "What is the average duration of investor commitments with your Company?",
      ans: "Coupon is reset Higher, if the benchmark is revised higher (in a rising rate environment). But there is downside protection provided to Clients when the RBI published benchmark falls.",
    },
    {
      id: 2,
      ques: "How can I regularly verify that your Company complies with industry regulations to ensure the safety of my money?",
      ans: "Zero Liquidity-risk, as SCDND is the market-maker on both sides for the Client, being the Buyer to any seller and a Seller to any buyer, at all times.",
    },
    {
      id: 3,
      ques: "If the founder or any Key Managerial Personnel wishes to leave the Company, what is your contingency plan to ensure management continuity?",
      ans: "Zero Liquidity-risk, as SCDND is the market-maker on both sides for the Client, being the Buyer to any seller and a Seller to any buyer, at all times.",
    },
    {
      id: 4,
      ques: "How do you ensure that the Company&#39;s investment strategy and performance is not dependent on one individual (CEO, CIO etc.) or a specific team?",
      ans: "Zero Liquidity-risk, as SCDND is the market-maker on both sides for the Client, being the Buyer to any seller and a Seller to any buyer, at all times.",
    },
    {
      id: 5,
      ques: "Do you have any institutional investors currently invested with your Company? This would indicate that experienced market experts have vetted your Company.",
      ans: "Zero Liquidity-risk, as SCDND is the market-maker on both sides for the Client, being the Buyer to any seller and a Seller to any buyer, at all times.",
    },
    {
      id: 6,
      ques: "In the case of default or bankruptcy of your Company, what is the process followed under law for recoveries of liabilities? What are the clients classified as (secured creditors, unsecured creditors etc.)?",
      ans: "Zero Liquidity-risk, as SCDND is the market-maker on both sides for the Client, being the Buyer to any seller and a Seller toany buyer, at all times.",
    },
    {
      id: 7,
      ques: "Who has signing authority within the Company i.e., are the individuals responsible for day- to-day management and the Board of Directors the same group?",
      ans: "Zero Liquidity-risk, as SCDND is the market-maker on both sides for the Client, being the Buyer to any seller and a Seller to any buyer, at all times.",
    },
    {
      id: 8,
      ques: "Can you provide us with contact details of some of your clients so we can inquire about their experiences and comfort levels with your services?",
      ans: "Zero Liquidity-risk, as SCDND is the market-maker on both sides for the Client, being the Buyer to any seller and a Seller to any buyer, at all times.",
    },
  ];

  const [openIndex, setOpenIndex] = useState(-1);

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? -1 : index);
  };

  return (
    <div className="max-w-[1800px] mx-auto transition-all duration-500 ease-in-out mt-28">
      <div className="w-[90%] mx-auto border rounded-lg relative p-3">
        <h2 className="font-bold text-sm md:text-base lg:text-lg bg-white text-black absolute -top-4 px-2 w-fit">
          Frequently Asked Questions
        </h2>
        <div className="mt-3 ">
          <div className="flex flex-col gap-0 ">
            {partnershipFAQ.map((data, index) => (
              <div className="text-black" key={index}>
                <h2
                  className="flex flex-row justify-between items-center text-xs lg:text-base font-semibold p-3 cursor-pointer "
                  onClick={() => toggleAccordion(index)}
                >
                  <div className="flex items-start  gap-2">
                    <span>
                      <BsQuestionCircleFill className="text-base lg:text-xl " />
                    </span>
                    <span
                      className="font-semibold px-2 duration-500 ease-in-out"
                      style={{
                        color: openIndex === index ? "#339dfa" : "#212121",
                      }}
                    >
                      {data.ques}
                    </span>
                  </div>
                  <span className="duration-1000 ease-in-out">
                    {openIndex === index ? (
                      <IoIosArrowDropup
                        
                        className="duration-1000 ease-in-out text-[#339dfa] text-base lg:text-xl"
                      />
                    ) : (
                      <IoIosArrowDropdown
                      
                        className="duration-1000 ease-in-out text-[#339dfa] text-base lg:text-xl"
                      />
                    )}
                  </span>
                </h2>
                <div
                  style={{
                    maxHeight: openIndex === index ? "300px" : "0",
                  }}
                  className={`ml-7  border-l-2 border-[#339dfa]  overflow-hidden  duration-700 ease-in-out text-xs lg:text-base ${
                    openIndex === index ? "max-h-screen " : "max-h-0 "
                  }`}
                >
                  <p className=" py-1 pl-3 pr-4 text-[#212121] font-medium">
                    {data.ans}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default FAQ;
