import React from "react";
import Header from "../../components/Header/Header";
import { BsFillQuestionCircleFill } from "react-icons/bs";
import { RiBookmark3Fill } from "react-icons/ri";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

function PLFinancialInf() {
  return (
    <>
      <Header />
      <div className="after-header Partnerships-layer flex flex-col items-center max-w-[1800px] mx-auto">
        <p className="w-[90%] mx-auto text-sm lg:text-base ">
          <a href="/" className="hover:underline">
            Home
          </a>{" "}
          {">"}{" "}
          <a href="/partnerships" className="hover:underline">
            Partnership
          </a>{" "}
          {">"}{" "}
          <a href="/MinancialInf">
            <u>Financial Influencer</u>
          </a>
        </p>
        <div className="container-fluid w-full max-w-[90%] mx-auto mt-4  bg-gradient-to-tr from-[#dbedf9] to-[#e7f6ff] pt-5 px-2 lg:p-5 rounded-2xl">
          <div className="flex flex-wrap gap-y-10 gap-x-4 justify-around ">
            {/*       Card - 1     */}

            <div className=" w-full lg:max-w-[400px] md:max-w-[360px] h-max transition duration-500 ease-in-out hover:shadow-[0px_6px_0px_0px_#5af]  rounded-2xl   overflow-hidden cursor-pointer">
              <div className="w-full h-[470px] bg-white  rounded-xl     border-none overflow-hidden">
                <Tabs>
                  <TabPanel className="w-full bg-white rounded-xl  border-none h-[420px]">
                    <div className="   object-cover transition duration-300 ease-in-out hover:opacity-90">
                      <img
                        src="/images/TrustConflicts.jpg"
                        className=""
                        alt="scdnd estates"
                      />
                    </div>
                    <div className="p-3 pb-2  flex flex-col items-center overflow-x-hidden ">
                      {/* <h2 className=" text-xl font-bold tracking-tight text- uppercase bg-white">Lead Generation Challenges</h2> */}
                      <h3 className="text-gray-800 mb-2 text-xl font-bold tracking-tight  uppercase bg-white text-center">
                        Trust and conflicts of interest in investment advisory.
                      </h3>
                      <span className="bg-[#5af]  mb-2 inline-block h-0.5 w-[60%] rounded "></span>

                      <p className="  font-semibold bg-white text-base leading-5 text-gray-600 text-center">
                        Post-financial crisis, advisors discussing centralized
                        assets are viewed skeptically due to ties with
                        established institutions and ultra-rich price-setters.
                      </p>
                    </div>
                  </TabPanel>

                  <TabPanel className="w-full bg-white rounded-xl  border-none ">
                    <div className="p-4 overflow-scroll bg-white border-none overflow-x-hidden pb-2 flex flex-col items-start">
                      <h2 className="mb-3  text-xl font-bold  text-[#212121] ">
                        Decentralized Assets: Transparency & Control
                      </h2>

                      <div className="space-y-4 text-gray-500  h-[314px] bg-white ">
                        {[
                          "The prices of financial products are intentionally tied to publicly available external benchmarks, with ownership and transactions conducted on the blockchain.",
                          "This design removes centralized price-setters and record-keepers, making the ecosystem truly decentralized and returning control and benefits to the users.",
                        ].map((item, index) => (
                          <li
                            key={index}
                            className="flex  space-x-2 rtl:space-x-reverse items-start border-none bg-white"
                          >
                            <svg
                              className="flex-shrink-0 w-3.5 h-3.5 text-blue-600 leading-tight mt-[3px]"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                            >
                              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                            </svg>
                            <span className="leading-tight bg-white">
                              {item}
                            </span>
                          </li>
                        ))}
                      </div>
                    </div>
                  </TabPanel>

                  <TabList className="relative left-0 bottom-0 border-t-2 border-blue-400/50  w-full flex justify-evenly  text-sm font-medium text-center text-gray-500  rounded-b-lg bg-gray-400/15 overflow-hidden ">
                    <Tab className=" w-full  hover:bg-blue-400/10 overflow-hidden  rounded-none">
                      <button className=" py-2.5 px-1  w-full h-full rounded-none    font-semibold text-base flex items-center justify-center gap-2">
                        Problem <BsFillQuestionCircleFill size={15} />
                      </button>
                    </Tab>
                    <div className="bg-blue-400/50 w-[5px] z-10"></div>
                    <Tab className=" w-full hover:bg-blue-400/10 overflow-hidden rounded-none">
                      <button className="py-2.5 px-1  btn-shine w-full h-full rounded-none  text-[#414141] font-bold text-base flex items-center justify-center gap-2">
                        Solution <RiBookmark3Fill size={15} />
                      </button>
                    </Tab>
                  </TabList>
                </Tabs>
              </div>
            </div>

            {/*       Card - 2     */}

            <div className=" w-full lg:max-w-[400px] md:max-w-[360px] h-max  transition duration-500 ease-in-out hover:shadow-[0px_6px_0px_0px_#5af]  rounded-2xl   overflow-hidden cursor-pointer">
              <div className="w-full h-[470px] bg-white  rounded-xl     border-none overflow-hidden">
                <Tabs>
                  <TabPanel className="w-full bg-white rounded-xl  border-none h-[420px]">
                    <div className="   object-cover transition duration-300 ease-in-out hover:opacity-90">
                      <img
                        src="/images/Entrybar.jpg"
                        className=""
                        alt="scdnd estates"
                      />
                    </div>
                    <div className="p-3 pb-2  flex flex-col items-center overflow-x-hidden ">
                      {/* <h2 className=" text-xl font-bold tracking-tight text- uppercase bg-white">Lead Generation Challenges</h2> */}
                      <h3 className="text-gray-800 mb-2 text-xl font-bold tracking-tight  uppercase bg-white text-center">
                        The Low Entry Barrier in the World of Financial
                        Influence
                      </h3>
                      <span className="bg-[#5af]  mb-2 inline-block h-0.5 w-[60%] rounded "></span>

                      <p className="  font-semibold bg-white text-base leading-5 text-gray-600 text-center">
                        In the financial influencer industry, low entry barriers
                        and social media have made content quality the key
                        differentiator in global competition for audiences.
                      </p>
                    </div>
                  </TabPanel>

                  <TabPanel className="w-full bg-white rounded-xl  border-none ">
                    <div className="p-4 overflow-scroll bg-white border-none overflow-x-hidden pb-2">
                      <h2 className="mb-3  text-xl font-bold  text-[#212121] ">
                        The Advantage of Embracing Financial Innovation
                      </h2>

                      <div className="space-y-4 text-gray-500  h-[300px] md:h-[314px] bg-white ">
                        {[
                          "New entrants can potentially enjoy a significant first-mover advantage, leading to higher margins and market share, as their competitors are focused on traditional investments",
                          "The introduction of a revolutionary financial innovation can spark discussions, questions, and interactions among followers, driving increased engagement on the advisor's or influencer's platform.",
                        ].map((item, index) => (
                          <li
                            key={index}
                            className="flex  space-x-2 rtl:space-x-reverse items-start border-none bg-white"
                          >
                            <svg
                              className="flex-shrink-0 w-3.5 h-3.5 text-blue-600 leading-tight mt-[3px]"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                            >
                              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                            </svg>
                            <span className="leading-tight bg-white">
                              {item}
                            </span>
                          </li>
                        ))}
                      </div>
                    </div>
                  </TabPanel>

                  <TabList className="relative left-0 bottom-0 border-t-2 border-blue-400/50  w-full flex justify-evenly  text-sm font-medium text-center text-gray-500  rounded-b-lg bg-gray-400/15 overflow-hidden ">
                    <Tab className=" w-full  hover:bg-blue-400/10 overflow-hidden  rounded-none">
                      <button className=" py-2.5 px-1  w-full h-full rounded-none    font-semibold text-base flex items-center justify-center gap-2">
                        Problem <BsFillQuestionCircleFill size={15} />
                      </button>
                    </Tab>
                    <div className="bg-blue-400/50 w-[5px] z-10"></div>
                    <Tab className=" w-full hover:bg-blue-400/10 overflow-hidden rounded-none">
                      <button className="py-2.5 px-1  btn-shine w-full h-full rounded-none   font-bold text-base flex items-center justify-center gap-2">
                        Solution <RiBookmark3Fill size={15} />
                      </button>
                    </Tab>
                  </TabList>
                </Tabs>
              </div>
            </div>

            {/*       Card - 3     */}

            <div className=" w-full lg:max-w-[400px] md:max-w-[360px] h-max  transition duration-500 ease-in-out hover:shadow-[0px_6px_0px_0px_#5af]  rounded-2xl   overflow-hidden cursor-pointer">
              <div className="w-full h-[470px] bg-white  rounded-xl     border-none overflow-hidden">
                <Tabs>
                  <TabPanel className="w-full bg-white rounded-xl  border-none h-[420px] overflow-hidden">
                    <div className="   object-cover transition duration-300 ease-in-out hover:opacity-90">
                      <img
                        src="/images/Content.jpg"
                        className=""
                        alt="scdnd estates"
                      />
                    </div>
                    <div className="p-3 pb-2  flex flex-col items-center overflow-x-hidden ">
                      {/* <h2 className=" text-xl font-bold tracking-tight text- uppercase bg-white">Lead Generation Challenges</h2> */}
                      <h3 className="text-gray-800 mb-2 text-xl font-bold tracking-tight  uppercase bg-white text-center">
                        The Content Challenge
                      </h3>
                      <span className="bg-[#5af]  mb-2 inline-block h-0.5 w-[60%] rounded "></span>

                      <p className="  font-semibold bg-white text-base leading-5 text-gray-600 text-center">
                        Staying engaging with fresh content in a repetitive
                        industry is challenging, while staying updated on market
                        trends is crucial for accurate investing advice.
                      </p>
                    </div>
                  </TabPanel>

                  <TabPanel className="w-full bg-white rounded-xl  border-none ">
                    <div className="p-4 overflow-scroll bg-white border-none overflow-x-hidden pb-2">
                      <h2 className="mb-3 flex flex-wrap items-center text-xl font-bold  text-[#212121] ">
                        Game-Changing Solution for Financial Advisors
                      </h2>

                      <div className="space-y-4 text-gray-500  h-[314px] bg-white ">
                        {[
                          "The performance risk of their recommendations is fully assumed by SCDND, eliminating the need for advisors/influencers to conduct real-time market research, which can be costly and time-consuming.",
                          "Additionally, the risk of follower disillusionment due to market volatility, which can hampers new subscriber additions and retention, is significantly reduced.",
                        ].map((item, index) => (
                          <li
                            key={index}
                            className="flex  space-x-2 rtl:space-x-reverse items-start border-none bg-white"
                          >
                            <svg
                              className="flex-shrink-0 w-3.5 h-3.5 text-blue-600 leading-tight mt-[3px]"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                            >
                              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                            </svg>
                            <span className="leading-tight bg-white">
                              {item}
                            </span>
                          </li>
                        ))}
                      </div>
                    </div>
                  </TabPanel>

                  <TabList className="relative left-0 bottom-0 border-t-2 border-blue-400/50  w-full flex justify-evenly  text-sm font-medium text-center text-gray-500  rounded-b-lg bg-gray-400/15 overflow-hidden ">
                    <Tab className=" w-full  hover:bg-blue-400/10 overflow-hidden  rounded-none">
                      <button className=" py-2.5 px-1  w-full h-full rounded-none    font-semibold text-base flex items-center justify-center gap-2">
                        Problem <BsFillQuestionCircleFill size={15} />
                      </button>
                    </Tab>
                    <div className="bg-blue-400/50 w-[5px] z-10"></div>
                    <Tab className=" w-full hover:bg-blue-400/10 overflow-hidden rounded-none">
                      <button className="py-2.5 px-1  btn-shine w-full h-full rounded-none   font-bold text-base flex items-center justify-center gap-2">
                        Solution <RiBookmark3Fill size={15} />
                      </button>
                    </Tab>
                  </TabList>
                </Tabs>
              </div>
            </div>
          </div>

          <div className="my-4 text-center pt-3 xl:px-20">
            <p className=" just-content-center px-4 pt-3 leading-7 text-sm md:text-base font-semibold">
              In a world where innovation is key to capturing and retaining
              audience attention, we offer financial influencers the opportunity
              to elevate their influence to new heights. Our financial product
              is a game-changer, enabling influencers to provide their followers
              with cutting-edge information and experiences.
            </p>
            <p className="mb-3 just-content-center px-4 pt-1 leading-7 text-sm md:text-base font-semibold">
              Don't let the challenges of stagnant content and monetization hold
              you back. Join us in this exciting journey to revolutionize the
              financial industry and secure your position as a pioneer in
              financial innovation. Together, we can turn the challenges of
              today into the opportunities of tomorrow, ensuring that financial
              influencers continue to be the trusted guides to financial success
              for their loyal followers.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default PLFinancialInf;
