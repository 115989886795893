import React from 'react'

function AlertGreen({message, onClose}) {
  return (
  <>
  <div className="fixed w-full flex left-0 items-start justify-center z-[9999999] top-20 alert-animation">
    <div
    class="bg-green-100 max-w-lg mx-3 border-l-4 border-green-500 text-green-900 p-2 rounded-r-lg flex items-center transition duration-300 ease-in-out hover:bg-green-200 transform  scale-100 hover:scale-105 justify-between"
  >
    <div className="flex items-center justify-start">
    <svg id="fi_5253968" height="24" viewBox="0 0 48 48" width="24" xmlns="http://www.w3.org/2000/svg" data-name="Layer 2"><path d="m44.6807 19.2949a8.1106 8.1106 0 0 1 -1.6538-1.4749 7.5758 7.5758 0 0 1 .4687-2.3008c.5166-1.749 1.16-3.9257-.08-5.6289-1.249-1.7168-3.5307-1.7744-5.3638-1.8213a7.86 7.86 0 0 1 -2.2895-.2461 7.7833 7.7833 0 0 1 -.9307-2.081c-.6116-1.7419-1.3736-3.9099-3.4141-4.573-1.98-.6435-3.7569.5791-5.3237 1.6543a7.4606 7.4606 0 0 1 -2.0933 1.1768 7.4483 7.4483 0 0 1 -2.0942-1.1768c-1.5669-1.0761-3.3443-2.2949-5.3238-1.6543-2.04.6631-2.8017 2.83-3.414 4.5723a7.9346 7.9346 0 0 1 -.9209 2.0742 7.6822 7.6822 0 0 1 -2.2994.253c-1.833.0468-4.1147.1045-5.3637 1.8212-1.24 1.7042-.5967 3.8809-.08 5.63a7.7906 7.7906 0 0 1 .4731 2.2862 7.8263 7.8263 0 0 1 -1.6587 1.4892c-1.4789 1.1279-3.3189 2.5321-3.3189 4.705s1.84 3.5771 3.3193 4.7051a8.1106 8.1106 0 0 1 1.6538 1.4749 7.5758 7.5758 0 0 1 -.4687 2.3008c-.5166 1.749-1.16 3.9258.08 5.6289 1.249 1.7168 3.5307 1.7744 5.3638 1.8213a7.86 7.86 0 0 1 2.2895.2461 7.7833 7.7833 0 0 1 .9307 2.081c.6123 1.7422 1.374 3.91 3.4145 4.5733a3.5928 3.5928 0 0 0 1.12.1787c1.5357 0 2.938-.9639 4.2037-1.833a7.4589 7.4589 0 0 1 2.0934-1.1771 7.45 7.45 0 0 1 2.0943 1.1768c1.5668 1.0761 3.3437 2.2939 5.3237 1.6543 2.04-.6631 2.8017-2.83 3.414-4.5723a7.9346 7.9346 0 0 1 .9209-2.0742 7.6822 7.6822 0 0 1 2.2994-.253c1.833-.0468 4.1147-.1045 5.3637-1.8212 1.24-1.7041.5967-3.8809.08-5.63a7.7906 7.7906 0 0 1 -.4731-2.2862 7.8263 7.8263 0 0 1 1.6587-1.4892c1.4784-1.1279 3.3184-2.5321 3.3184-4.705s-1.84-3.5771-3.3193-4.7051zm-11.7666 1.1192-10 10a1.9995 1.9995 0 0 1 -2.8282 0l-5-5a2 2 0 1 1 2.8282-2.8282l3.5859 3.586 8.5859-8.586a2 2 0 1 1 2.8282 2.8282z" fill="#22c55e"></path></svg>
    <p class="pl-2 text-xs font-semibold">
    {message}
    </p></div>
    <button onClick={onClose} className="flex items-center ml-3" ><svg className="fill-gray-900 hover:fill-red-500 scale-100 hover:scale-90 duration-200 ease-in-out" height="20" viewBox="0 0 32 32" width="20" xmlns="http://www.w3.org/2000/svg" id="fi_2734822"><g id="Layer_22" data-name="Layer 22"><path d="m21 12.46-3.59 3.54 3.59 3.54a1 1 0 0 1 0 1.46 1 1 0 0 1 -.71.29 1 1 0 0 1 -.7-.29l-3.59-3.59-3.54 3.59a1 1 0 0 1 -.7.29 1 1 0 0 1 -.71-.29 1 1 0 0 1 0-1.41l3.54-3.59-3.54-3.54a1 1 0 0 1 1.41-1.41l3.54 3.54 3.54-3.54a1 1 0 0 1 1.46 1.41zm4.9 13.44a14 14 0 1 1 0-19.8 14 14 0 0 1 0 19.8zm-1.41-18.39a12 12 0 1 0 0 17 12 12 0 0 0 0-17z"></path></g></svg></button>
  </div>
  </div>
  </>
  )
}

export default AlertGreen;