import React, { useState, useEffect, useRef } from "react";
import "./Partnerships.css";
import PartnershipApi from "../../API/PartnershipApi";
import FAQ from "./FAQ.jsx";
import Header from "../../components/Header/Header";
import { SiTicktick } from "react-icons/si";
import { IoCloseCircle } from "react-icons/io5";

function Partnerships() {
  const popupRef = useRef(null);
  const [show, setShow] = useState(-1);
  const [close, setClose] = useState("");
  const options = [
    {
      id: "0",
      img: "/images/REbroker.png",
      label: "REAL ESTATE BROKER",
      checked: true,
      link: `/real_state_broker/taketest`,
    },
    {
      id: "1",
      img: "/images/Stockbroker.png",
      label: "STOCK BROKER",
      checked: false,
      link: `/stock_broker/taketest`,
    },
    {
      id: "2",
      img: "/images/Mutualfunds.png",
      label: "MUTUAL FUND DISTRIBUTOR",
      checked: false,
      link: `/mutual_fund_distributor/taketest`,
    },
    {
      id: "3",
      img: "/images/Influence.png",
      label: "FINANCIAL INFLUENCER",
      checked: false,
      link: `/financial_influencer/taketest`,
    },
    {
      id: "4",
      img: "/images/InfAgent.png",
      label: "INSURANCE AGENT",
      checked: false,
      link: `/insurance_agent/taketest`,
    },
    {
      id: "5",
      img: "/images/VentureCap.png",
      label: "FOREIGN PORTFOLIO",
      checked: false,
      link: `/foreign_portfolio/taketest`,
    },
  ];

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setClose("");
    }
  };

  useEffect(() => {
    if (close !== "") {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [close]);

  return (
    <>
      <Header />
      <div className="container-fluid  after-header max-w-[1800px] mx-auto">
        
        <div className="hidden-768 lg:flex items-center justify-between w-[90%] mx-auto min-h-[700px] md:min-h-[700px] lg:min-h-[500px]">
          <div className="w-full lg:w-[49%] md:p-3">
            <h2 className="text-xl md:text-4xl font-bold text-black md:leading-[40px]">
              Elevate your business by joining SCDND's partnership program
            </h2>
            <p className="text-black mt-12 leading-normal flex flex-col gap-3 text-sm md:text-base">
              <span className="flex items-start gap-1.5">
                <span>
                  <SiTicktick className="mt-1 text-[#38BDF8]" />
                </span>
                Earn recurring commissions by helping us create customized
                investment solutions to perfectly fit your client's portfolio
              </span>
              <span className="flex items-start gap-1.5">
                <span>
                  <SiTicktick className="mt-1 text-[#38BDF8]" />
                </span>
                Market and Distribute our in-house designed hybrid securities to
                your business network.
              </span>
              <span className="flex items-start gap-1.5">
                <span>
                  <SiTicktick className="mt-1 text-[#38BDF8]" />
                </span>
                Introduce your existing customer base to the next generation of
                financial assets, vastly improving the efficiency of your
                distribution channel as well as User experience.
              </span>
            </p>
            <button
              onClick={() => setClose(true)}
              class="relative inline-flex items-center rounded-full overflow-hidden shadow-md border group border-none bg-white text-black mt-5"
            >
              <span class="absolute inset-0 bg-[#38BDF8] translate-x-[-100%] transition-transform duration-300 group-hover:translate-x-0"></span>
              <div class="relative z-10 flex items-center text-sm font-semibold">
                <div class="w-10 h-8 bg-[#38BDF8] flex items-center justify-center rounded-l-full">
                  <svg
                    viewBox="0 0 50 50"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                  >
                    <circle
                      opacity="0.5"
                      cx="25"
                      cy="25"
                      r="23"
                      fill="url(#icon-payments-cat_svg__paint0_linear_1141_21101)"
                    ></circle>
                    <mask id="icon-payments-cat_svg__a" fill="#fff">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M34.42 15.93c.382-1.145-.706-2.234-1.851-1.852l-18.568 6.189c-1.186.395-1.362 2-.29 2.644l5.12 3.072a1.464 1.464 0 001.733-.167l5.394-4.854a1.464 1.464 0 011.958 2.177l-5.154 4.638a1.464 1.464 0 00-.276 1.841l3.101 5.17c.644 1.072 2.25.896 2.645-.29L34.42 15.93z"
                      ></path>
                    </mask>
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M34.42 15.93c.382-1.145-.706-2.234-1.851-1.852l-18.568 6.189c-1.186.395-1.362 2-.29 2.644l5.12 3.072a1.464 1.464 0 001.733-.167l5.394-4.854a1.464 1.464 0 011.958 2.177l-5.154 4.638a1.464 1.464 0 00-.276 1.841l3.101 5.17c.644 1.072 2.25.896 2.645-.29L34.42 15.93z"
                      fill="#fff"
                    ></path>
                    <defs>
                      <linearGradient
                        id="icon-payments-cat_svg__paint0_linear_1141_21101"
                        x1="25"
                        y1="2"
                        x2="25"
                        y2="48"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#fff" stop-opacity="0.71"></stop>
                        <stop
                          offset="1"
                          stop-color="#fff"
                          stop-opacity="0"
                        ></stop>
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
                <span class="inline-block transition-colors duration-200 pl-3 pr-6 text-black group-hover:text-white">
                  Connect Now
                </span>
              </div>
            </button>
          </div>

          {/* Circular motion */}
          <div className="w-full lg:w-[49%] flex justify-center items-center scale-[0.4] md:scale-[0.7] lg:scale-[0.5] xl:scale-[0.7] 2xl:scale-[0.8]">
            <div className="h-full w-full flex items-center justify-center">
              <div className="profileCard_container flex items-center justify-center w-full h-full relative ">
                <svg className="h-[700px] w-[800px] absolute max-lg:top-12 ">
                  <defs>
                    <path
                      id="circlePath"
                      d="M 378, 320 m -358, 0 a 358, 100 0 1,0 716,0 a 358, 100 0 1,0 -716,0"
                      fill="none"
                    />
                    <path
                      id="circlePath2"
                      d="M 378, 320 m -253.217, -253.217 a 358, 100 45 1,0 506.434, 506.434 a 358, 100 45 1,0 -506.434, -506.434"
                      fill="none"
                    />
                    <path
                      id="circlePath3"
                      d="M 378, 320 m -253.217, 253.217 a 358, 100 135 1,0 506.434, -506.434 a 358, 100 135 1,0 -506.434, 506.434"
                      fill="none"
                    />
                    <path
                      id="circlePath4"
                      d="M 378, 320 m -358, 0 a 358, 100 0 1,1 716,0 a 358, 100 0 1,1 -716,0"
                      fill="none"
                    />
                    <path
                      id="circlePath5"
                      d="M 378, 320 m -253.217, -253.217 a 358, 100 45 1,1 506.434, 506.434 a 358, 100 45 1,1 -506.434, -506.434"
                      fill="none"
                    />
                    <path
                      id="circlePath6"
                      d="M 378, 320 m -253.217, 253.217 a 358, 100 135 1,1 506.434, -506.434 a 358, 100 135 1,1 -506.434, 506.434"
                      fill="none"
                    />
                    {/* <!-- Gradient Definition for Border --> */}
                    <linearGradient
                      id="gradientBorder"
                      x1="0%"
                      y1="0%"
                      x2="100%"
                      y2="0%"
                    >
                      <stop
                        offset="0%"
                        style={{ stopColor: "#000", stopOpacity: "1" }} // orange
                      />
                      <stop
                        offset="50%"
                        style={{ stopColor: "#000", stopOpacity: "1" }} //light orange
                      />
                      <stop
                        offset="100%"
                        style={{ stopColor: "#000", stopOpacity: "1" }}
                      />
                    </linearGradient>
                    <filter
                      id="dropShadow"
                      x="-50%"
                      y="-50%"
                      width="200%"
                      height="200%"
                    >
                      {/* <!-- Increase blur for a larger shadow --> */}
                      <feGaussianBlur in="SourceAlpha" stdDeviation="40" />
                      {/* <!-- Set dx and dy to 0 to make the shadow evenly spread around --> */}
                      <feOffset dx="0" dy="0" result="offsetblur" />
                      {/* <!-- Animate the flood-color for light-to-dark transition --> */}
                      <feFlood
                        id="animatedFlood"
                        flood-color="rgba(0,0,0,0.1)"
                        flood-opacity="1"
                      />
                      <feComposite in2="offsetblur" operator="in" />
                      <feMerge>
                        <feMergeNode />
                        <feMergeNode in="SourceGraphic" />
                      </feMerge>
                    </filter>
                    <filter
                      id="dropShadow2"
                      x="-50%"
                      y="-50%"
                      width="200%"
                      height="200%"
                    >
                      {/* <!-- Increase blur for a larger shadow --> */}
                      <feGaussianBlur in="SourceAlpha" stdDeviation="3" />
                      {/* <!-- Set dx and dy to 0 to make the shadow evenly spread around --> */}
                      <feOffset dx="1" dy="0" result="offsetblur" />
                      {/* <!-- Animate the flood-color for light-to-dark transition --> */}
                      <feFlood flood-color="#94a3b8" flood-opacity="1" />
                      <feComposite in2="offsetblur" operator="in" />
                      <feMerge>
                        <feMergeNode />
                        <feMergeNode in="SourceGraphic" />
                      </feMerge>
                    </filter>
                  </defs>
                  {/* <!-- Background Ellipses --> */}
                  {/* <!-- Left half of the ellipse --> */}
                  <path
                    d="M 378, 340 m -358, 0 a 358, 100 0 1,0 716,0 a 358, 100 0 1,0 -716,0"
                    fill="none"
                    stroke="#9fa6b28a"
                    strokeWidth="2"
                    strokeDasharray={3}
                  />
                  <path
                    d="M 378, 340 m -253.217, -253.217 a 358, 100 45 1,0 506.434, 506.434 a 358, 100 45 1,0 -506.434, -506.434"
                    fill="none"
                    stroke="#9fa6b28a"
                    strokeWidth="2"
                    strokeDasharray={3}
                  />
                  <path
                    d="M 378, 340 m -253.217, 253.217 a 358, 100 135 1,0 506.434, -506.434 a 358, 100 135 1,0 -506.434, 506.434"
                    fill="none"
                    stroke="#9fa6b28a"
                    strokeWidth="2"
                    strokeDasharray={3}
                  />
                  {/* 
                    <!-- Moving Icons --> */}
                  {/* <!-- 1st Moving Icon (Behind for the first half) --> */}
                  <g>
                    <circle
                      cx="20"
                      cy="20"
                      r="35"
                      fill="#fff"
                      stroke="#e6e6e6"
                      strokeWidth="1.5"
                      filter="url(#dropShadow2)"
                    >
                      <animateMotion repeatCount="indefinite" dur="9s">
                        <mpath href="#circlePath" />
                      </animateMotion>
                    </circle>
                    <image
                      xlinkHref="/images/REbroker.png"
                      width="40"
                      height="40"
                    >
                      <animateMotion repeatCount="indefinite" dur="9s">
                        <mpath href="#circlePath" />
                      </animateMotion>
                    </image>
                  </g>
                  <g>
                    <circle
                      cx="20"
                      cy="20"
                      r="35"
                      fill="#fff"
                      stroke="#e6e6e6"
                      strokeWidth="1.5"
                      filter="url(#dropShadow2)"
                    >
                      <animateMotion repeatCount="indefinite" dur="15s">
                        <mpath href="#circlePath2" />
                      </animateMotion>
                    </circle>
                    <image
                      xlinkHref="/images/Stockbroker.png"
                      width="40"
                      height="40"
                    >
                      <animateMotion repeatCount="indefinite" dur="15s">
                        <mpath href="#circlePath2" />
                      </animateMotion>
                    </image>
                  </g>
                  {/* <!-- Center Image for Layering Control (Front layer here) --> */}
                  <g>
                    <circle
                      cx="375"
                      cy="345"
                      r="56"
                      fill="#fff"
                      stroke="url(#gradientBorder)" //<!-- Visible blue border -->
                      strokeWidth="0" //<!-- Thickness of the border -->
                      class="stroke-current text-red-500"
                      filter="url(#dropShadow)" // <!-- Applying the shadow -->
                    >
                      <animateTransform attributeName="transform" />
                    </circle>

                    <image
                      xlinkHref="/images/center-image.svg"
                      x="310"
                      y="280"
                      width="130"
                      height="130"
                    />
                  </g>
                  <g>
                    <circle
                      cx="20"
                      cy="20"
                      r="35"
                      fill="#fff"
                      stroke="#e6e6e6"
                      strokeWidth="1.5"
                      filter="url(#dropShadow2)"
                    >
                      <animateMotion repeatCount="indefinite" dur="7s">
                        <mpath href="#circlePath3" />
                      </animateMotion>
                    </circle>
                    <image
                      xlinkHref="/images/Mutualfunds.png"
                      width="40"
                      height="40"
                    >
                      <animateMotion repeatCount="indefinite" dur="7s">
                        <mpath href="#circlePath3" />
                      </animateMotion>
                    </image>
                  </g>
                  {/* <!-- 1st Moving Icon (Front for the second half) --> */}
                  <g>
                    <circle
                      cx="20"
                      cy="20"
                      r="35"
                      fill="#fff"
                      stroke="#e6e6e6"
                      strokeWidth="1.5"
                      filter="url(#dropShadow2)"
                    >
                      <animateMotion repeatCount="indefinite" dur="12s">
                        <mpath href="#circlePath4" />
                      </animateMotion>
                    </circle>
                    <image
                      xlinkHref="/images/Influence.png"
                      width="40"
                      height="40"
                    >
                      <animateMotion repeatCount="indefinite" dur="12s">
                        <mpath href="#circlePath4" />
                      </animateMotion>
                    </image>
                  </g>
                  <g>
                    <circle
                      cx="20"
                      cy="20"
                      r="35"
                      fill="#fff"
                      stroke="#e6e6e6"
                      strokeWidth="1.5"
                      filter="url(#dropShadow2)"
                    >
                      <animateMotion repeatCount="indefinite" dur="4s">
                        <mpath href="#circlePath5" />
                      </animateMotion>
                    </circle>
                    <image
                      xlinkHref="/images/InfAgent.png"
                      width="40"
                      height="40"
                    >
                      <animateMotion repeatCount="indefinite" dur="4s">
                        <mpath href="#circlePath5" />
                      </animateMotion>
                    </image>
                  </g>
                  <g>
                    <circle
                      cx="20"
                      cy="20"
                      r="35"
                      fill="#fff"
                      stroke="#e6e6e6"
                      strokeWidth="1.5"
                      filter="url(#dropShadow2)"
                    >
                      <animateMotion repeatCount="indefinite" dur="21s">
                        <mpath href="#circlePath6" />
                      </animateMotion>
                    </circle>
                    <image
                      xlinkHref="/images/VentureCap.png"
                      width="40"
                      height="40"
                    >
                      <animateMotion repeatCount="indefinite" dur="21s">
                        <mpath href="#circlePath6" />
                      </animateMotion>
                    </image>
                  </g>
                </svg>
              </div>
            </div>
          </div>
        </div>

        {/* small screen */}
        <div className="lg:hidden items-center justify-between w-[90%] mx-auto min-h-[800px] md:min-h-[950px] lg:min-h-[650px]">
          <div className="w-full lg:w-[49%] md:p-3">
            <h2 className="text-xl md:text-4xl font-bold text-black md:leading-[40px]">
              Elevate your business by joining SCDND's partnership program
            </h2>
            {/* Circular motion */}
            <div className="w-full lg:w-[49%] flex justify-center items-center scale-[0.4] md:scale-[0.7] lg:scale-[0.5] xl:scale-[0.7] 2xl:scale-[0.8]">
              <div className="h-full w-full flex items-center justify-center">
                <div className="profileCard_container flex items-center justify-center w-full h-full relative ">
                  <svg className="h-[700px] w-[800px] absolute max-lg:top-12 ml-12">
                    <defs>
                      <path
                        id="circlePath"
                        d="M 378, 320 m -358, 0 a 358, 100 0 1,0 716,0 a 358, 100 0 1,0 -716,0"
                        fill="none"
                      />
                      <path
                        id="circlePath2"
                        d="M 378, 320 m -253.217, -253.217 a 358, 100 45 1,0 506.434, 506.434 a 358, 100 45 1,0 -506.434, -506.434"
                        fill="none"
                      />
                      <path
                        id="circlePath3"
                        d="M 378, 320 m -253.217, 253.217 a 358, 100 135 1,0 506.434, -506.434 a 358, 100 135 1,0 -506.434, 506.434"
                        fill="none"
                      />
                      <path
                        id="circlePath4"
                        d="M 378, 320 m -358, 0 a 358, 100 0 1,1 716,0 a 358, 100 0 1,1 -716,0"
                        fill="none"
                      />
                      <path
                        id="circlePath5"
                        d="M 378, 320 m -253.217, -253.217 a 358, 100 45 1,1 506.434, 506.434 a 358, 100 45 1,1 -506.434, -506.434"
                        fill="none"
                      />
                      <path
                        id="circlePath6"
                        d="M 378, 320 m -253.217, 253.217 a 358, 100 135 1,1 506.434, -506.434 a 358, 100 135 1,1 -506.434, 506.434"
                        fill="none"
                      />
                      {/* <!-- Gradient Definition for Border --> */}
                      <linearGradient
                        id="gradientBorder"
                        x1="0%"
                        y1="0%"
                        x2="100%"
                        y2="0%"
                      >
                        <stop
                          offset="0%"
                          style={{ stopColor: "#000", stopOpacity: "1" }} // orange
                        />
                        <stop
                          offset="50%"
                          style={{ stopColor: "#000", stopOpacity: "1" }} //light orange
                        />
                        <stop
                          offset="100%"
                          style={{ stopColor: "#000", stopOpacity: "1" }}
                        />
                      </linearGradient>
                      <filter
                        id="dropShadow"
                        x="-50%"
                        y="-50%"
                        width="200%"
                        height="200%"
                      >
                        {/* <!-- Increase blur for a larger shadow --> */}
                        <feGaussianBlur in="SourceAlpha" stdDeviation="40" />
                        {/* <!-- Set dx and dy to 0 to make the shadow evenly spread around --> */}
                        <feOffset dx="0" dy="0" result="offsetblur" />
                        {/* <!-- Animate the flood-color for light-to-dark transition --> */}
                        <feFlood
                          id="animatedFlood2"
                          flood-color="rgba(0,0,0,0.1)"
                          flood-opacity="1"
                        />
                        <feComposite in2="offsetblur" operator="in" />
                        <feMerge>
                          <feMergeNode />
                          <feMergeNode in="SourceGraphic" />
                        </feMerge>
                      </filter>
                      <filter
                        id="dropShadow2"
                        x="-50%"
                        y="-50%"
                        width="200%"
                        height="200%"
                      >
                        {/* <!-- Increase blur for a larger shadow --> */}
                        <feGaussianBlur in="SourceAlpha" stdDeviation="3" />
                        {/* <!-- Set dx and dy to 0 to make the shadow evenly spread around --> */}
                        <feOffset dx="1" dy="0" result="offsetblur" />
                        {/* <!-- Animate the flood-color for light-to-dark transition --> */}
                        <feFlood flood-color="#94a3b8" flood-opacity="1" />
                        <feComposite in2="offsetblur" operator="in" />
                        <feMerge>
                          <feMergeNode />
                          <feMergeNode in="SourceGraphic" />
                        </feMerge>
                      </filter>
                    </defs>
                    {/* <!-- Background Ellipses --> */}
                    {/* <!-- Left half of the ellipse --> */}
                    <path
                      d="M 378, 340 m -358, 0 a 358, 100 0 1,0 716,0 a 358, 100 0 1,0 -716,0"
                      fill="none"
                      stroke="#9fa6b28a"
                      strokeWidth="2"
                      strokeDasharray={3}
                    />
                    <path
                      d="M 378, 340 m -253.217, -253.217 a 358, 100 45 1,0 506.434, 506.434 a 358, 100 45 1,0 -506.434, -506.434"
                      fill="none"
                      stroke="#9fa6b28a"
                      strokeWidth="2"
                      strokeDasharray={3}
                    />
                    <path
                      d="M 378, 340 m -253.217, 253.217 a 358, 100 135 1,0 506.434, -506.434 a 358, 100 135 1,0 -506.434, 506.434"
                      fill="none"
                      stroke="#9fa6b28a"
                      strokeWidth="2"
                      strokeDasharray={3}
                    />
                    {/* 
                    <!-- Moving Icons --> */}
                    {/* <!-- 1st Moving Icon (Behind for the first half) --> */}
                    <g>
                      <circle
                        cx="20"
                        cy="20"
                        r="35"
                        fill="#fff"
                        stroke="#e6e6e6"
                        strokeWidth="1.5"
                        filter="url(#dropShadow2)"
                      >
                        <animateMotion repeatCount="indefinite" dur="11s">
                          <mpath href="#circlePath" />
                        </animateMotion>
                      </circle>
                      <image
                        xlinkHref="/images/REbroker.png"
                        width="40"
                        height="40"
                      >
                        <animateMotion repeatCount="indefinite" dur="11s">
                          <mpath href="#circlePath" />
                        </animateMotion>
                      </image>
                    </g>
                    <g>
                      <circle
                        cx="20"
                        cy="20"
                        r="35"
                        fill="#fff"
                        stroke="#e6e6e6"
                        strokeWidth="1.5"
                        filter="url(#dropShadow2)"
                      >
                        <animateMotion repeatCount="indefinite" dur="7s">
                          <mpath href="#circlePath2" />
                        </animateMotion>
                      </circle>
                      <image
                        xlinkHref="/images/Stockbroker.png"
                        width="40"
                        height="40"
                      >
                        <animateMotion repeatCount="indefinite" dur="7s">
                          <mpath href="#circlePath2" />
                        </animateMotion>
                      </image>
                    </g>
                    {/* <!-- Center Image for Layering Control (Front layer here) --> */}
                    <g>
                      <circle
                        cx="375"
                        cy="345"
                        r="56"
                        fill="#fff"
                        stroke="url(#gradientBorder)" //<!-- Visible blue border -->
                        strokeWidth="0" //<!-- Thickness of the border -->
                        class="stroke-current text-red-500"
                        filter="url(#dropShadow)" // <!-- Applying the shadow -->
                      >
                        <animateTransform attributeName="transform" />
                      </circle>

                      <image
                        xlinkHref="/images/center-image.svg"
                        x="310"
                        y="280"
                        width="130"
                        height="130"
                      />
                    </g>
                    <g>
                      <circle
                        cx="20"
                        cy="20"
                        r="35"
                        fill="#fff"
                        stroke="#e6e6e6"
                        strokeWidth="1.5"
                        filter="url(#dropShadow2)"
                      >
                        <animateMotion repeatCount="indefinite" dur="11s">
                          <mpath href="#circlePath3" />
                        </animateMotion>
                      </circle>
                      <image
                        xlinkHref="/images/Mutualfunds.png"
                        width="40"
                        height="40"
                      >
                        <animateMotion repeatCount="indefinite" dur="11s">
                          <mpath href="#circlePath3" />
                        </animateMotion>
                      </image>
                    </g>
                    {/* <!-- 1st Moving Icon (Front for the second half) --> */}
                    <g>
                      <circle
                        cx="20"
                        cy="20"
                        r="35"
                        fill="#fff"
                        stroke="#e6e6e6"
                        strokeWidth="1.5"
                        filter="url(#dropShadow2)"
                      >
                        <animateMotion repeatCount="indefinite" dur="5s">
                          <mpath href="#circlePath4" />
                        </animateMotion>
                      </circle>
                      <image
                        xlinkHref="/images/Influence.png"
                        width="40"
                        height="40"
                      >
                        <animateMotion repeatCount="indefinite" dur="5s">
                          <mpath href="#circlePath4" />
                        </animateMotion>
                      </image>
                    </g>
                    <g>
                      <circle
                        cx="20"
                        cy="20"
                        r="35"
                        fill="#fff"
                        stroke="#e6e6e6"
                        strokeWidth="1.5"
                        filter="url(#dropShadow2)"
                      >
                        <animateMotion repeatCount="indefinite" dur="9s">
                          <mpath href="#circlePath5" />
                        </animateMotion>
                      </circle>
                      <image
                        xlinkHref="/images/InfAgent.png"
                        width="40"
                        height="40"
                      >
                        <animateMotion repeatCount="indefinite" dur="9s">
                          <mpath href="#circlePath5" />
                        </animateMotion>
                      </image>
                    </g>
                    <g>
                      <circle
                        cx="20"
                        cy="20"
                        r="35"
                        fill="#fff"
                        stroke="#e6e6e6"
                        strokeWidth="1.5"
                        filter="url(#dropShadow2)"
                      >
                        <animateMotion repeatCount="indefinite" dur="9s">
                          <mpath href="#circlePath6" />
                        </animateMotion>
                      </circle>
                      <image
                        xlinkHref="/images/VentureCap.png"
                        width="40"
                        height="40"
                      >
                        <animateMotion repeatCount="indefinite" dur="9s">
                          <mpath href="#circlePath6" />
                        </animateMotion>
                      </image>
                    </g>
                  </svg>
                </div>
              </div>
            </div>
            <p className="text-black mt-[300px] md:mt-[500px] leading-normal flex flex-col gap-3 text-sm md:text-base">
              <span className="flex items-start gap-1.5">
                <span>
                  <SiTicktick className="mt-1 text-[#38BDF8]" />
                </span>
                Earn recurring commissions by helping us create customized
                investment solutions to perfectly fit your client's portfolio
              </span>
              <span className="flex items-start gap-1.5">
                <span>
                  <SiTicktick className="mt-1 text-[#38BDF8]" />
                </span>
                Market and Distribute our in-house designed hybrid securities to
                your business network.
              </span>
              <span className="flex items-start gap-1.5">
                <span>
                  <SiTicktick className="mt-1 text-[#38BDF8]" />
                </span>
                Introduce your existing customer base to the next generation of
                financial assets, vastly improving the efficiency of your
                distribution channel as well as User experience.
              </span>
            </p>

            <div className="w-full flex items-center justify-center">
              <button
                onClick={() => setClose(true)}
                class="relative inline-flex items-center rounded-full overflow-hidden shadow-md border group border-none bg-white text-black mt-5 "
              >
                <span class="absolute inset-0 bg-[#38BDF8] translate-x-[-100%] transition-transform duration-300 group-hover:translate-x-0"></span>
                <div class="relative z-10 flex items-center text-xs md:text-sm font-semibold">
                  <div class="w-8 h-6 md:w-10 md:h-8 bg-[#38BDF8] flex items-center justify-center rounded-l-full">
                    <svg
                      viewBox="0 0 50 50"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                    >
                      <circle
                        opacity="0.5"
                        cx="25"
                        cy="25"
                        r="23"
                        fill="url(#icon-payments-cat_svg__paint0_linear_1141_21101)"
                      ></circle>
                      <mask id="icon-payments-cat_svg__a" fill="#fff">
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M34.42 15.93c.382-1.145-.706-2.234-1.851-1.852l-18.568 6.189c-1.186.395-1.362 2-.29 2.644l5.12 3.072a1.464 1.464 0 001.733-.167l5.394-4.854a1.464 1.464 0 011.958 2.177l-5.154 4.638a1.464 1.464 0 00-.276 1.841l3.101 5.17c.644 1.072 2.25.896 2.645-.29L34.42 15.93z"
                        ></path>
                      </mask>
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M34.42 15.93c.382-1.145-.706-2.234-1.851-1.852l-18.568 6.189c-1.186.395-1.362 2-.29 2.644l5.12 3.072a1.464 1.464 0 001.733-.167l5.394-4.854a1.464 1.464 0 011.958 2.177l-5.154 4.638a1.464 1.464 0 00-.276 1.841l3.101 5.17c.644 1.072 2.25.896 2.645-.29L34.42 15.93z"
                        fill="#fff"
                      ></path>
                      <defs>
                        <linearGradient
                          id="icon-payments-cat_svg__paint0_linear_1141_21101"
                          x1="25"
                          y1="2"
                          x2="25"
                          y2="48"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#fff" stop-opacity="0.71"></stop>
                          <stop
                            offset="1"
                            stop-color="#fff"
                            stop-opacity="0"
                          ></stop>
                        </linearGradient>
                      </defs>
                    </svg>
                  </div>
                  <span class="inline-block transition-colors duration-200 pl-2 pr-4 text-black group-hover:text-white">
                    Connect Now
                  </span>
                </div>
              </button>
            </div>
          </div>
        </div>

        {close === true && (
          <div className="demo2-container">
            <div
              className="pop-up-animation w-[300px] px-4 rounded-md shadow-[0px_0px_15px_rgba(0,0,0,0.09)] bg-white "
              ref={popupRef}
            >
              <p className="text-base text-black font-semibold select-none flex items-center justify-between py-3">
                Select Business Type
                <IoCloseCircle
                  className="text-xl text-red-600 cursor-pointer"
                  onClick={() => setClose()}
                />
              </p>
              <hr />
              <div className="flex flex-col gap-2 py-3 ">
                {options.map(({ id, label, checked, link, img }) => (
                  <a href={link} key={id}>
                    <label
                      htmlFor={id}
                      className={`font-medium py-2.5 relative hover:bg-zinc-100 flex items-center px-2 text-sm gap-2 rounded-lg ${
                        checked
                          ? "text-blue-500 bg-blue-50 ring-blue-300 ring-1"
                          : ""
                      } select-none`}
                    >
                      <img src={img} alt="" className="w-7" />
                      {label}
                    </label>
                  </a>
                ))}
              </div>
            </div>
          </div>
        )}

        <div className=""></div>

        <div className="flex flex-wrap  gap-y-[70px] container-fluid justify-between  h-max min-h-[600px] w-[90%] mx-auto mt-5  pt-3 xl:p-3 rounded-2xl  ">
          {PartnershipApi.map((curElem) => {
            const { id, heading, info, image, route } = curElem;
            return (
              <>
                <div
                  to={route}
                  className="flex flex-wrap justify-center  rounded-2xl w-full md:w-[48%] xl:w-[32%] relative group overflow-hidden "
                  onMouseEnter={() => setShow(id)}
                  onMouseLeave={() => setShow(-1)}
                >
                  <span class="absolute top-8 left-5 z-0 h-[70px] w-[70px] rounded-full bg-gradient-to-r from-cyan-100 to-sky-100 opacity-75 transition-all duration-500 transform group-hover:scale-[20]"></span>
                  <div class="border-x border-t border-b-4 border-b-[#5AB2FF] w-full h-auto rounded-2xl bg-white">
                    <div class="flex flex-col pt-9 px-6 pb-6 relative">
                      <div className="p-1 md:p-1.5 lg:p-2.5  bg-white rounded-full h-16 w-16 shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] ">
                        <img src={image} alt="" className="   object-cover  " />
                      </div>

                      <div className="mt-3 h-60 xl:h-48 group duration-300 ease-in-out transition-transform ">
                        <h5 class="text-sm xl:text-base font-bold mb-2 text-left mr-auto text-zinc-700">
                          {heading}
                        </h5>
                        <p class="w-full mb-4 text-sm text-left line-clamp-3 group-hover:line-clamp-none duration-300 ease-in-out transition-all ">
                          {info}
                        </p>

                        <a
                          href={route}
                          className=" absolute bottom-5 font-semibold cursor-pointer py-2 px-8 w-max break-keep text-sm rounded-lg  duration-200 text-white bg-[#5ab2ff] hover:bg-[#1082e6]"
                        >
                          <button type="button">Explore</button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default Partnerships;
