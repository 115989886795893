import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import "./PrimaryMarket.css";
import PopUp1 from "../../components/PopUp1/PopUp1";
import CheckAuth from "../../components/ServiceFunctions/CheckAuth";
import UserData from "../../components/ServiceFunctions/UserData";
import Loader2 from "../../components/Loaders/Loader2";
import KycPopUp from "../../components/PopUp1/PopUp2";
import { GiWallet } from "react-icons/gi";
import { MdAddCircle } from "react-icons/md";
import { IoMdClock, IoIosCloseCircle } from "react-icons/io";
import ExchangeTable from "../../components/PrimaryMarketComponents/ExchangeTable.jsx";
import OrderTable from "../../components/PrimaryMarketComponents/OrderTable.jsx";
import Header from "../../components/Header/Header";
import {
  RiExchangeLine,
  RiExchangeFundsFill,
  RiCouponLine,
} from "react-icons/ri";
import { FaClipboardList } from "react-icons/fa6";
import { PiListChecksFill } from "react-icons/pi";
import { LiaFileContractSolid } from "react-icons/lia";
import { GoKebabHorizontal } from "react-icons/go";
import { IoArrowUpCircle } from "react-icons/io5";
import ContractNotes from "../../components/PrimaryMarketComponents/ContractNotes.jsx";
import CouponStatement from "../../components/PrimaryMarketComponents/CouponStatements.jsx";
import secureLocalStorage from "react-secure-storage";
// import DatepickerComponent from "./DatepickerComponent";
import FAQPrimaryMarket from "../../pages/PrimaryMarket/FAQPrimaryMarket.jsx";

function PrimaryMarket() {
  const { isAuthenticated, email, phone, pan, cash, kycStatus } = CheckAuth();
  const location = useLocation();
  const { loader } = UserData({
    isAuthenticated,
    email,
    phone,
    pan,
    formName: "",
  });
  const { page } = useParams();
  const navigate = useNavigate();
  const storedData = secureLocalStorage.getItem("blockchainData");
  const storedData2 = secureLocalStorage.getItem("ownershipData");
  const storedData3 = secureLocalStorage.getItem("tempData");
  const ownershipData = JSON.parse(storedData2);
  const [blockedBalance, setBlockedBalance] = useState(0);
  const [tempData, setTempData] = useState([]);
  const [iconChange, setIconChange] = useState(false);

  useEffect(() => {
    setTempData(JSON.parse(storedData3));
    if (pan) {
      // Process F`
      let blocked2 = 0;
      if (storedData3) {
        tempData?.filter((data) => {
          if (
            data.Status === "Processing" ||
            data.Status === "Pending" ||
            data.Status === "Revised" ||
            data.Status === "Modified" ||
            data.Status === "Executed"
          ) {
            if (data.Buyer === pan) {
              blocked2 = blocked2 + data.Units * data["Price_per_unit"];
            }
          }
        });
      }
      setBlockedBalance(blocked2);
      // Process F`
    }
  }, [pan, storedData3, blockedBalance]);

  const getIconForTitle = (title) => {
    switch (title) {
      case "WALR Exchange":
        return <RiExchangeLine className="icon-size" />;
      case "RESIDEX Exchange":
        return <RiExchangeFundsFill className="icon-size" />;
      case "Open Orders":
        return <FaClipboardList className="icon-size" />;
      case "Completed Orders":
        return <PiListChecksFill className="icon-size" />;
      case "Contract Notes":
        return <LiaFileContractSolid className="icon-size" />;
      case "Coupon Statement":
        return <RiCouponLine className="icon-size" />;
      default:
        return null;
    }
  };

  // API
  const KYCFormApi = [
    {
      Id: 1,
      title: "Auction",
      name: "WALR Exchange",
      link: "walr",
    },
    {
      Id: 2,
      title: "Auction",
      name: "RESIDEX Exchange",
      link: "residex",
    },
    {
      Id: 3,
      title: "My Orders",
      name: "Open Orders",
      link: "open",
    },
    {
      Id: 4,
      title: "My Orders",
      name: "Completed Orders",
      link: "completed",
    },
    {
      Id: 5,
      title: "Financials",
      name: "Contract Notes",
      link: "contract",
    },
    {
      Id: 6,
      title: "Financials",
      name: "Coupon Statement",
      link: "coupon",
    },
  ];
  // API

  useEffect(() => {
    if (location.state && location.state.selectedCard) {
      const selected = KYCFormApi.find(
        (card) => card.name === location.state.selectedCard
      );
      if (selected) {
        setSelectedCard(selected);
      }
    }
  }, [location.state]);

  const [selectedCard, setSelectedCard] = useState(KYCFormApi[0]);

  const handleSelectCard = (card) => {
    setSelectedCard(card);
    setIconChange(false);
  };

  const groupedButtons = KYCFormApi.reduce((acc, card) => {
    if (!acc[card.title]) {
      acc[card.title] = [];
    }
    acc[card.title].push(card);
    return acc;
  }, {});

  return (
    <div>
      {loader ? (
        <Loader2 />
      ) : (
        <>
          <Header />
          {!isAuthenticated ? (
            <PopUp1 />
          ) : kycStatus !== 85 ? (
            <KycPopUp />
          ) : (
            <>
              <div className="m-0 main h-full after-header max-w-[1800px] mx-auto">
                <div className="lg:flex justify-start w-[90%] mx-auto">
                  <div className="py-2 d-flex flex-col gap-2 w-full lg:w-auto">
                    {/* Add Money */}
                    <div
                      className="px-3 rounded-3 flex flex-wrap justify-between items-center py-2 w-full"
                      style={{ backgroundColor: "#d8e5f9" }}
                    >
                      <span className="fs-6 fw-bold text-sm text-primary flex gap-1">
                        <GiWallet size={20} className="text-primary" /> ₹
                        {cash === null
                          ? 0
                          : parseInt(cash - blockedBalance).toLocaleString(
                              "en-IN"
                            )}
                      </span>
                      <a href="/moneyWallet" className="py-1">
                        <span className="font-size2 lg:text-[10px] btn py-1.5 px-2 btn-primary btn-sm btn-hover d-flex align-items-center w-fit button-media">
                          <MdAddCircle size={12} className="text-light" />
                          &nbsp;Add
                        </span>
                      </a>
                    </div>

                    {/* Bid Timing */}
                    <div
                      className="px-3 p-1 d-flex align-items-center rounded-3 border-primary justify-content-between"
                      style={{ backgroundColor: "#d8e5f9" }}
                    >
                      <span className="text-xs  text-primary d-flex align-items-center gap-2 py-1">
                        <IoMdClock size={20} className="text-primary" />
                        <span className="font-semibold">
                          <span>Bid-Timings:</span>
                          <br />
                          <span>10:30AM - 06:30PM</span>
                        </span>
                      </span>
                    </div>

                    {/* Side Bar */}
                    <div className=" align-left border border-[#D8E5F9] rounded-xl px-2.5  hidden-768 pb-2 z-0">
                      <div className="custodian-buttons">
                        {Object.entries(groupedButtons).map(
                          ([title, cards], index) => (
                            <div
                              key={index}
                              className="flex flex-col gap-[7px]"
                            >
                              <div
                                key={index}
                                className="side-sub-title flex justify-center"
                              ></div>
                              {cards.map((card) => (
                                <div
                                  key={index}
                                  onClick={() =>
                                    navigate(`/PrimaryMarket/${card.link}`)
                                  }
                                >
                                  <button
                                    key={index}
                                    className={`custodian-button bg-gray-100 border flex gap-2 items-center py-2.5 2xl:py-2.5 px-3 ${
                                      page === card.link ? "cust-active" : ""
                                    }`}
                                    onClick={() =>
                                      navigate(`/PrimaryMarket/${card.link}`)
                                    }
                                  >
                                    {getIconForTitle(card.name)}&nbsp;
                                    {card.name}
                                  </button>
                                </div>
                              ))}
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="lg:w-[82%]">
                    {" "}
                    {page === "walr" ? (
                      <ExchangeTable
                        head={"WALR Exchange"}
                        tabHead1={"Maturity Date"}
                        tabHead2={"Coupon Post Reset (%)"}
                        tabHead3={"Option Price (₹)"}
                        tabHead4={"Coupon at Issue (%)"}
                      />
                    ) : page === "residex" ? (
                      <ExchangeTable
                        head={"RESIDEX Exchange"}
                        tabHead1={"Holding Period"}
                        tabHead2={"CAGR from Inception"}
                        tabHead3={"3 mth Premium"}
                        tabHead4={"Price"}
                      />
                    ) : page === "open" ? (
                      <OrderTable
                        head={"Open Orders"}
                        tabHead1={""}
                        tabHead2={"Status"}
                      />
                    ) : page === "completed" ? (
                      <OrderTable
                        head={"Completed Orders"}
                        tabHead1={"Order Id"}
                        tabHead2={""}
                      />
                    ) : page === "contract" ? (
                      <ContractNotes head={"Contract Notes"} />
                    ) : (
                      page === "coupon" && (
                        <CouponStatement head={"Coupon Statement"} />
                      )
                    )}
                  </div>
                </div>
              </div>

              <div className="fixed bottom-0 bg-[#3B71CA] p-2 md:px-20 w-full inline-flex text-[10px] justify-between items-end z-10 font-bold lg:hidden">
                {Object.entries(groupedButtons).map(([title, cards], index) => (
                  <>
                    {title !== "My Orders" ? (
                      <>
                        {cards.map((card) => (
                          <>
                            <div
                              key={index}
                              className="flex flex-col justify-center  items-center w-[60px] h-[60px] gap-1 text-[#c2dbff] hover:text-white"
                              onClick={() =>
                                navigate(`/PrimaryMarket/${card.link}`)
                              }
                            >
                              <span
                                className={`bg-[#3b71ca] rounded-full h-fit flex flex-col items-center p-1.5 ${
                                  page === card.link && iconChange === false
                                    ? "nav-active text-white "
                                    : "top-0 pb-2.5"
                                }`}
                              >
                                <span className="text-[10px] p-1 rounded-full top-0 text-center ">
                                  {getIconForTitle(card.name)}
                                </span>
                                <p className="text-[10px] text-center leading-3">
                                  {card.name.split(" ")[0]} <br />
                                  {card.name.split(" ")[1]}
                                </p>
                              </span>
                            </div>
                          </>
                        ))}
                      </>
                    ) : (
                      <>
                        <>
                          <div
                            className="flex flex-col justify-center items-center text-black w-[60px] h-[60px] bg-[#D8E5F9] rounded-2xl border-[#386BC0] border-2 duration-200"
                            onClick={() => {
                              // handleSelectCard("Open");
                              setIconChange(!iconChange);
                            }}
                          >
                            {!iconChange ? (
                              <GoKebabHorizontal className="font-bold text-lg" />
                            ) : (
                              <IoArrowUpCircle className="font-bold text-lg" />
                            )}
                            {/* <GoKebabHorizontal className="font-bold text-xl" /> */}
                            <p className="text-center text-[10px]">Orders</p>
                          </div>
                          {/*  */}
                        </>
                      </>
                    )}
                  </>
                ))}
              </div>

              {iconChange && (
                <div className="demo3-container">
                  <div
                    className={`min-h-[230px] min-w-[250px] p-4 flex flex-col rounded-xl border border-[#3B71CA] bg-white shadow ${
                      !iconChange ? "" : "animate-scaleUp"
                    }`}
                  >
                    {Object.entries(groupedButtons).map(
                      ([title, cards], index) => (
                        <>
                          {title === "My Orders" && (
                            <>
                              <div
                                key={index}
                                className="font-bold flex justify-between items-center"
                              >
                                <p className="">My Orders</p>
                                <IoIosCloseCircle
                                  onClick={() => setIconChange(!iconChange)}
                                />
                              </div>
                              <hr className="mt-2" />
                              <div className="flex text-xs gap-5 font-bold mt-4">
                                {cards.map((card) => (
                                  <div
                                    className="flex justify-center flex-col items-center"
                                    onClick={() =>{
                                      navigate(`/PrimaryMarket/${card.link}`);
                                      setIconChange(false);
                                    }}
                                  >
                                    <i>{getIconForTitle(card.name)}</i>
                                    <p className="text-center">
                                      {card.name.split(" ")[0]} <br />
                                      {card.name.split(" ")[1]}
                                    </p>
                                  </div>
                                ))}
                              </div>
                            </>
                          )}
                        </>
                      )
                    )}
                  </div>
                </div>
              )}
              <div className="max-w-[1800px] mx-auto">
                <FAQPrimaryMarket />
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default PrimaryMarket;
