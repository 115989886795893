import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { IoEyeSharp } from "react-icons/io5";
import { HiMiniEyeSlash } from "react-icons/hi2";
import { BiSkipNext, BiSkipPrevious } from "react-icons/bi";
import { BsFilterLeft } from "react-icons/bs";
import { HiCurrencyRupee } from "react-icons/hi";
import { IoClose } from "react-icons/io5";
import { IoMdCloseCircle } from "react-icons/io";
import "./MoneyWallet.css";
import CheckAuth from "../../components/ServiceFunctions/CheckAuth";
import UserData from "../../components/ServiceFunctions/UserData";
import Loader2 from "../../components/Loaders/Loader2";
import KycPopUp from "../../components/PopUp1/PopUp2";
import PopUp1 from "../../components/PopUp1/PopUp1";
import Header from "../../components/Header/Header";
import secureLocalStorage from "react-secure-storage";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import AlertYellow from "../../components/Alerts/AlertYellow.jsx"
import AlertRed from "../../components/Alerts/AlertRed.jsx"

function MoneyWallet() {
  const { isAuthenticated, email, phone, cash, pan, kycStatus } = CheckAuth();
  const { loader } = UserData({
    isAuthenticated,
    email,
    phone,
    formName: "",
  });
  const [transactions, setTransactions] = useState([]);
  const [loader2, setLoader2] = useState(false);
  const [selectedTransactionType, setSelectedTransactionType] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [topUpAmount, setTopUpAmount] = useState("");
  const [currentBalance, setCurrentBalance] = useState(cash);
  const [withdrawAmount, setWithdrawAmount] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const storedData = secureLocalStorage.getItem("blockchainData");
  const storedData2 = secureLocalStorage.getItem("ownershipData");
  const storedData3 = secureLocalStorage.getItem("tempData");
  const tempData = JSON.parse(storedData3);
  const [dataFetched, setDataFetched] = useState(false);
  const [addMoneyPopUp, setAddMoneyPopUp] = useState(false);
  const [withdrawPopUp, setWithdrawPopUp] = useState(false);
  const balance = parseInt(cash);
  const popupRef = useRef(null);
  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [showModal3, setShowModal3] = useState(false);
  const [showModal4, setShowModal4] = useState(false);
  const [showModal5, setShowModal5] = useState(false);

  // process F
  var Blocked = 0;
  // process F

  useEffect(() => {
    if (isAuthenticated) {
      const fetchData = async () => {
        if (
          !dataFetched &&
          (storedData === null || storedData2 === null || storedData3 === null)
        ) {
          try {
            const response = await fetch(
              `https://api-scdndestates.com/api/completed-order/`,
              {
                method: "POST",
                body: JSON.stringify({
                  pan,
                }),
                headers: {
                  "Content-type": "application/json; charset=UTF-8",
                },
              }
            );

            if (!response.ok) {
              throw new Error("Failed to fetch data");
            }

            const data = await response.json();
            const sortedorders = data.data;

            secureLocalStorage.setItem(
              "blockchainData",
              JSON.stringify(sortedorders)
            );
            secureLocalStorage.setItem(
              "ownershipData",
              JSON.stringify(data.data2)
            );
            secureLocalStorage.setItem("tempData", JSON.stringify(data.data3));

            setDataFetched(true);
          } catch (error) {
            // console.error("Error fetching data:", error);
          }
        }
      };

      fetchData();
    }
  }, [dataFetched, pan, storedData, storedData2, storedData3, isAuthenticated]);

  useEffect(() => {
    // Process O
    const blockchainData = JSON.parse(storedData);
    const ModifiedBlockchainData =
      blockchainData !== null &&
      blockchainData.map((transaction) => {
        let pricePerUnit = transaction["Price_per_unit"];
        if (transaction.Buyer === pan) {
          pricePerUnit = -Math.abs(transaction["Price_per_unit"]);
        } else {
          pricePerUnit = Math.abs(transaction["Price_per_unit"]);
        }
        return { ...transaction, Price_per_unit: pricePerUnit };
      });
    // Process O

    const mergeData = (data) => {
      const merged = {};
      if (Array.isArray(data)) {
        data.forEach((entry) => {
          let orderIdPrefix = entry["Order_id"]?.substring(0, 3);
          if (entry["Security"] === "Failure") {
            orderIdPrefix = "FAIL";
          } else {
            if (
              orderIdPrefix !== "PMY" &&
              orderIdPrefix !== "P2P" &&
              orderIdPrefix !== "FEE"
            ) {
              orderIdPrefix = "BANK";
            }
          }
          const date = new Date(entry.Timestamp).toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "short",
            year: "numeric",
          });
          const key = `${date}_${orderIdPrefix}`;
          if (!merged[key]) {
            merged[key] = {
              Date: date,
              Mode: orderIdPrefix,
              Action: entry["Units"] * entry["Price_per_unit"],
              Balance:
                currentBalance !== undefined
                  ? currentBalance + entry["Units"] * entry["Price_per_unit"]
                  : 0 + entry["Units"] * entry["Price_per_unit"],
            };
          } else {
            merged[key].Action += entry["Units"] * entry["Price_per_unit"];
          }
        });
        return Object.values(merged);
      } else {
        return [];
      }
    };

    let result = mergeData(ModifiedBlockchainData);

    function parseDate(dateStr) {
      // Handle cases like "29 Sept 2020" (missing day padding and inconsistent month formats)
      return new Date(dateStr.replace(/(\d+)\s(\w+)\s(\d+)/, "$1 $2 $3"));
    }

    const modeOrder = ["FEE", "FAIL", "P2P", "PMY", "BANK"];
    let runningBalance = cash;
    let ModifiedmergedData = result
      .sort((a, b) => {
        // First sort by Date in descending order
        const dateA = parseDate(a.Date);
        const dateB = parseDate(b.Date);

        if (dateA > dateB) return -1;
        if (dateA < dateB) return 1;

        // If dates are the same, sort by Mode based on the specified order
        return modeOrder.indexOf(a.Mode) - modeOrder.indexOf(b.Mode);
      })
      .map((transaction, index) => {
        runningBalance =
          result?.[index - 1]?.["Mode"] === "FAIL" || index === 0
            ? runningBalance
            : runningBalance - result?.[index - 1]?.["Action"];
        return { ...transaction, Balance: Math.abs(runningBalance) };
      });

    const filter = ModifiedmergedData.filter((data) => {
      if (selectedTransactionType === "Primary") {
        return data.Mode === "PMY";
      } else if (selectedTransactionType === "Peer-2-Peer") {
        return data.Mode === "P2P";
      } else if (selectedTransactionType === "Bank") {
        return !["PMY", "P2P", "FEE"].includes(data.Mode);
      } else {
        return true;
      }
    });

    setTransactions(filter);
  }, [selectedTransactionType, storedData, pan]);
  // , transactions

  const startIndex = (currentPage - 1) * 10;
  const endIndex = startIndex + 10;
  const totalPages = Math.ceil(transactions.length / 10);
  const currentTransactions = transactions
    // .sort((a, b) => new Date(b.Date) - new Date(a.Date))
    .slice(startIndex, endIndex);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const navigateToPaymentPage = () => {
    const paymentData = { topUpAmount, balance };
    if (topUpAmount >= 10000) {
      if (topUpAmount % 100 !== 0) {
        setShowModal1(true);
        setShowModal2(false);
        setShowModal3(false);
        setShowModal4(false);
        setShowModal5(false);
        setTimeout(() => {
          setShowModal1(false);
        }, 5000);
      } else {
        navigate("/paymentPage", { state: { paymentData } });
      }
    } else {
      setTopUpAmount(0);
      setShowModal1(false);
        setShowModal2(true);
        setShowModal3(false);
        setShowModal4(false);
        setShowModal5(false);
        setTimeout(() => {
          setShowModal2(false);
        }, 5000);
    }
  };

  const navigateToWithdrawPage = (isAuthenticated) => {
    if (!isAuthenticated) {
      navigate("/userLost");
      return; // Prevent further execution if not authenticated
    }
    if (isAuthenticated) {
      //Process F
      fetch(`https://api-scdndestates.com/api/get-blocked-balance/`, {
        method: "POST",
        body: JSON.stringify({
          pan,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to fetch data");
          }
          return response.json();
        })
        .then((data) => {
          secureLocalStorage.setItem(
            "Blocked Balance",
            JSON.stringify(data.data)
          );
          Blocked = data.data;
        })
        .catch((error) => {
          return null;
        });
      //Process F
      if (withdrawAmount <= balance - Blocked) {
        setLoader2(true);
        fetch(`https://api-scdndestates.com/api/withdraw-otp/`, {
          method: "POST",
          body: JSON.stringify({
            phone: phone,
            amount: withdrawAmount,
          }),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error("Failed to send otp");
            }
            return response.json();
          })
          .then((response) => {
            setLoader2(false);
            const otp = response.otp;
            const withdrawData = { withdrawAmount, balance, otp };
            navigate("/withdrawPage", { state: { withdrawData } });
            var dataObject = JSON.parse(response.msg91_response);
            var dataArray = [dataObject];
          })
          .catch((error) => {
            return null;
          });
      } else {
        setWithdrawAmount(0);
     
        setShowModal1(false);
        setShowModal2(false);
        setShowModal3(true);
        setShowModal4(false);
        setShowModal5(false);
        setTimeout(() => {
          setShowModal3(false);
        }, 5000);
      }
    }
  };

  const navigateToStatementPage = () => {
    const StatementData = { fromDate, toDate };
    navigate("/statementPage", { state: { StatementData } });
  };

  const [activeBox, setActiveBox] = useState("a");
  var filteredAuctionWALR = [];
  var filteredAuctionRESIDEX = [];
  var WALRAuction = 0;
  var RESIDEXAuction = 0;
  var filteredP2PWALR = [];
  var filteredP2PRESIDEX = [];
  var WALRP2P = 0;
  var RESIDEXP2P = 0;

  // Process F`
  if (tempData !== null) {
    const filteredBlockedBalance = tempData?.filter(
      (row) =>
        row.Buyer === pan &&
        (row.Status === "Processing" ||
          row.Status === "Revised" ||
          row.Status === "Pending" ||
          row.Status === "Modified" ||
          row.Status === "Executed")
    );
    filteredBlockedBalance?.map((data) => {
      Blocked = Blocked + data["Price_per_unit"] * data["Units"];
      return Blocked;
    });
    // Process F`

    filteredAuctionWALR = tempData.filter(
      (row) =>
        row.Buyer === pan &&
        row["Security"].slice(0, 4) === "WALR" &&
        (row.Status === "Processing" || row.Status === "Revised")
    );

    filteredAuctionWALR.map((data) => {
      WALRAuction = WALRAuction + data["Price_per_unit"] * data["Units"];
      return WALRAuction;
    });
    filteredAuctionRESIDEX = tempData.filter(
      (row) =>
        row.Buyer === pan &&
        row["Security"].slice(0, 7) === "RESIDEX" &&
        (row.Status === "Processing" || row.Status === "Revised")
    );

    filteredAuctionRESIDEX.map((data) => {
      RESIDEXAuction = RESIDEXAuction + data["Price_per_unit"] * data["Units"];
      return RESIDEXAuction;
    });

    filteredP2PWALR = tempData.filter(
      (row) =>
        row.Buyer === pan &&
        row["Security"].slice(0, 4) === "WALR" &&
        (row.Status === "Pending" ||
          row.Status === "Modified" ||
          row.Status === "Executed")
    );

    filteredP2PWALR.map((data) => {
      WALRP2P = WALRP2P + data["Price_per_unit"] * data["Units"];
      return WALRP2P;
    });

    filteredP2PRESIDEX = tempData.filter(
      (row) =>
        row.Buyer === pan &&
        row["Security"].slice(0, 7) === "RESIDEX" &&
        (row.Status === "Pending" ||
          row.Status === "Modified" ||
          row.Status === "Executed")
    );

    filteredP2PRESIDEX.map((data) => {
      RESIDEXP2P = RESIDEXP2P + data["Price_per_unit"] * data["Units"];
      return RESIDEXP2P;
    });
  }

  const handleBox = (val) => {
    if (val === activeBox) setActiveBox(null);
    else if (val === "a") {
      setActiveBox("a");
    } else if (val === "b") {
      setActiveBox("b");
    } else if (val === "c") {
      setActiveBox("c");
    } else {
      setActiveBox("d");
    }
  };

  // Toggel
  const [order, setOrder] = useState(0);

  const handleOrders = (val) => {
    if (val === order) {
      setOrder(-1);
    } else {
      setOrder(val);
    }
  };

  return (
    <>
    <div>
      {loader || loader2 ? (
        <Loader2 />
      ) : (
        <>
          <Header />
          {!isAuthenticated ? (
            <PopUp1 />
          ) : kycStatus !== 85 ? (
            <KycPopUp />
          ) : (
            <>
              <div className="py-24 w-full max-w-[1800px] mx-auto">
                <div className="flex flex-wrap lg:flex-nowrap justify-between w-[90%] mx-auto gap-2">
                  {/* Left Section */}
                  <div className="flex flex-col mx-auto rounded-md w-full lg:w-[450px] xl:w-fit ">
                    {/* Cash, Add Money, Withdraw */}
                    <div className="flex flex-wrap justify-between gap-1 w-full">
                      {/* Cash */}
                      <div className="bg-[#3B71CA] border-2 border-[#3B71CA] text-center px-6  py-2 rounded-md w-full  xl:w-[32%]">
                        <div>
                          <p className="font-semibold text-white text-base mt-1">
                            Cash
                          </p>
                          <div className="mt-1">
                            <div className="flex items-center font-semibold gap-1 justify-center">
                              <HiCurrencyRupee size={25} color="orange" />
                              <span className="text-lg text-light">
                                {balance.toLocaleString("en-IN")}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Add Money */}
                      <div className="border-2 border-[#3B71CA] px-6  py-2 rounded-md w-full md:w-[45%] xl:w-[32%]">
                        <div className="dropdown py-2">
                          <div
                            className="text-primary cursor-pointer"
                            onClick={() => setAddMoneyPopUp(!addMoneyPopUp)}
                          >
                            <p className="text-base text-center font-semibold">
                              Add Money
                            </p>
                            <div className="flex justify-center -mb-2.5 mt-2.5">
                              {/* Image and Toggle Button */}
                              <div className="flex items-center gap-2 ">
                                {/* Image */}
                                <span className="text-primary fw-bolder fs-5">
                                  <img
                                    src="/images/add-money.svg"
                                    alt=""
                                    className="w-8"
                                    color="white"
                                  />
                                </span>
                                <FaCaretDown className="text-[20px]" />
                              </div>
                            </div>
                          </div>

                          {/* Walr Popup */}
                          {addMoneyPopUp && (
                            <div className="demo10-container text-black p-2">
                              <div
                                className="rounded-2xl min-w-[300px] min-h-[200px] bg-white mx-auto my-4 pop-up-animation"
                                ref={popupRef}
                              >
                                <h2 className="text-lg font-bold px-3 mt-4 mb-2.5 rounded-t-2xl text-gray-600 flex items-center justify-between">
                                  Add Money
                                  <IoMdCloseCircle
                                    className="cursor-pointer"
                                    onClick={() =>
                                      setAddMoneyPopUp(!addMoneyPopUp)
                                    }
                                  />
                                </h2>
                                <div className="px-3">
                                  <div className="px-3 py-4 gap-4 bg-[#F2F8FF] rounded-lg shadow-sm ">
                                    <p className="text-sm font-semibold text-black">
                                      Enter Amount in Multiples of 100 Below
                                    </p>
                                    <span className="flex justify-between items-center border my-2 bg-white rounded-md pl-2">
                                      <input
                                        type="text"
                                        value={topUpAmount.toLocaleString(
                                          "en-IN"
                                        )}
                                        onChange={(e) => {
                                          const valueWithoutNonNumeric =
                                            e.target.value.replace(
                                              /[^\d]/g,
                                              ""
                                            );
                                          const parsedValue = parseInt(
                                            valueWithoutNonNumeric,
                                            10
                                          );
                                          if (
                                            !isNaN(parsedValue) &&
                                            parsedValue !== undefined
                                          ) {
                                            setTopUpAmount(parsedValue);
                                          } else {
                                            setTopUpAmount(0);
                                          }
                                        }}
                                        placeholder="Enter amount"
                                        className="placeholder:px-2 py-0.5 outline-none w-full"
                                      />
                                      <span className="px-2 flex justify-center items-center cursor-pointer">
                                        <IoClose
                                          onClick={() => setTopUpAmount(0)}
                                        />
                                      </span>
                                    </span>
                                    {topUpAmount >= 10000 ? (
                                      <></>
                                    ) : (
                                      <p className="text-xs text-red-500">
                                        Minimum amount for Wallet Top-up is Rs.
                                        10,000.*
                                      </p>
                                    )}
                                    <p className="font-semibold text-sm mt-3">
                                      Or Select From Below
                                    </p>
                                    <div className="mt-2 flex flex-wrap gap-1.5">
                                      <div
                                        className="border-[0.8px] border-[#3B71CA] rounded-full px-3 py-0.5 text-xs flex items-center hover:bg-[#DBEDF9] hover:font-semibold duration-200 cursor-pointer"
                                        onClick={() => setTopUpAmount(10000)}
                                      >
                                        ₹10,000
                                      </div>
                                      <div
                                        className="border-[0.8px] border-[#3B71CA] rounded-full px-3 py-0.5 text-xs flex items-center hover:bg-[#DBEDF9] hover:font-semibold duration-200 cursor-pointer"
                                        onClick={() => setTopUpAmount(25000)}
                                      >
                                        ₹25,000
                                      </div>
                                      <div
                                        className="border-[0.8px] border-[#3B71CA] rounded-full px-3 py-0.5 text-xs flex items-center hover:bg-[#DBEDF9] hover:font-semibold duration-200 cursor-pointer"
                                        onClick={() => setTopUpAmount(50000)}
                                      >
                                        ₹50,000
                                      </div>
                                      <div
                                        className="border-[0.8px] border-[#3B71CA] rounded-full px-3 py-0.5 text-xs flex items-center hover:bg-[#DBEDF9] hover:font-semibold duration-200 cursor-pointer"
                                        onClick={() => setTopUpAmount(100000)}
                                      >
                                        ₹1,00,000
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="flex px-3 py-4">
                                  <div className=" mx-auto">
                                    <button
                                      className=" duration-200 shadow-sm px-5 bg-[#3B71CA] py-1.5 rounded-md text-sm text-white font-semibold w-full sm:w-auto"
                                      onClick={() => navigateToPaymentPage()}
                                    >
                                      Submit
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      {/* Withdraw */}
                      <div className="border-2 relative border-[#3B71CA] px-6  py-2 rounded-md w-full md:w-[45%] xl:w-[32%]">
                        <div className="w-full ">
                          <div
                            className="text-primary cursor-pointer flex items-center flex-col"
                            onClick={() => setWithdrawPopUp(!withdrawPopUp)}
                          >
                            <p className="text-base font-semibold mt-2">
                              Withdraw
                            </p>
                            <div className="flex items-center gap-2">
                              <span className="text-primary fw-bolder fs-5">
                                <img
                                  src="/images/withdraw.svg"
                                  alt="scdnd estates"
                                  className="h-7 mt-2"
                                  color="white"
                                />
                              </span>
                              <FaCaretDown className="text-[20px] mt-3" />
                            </div>
                          </div>
                          {/* withdrawPopUp */}
                          {withdrawPopUp && (
                            <div className="demo10-container text-black p-2">
                              <div
                                className="rounded-2xl min-w-[300px] min-h-[200px] bg-white mx-auto my-4 pop-up-animation"
                                ref={popupRef}
                              >
                                <h2 className="text-lg font-bold px-3 mt-4 mb-2.5 rounded-t-2xl text-gray-600 flex items-center justify-between">
                                  Withdraw Money
                                  <IoMdCloseCircle
                                    className="cursor-pointer"
                                    onClick={() =>
                                      setWithdrawPopUp(!withdrawPopUp)
                                    }
                                  />
                                </h2>
                                <div className="px-3">
                                  <div className="px-3 py-4 gap-4 bg-[#F2F8FF] rounded-lg shadow-sm">
                                    <p className="text-sm font-semibold text-black">
                                      Enter Amount in Multiples of 100 Below
                                    </p>
                                    <span className="flex justify-between items-center border my-2 bg-white rounded-md pl-2">
                                      <input
                                        type="text"
                                        value={withdrawAmount.toLocaleString(
                                          "en-IN"
                                        )}
                                        onChange={(e) => {
                                          const valueWithoutNonNumeric =
                                            e.target.value.replace(
                                              /[^\d]/g,
                                              ""
                                            );
                                          const parsedValue = parseInt(
                                            valueWithoutNonNumeric,
                                            10
                                          );
                                          if (
                                            !isNaN(parsedValue) &&
                                            parsedValue !== undefined
                                          ) {
                                            setWithdrawAmount(parsedValue);
                                          } else {
                                            setWithdrawAmount(0);
                                          }
                                        }}
                                        placeholder="Enter amount"
                                        className="placeholder:px-2 py-0.5 outline-none w-full"
                                      />
                                      <span className="px-2 flex justify-center items-center cursor-pointer">
                                        <IoClose
                                          onClick={() => setWithdrawAmount(0)}
                                        />
                                      </span>
                                    </span>
                                    {withdrawAmount > balance ? (
                                      <p className="text-xs text-red-500">
                                        Insufficient Balance in Wallet*
                                      </p>
                                    ) : (
                                      <></>
                                    )}
                                    <p className="font-semibold text-sm mt-3">
                                      Or Select From Below
                                    </p>
                                    <div className="mt-2 flex flex-wrap gap-1.5">
                                      <div
                                        className="border-[0.8px] border-[#3B71CA] rounded-full px-3 py-0.5 text-xs flex items-center hover:bg-[#DBEDF9] hover:font-semibold duration-200 cursor-pointer"
                                        onClick={() => setWithdrawAmount(10000)}
                                      >
                                        ₹10,000
                                      </div>
                                      <div
                                        className="border-[0.8px] border-[#3B71CA] rounded-full px-3 py-0.5 text-xs flex items-center hover:bg-[#DBEDF9] hover:font-semibold duration-200 cursor-pointer"
                                        onClick={() => setWithdrawAmount(25000)}
                                      >
                                        ₹25,000
                                      </div>
                                      <div
                                        className="border-[0.8px] border-[#3B71CA] rounded-full px-3 py-0.5 text-xs flex items-center hover:bg-[#DBEDF9] hover:font-semibold duration-200 cursor-pointer"
                                        onClick={() => setWithdrawAmount(50000)}
                                      >
                                        ₹50,000
                                      </div>
                                      <div
                                        className="border-[0.8px] border-[#3B71CA] rounded-full px-3 py-0.5 text-xs flex items-center hover:bg-[#DBEDF9] hover:font-semibold duration-200 cursor-pointer"
                                        onClick={() =>
                                          setWithdrawAmount(100000)
                                        }
                                      >
                                        ₹1,00,000
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="flex px-3 py-4">
                                  <div className=" mx-auto">
                                    <button
                                      className=" duration-200 shadow-sm px-5 bg-[#3B71CA] py-1.5 rounded-md text-sm text-white font-semibold w-full sm:w-auto"
                                      onClick={() => {
                                        if (withdrawAmount <= 0) {
                                        
                                          setShowModal1(false);
        setShowModal2(false);
        setShowModal3(false);
        setShowModal4(true);
        setShowModal5(false);
        setTimeout(() => {
          setShowModal4(false);
        }, 5000);
                                        } else if (withdrawAmount % 100 !== 0) {
                                          
                                          setShowModal1(false);
        setShowModal2(false);
        setShowModal3(false);
        setShowModal4(false);
        setShowModal5(true);
        setTimeout(() => {
          setShowModal5(false);
        }, 5000);
                                        } else {
                                          navigateToWithdrawPage(
                                            isAuthenticated
                                          );
                                        }
                                      }}
                                    >
                                      Submit
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    {/* Blocked Auctions */}
                    <div className="flex flex-wrap mt-14 justify-between gap-2">
                      <div className="xl:max-w-[240px] w-full">
                        <div className="bg-indigo-100 px-6 py-4 rounded-lg">
                          <h5 className="text-sm xl:text-base font-bold">
                            Blocked for Auctions
                          </h5>
                          <hr className="m-0 p-0 my-3 mt-0" />
                          <div className="row">
                            <div className="flex items-center justify-between mb-4 mt-2">
                              <div className="flex items-center justify-between">
                                <span className="font-semibold text-sm">
                                  WALR : &nbsp; ₹
                                </span>
                                &nbsp;
                                <span className="font-semibold text-sm">
                                  {WALRAuction.length !== 0
                                    ? WALRAuction.toLocaleString("en-IN")
                                    : 0}
                                </span>
                              </div>
                              <div className="">
                                {activeBox === "a" ? (
                                  <IoEyeSharp
                                    size={18}
                                    color="#3b71ca"
                                    className="eye-btn-style"
                                    onClick={() => handleBox("a")}
                                  />
                                ) : (
                                  <HiMiniEyeSlash
                                    size={18}
                                    className="eye-btn-style"
                                    onClick={() => handleBox("a")}
                                  />
                                )}
                              </div>
                            </div>

                            <div className="flex items-center justify-between font-semibold text-sm mb-4 mt-2">
                              <div className="flex-1">
                                RESIDEX : &nbsp;₹ &nbsp;
                                <span className="font-semibold text-sm">
                                  {RESIDEXAuction.length !== 0
                                    ? RESIDEXAuction.toLocaleString("en-IN")
                                    : 0}
                                </span>
                              </div>
                              <div className="flex-none">
                                {activeBox === "b" ? (
                                  <IoEyeSharp
                                    size={18}
                                    color="#3b71ca"
                                    className="eye-btn-style"
                                    onClick={() => handleBox("b")}
                                  />
                                ) : (
                                  <HiMiniEyeSlash
                                    size={18}
                                    className="eye-btn-style"
                                    onClick={() => handleBox("b")}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Blocked Peer 2 peer */}
                      <div className="xl:max-w-[240px] w-full  ">
                        <div className="bg-[#dbedf9] h-full px-6 py-4 rounded-lg">
                          <h5 className="text-sm xl:text-base font-bold underline-offset-2">
                            Blocked for Peer-2-Peer
                          </h5>
                          <hr className="m-0 p-0 my-3 mt-0" />
                          <div className="row mt-3">
                            <div className="flex items-center justify-between mb-4 mt-2">
                              <div className="flex-1">
                                <span className="font-semibold text-sm">
                                  WALR : &nbsp; ₹
                                </span>
                                &nbsp;
                                <span className="font-semibold text-sm">
                                  {WALRP2P.length !== 0
                                    ? WALRP2P.toLocaleString("en-IN")
                                    : 0}
                                </span>
                              </div>
                              <div className="flex-none">
                                {activeBox === "c" ? (
                                  <IoEyeSharp
                                    size={18}
                                    color="#3b71ca"
                                    className="eye-btn-style"
                                    onClick={() => handleBox("c")}
                                  />
                                ) : (
                                  <HiMiniEyeSlash
                                    size={18}
                                    className="eye-btn-style"
                                    onClick={() => handleBox("c")}
                                  />
                                )}
                              </div>
                            </div>
                            <div className="flex items-center justify-between font-semibold text-sm mb-4 mt-2">
                              <div className="flex-1">
                                RESIDEX : &nbsp;₹ &nbsp;
                                <span className="font-semibold text-sm">
                                  {RESIDEXP2P.length !== 0
                                    ? RESIDEXP2P.toLocaleString("en-IN")
                                    : 0}
                                </span>
                              </div>
                              <div className="flex-none">
                                {activeBox === "d" ? (
                                  <IoEyeSharp
                                    size={18}
                                    color="#3b71ca"
                                    className="eye-btn-style"
                                    onClick={() => handleBox("d")}
                                  />
                                ) : (
                                  <HiMiniEyeSlash
                                    size={18}
                                    className="eye-btn-style"
                                    onClick={() => handleBox("d")}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Order Id Section */}
                    {/* Order ID */}
                    {activeBox === "a" ? (
                      <>
                        <div className="mt-5 rounded border p-3 relative h-[350px]">
                          {/* Heading of Security */}
                          <p
                            className="bg-indigo-100
                            text-base font-bold px-5 py-1.5 rounded-md w-fit absolute -top-5"
                          >
                            WALR
                          </p>
                          {/* Order ID */}
                          <div className="mt-4 overflow-y-scroll gap-1.5 flex flex-col h-52 text-[15px] text-black">
                            {Array.isArray(filteredAuctionWALR) &&
                            filteredAuctionWALR.length > 0 ? (
                              filteredAuctionWALR.map((data, index) => (
                                <div
                                  key={index}
                                  className="flex flex-col mt-1 px-3 py-1"
                                >
                                  <div
                                    onClick={() => handleOrders(index)}
                                    className="flex bg-indigo-100 shadow-inner rounded-md py-1.5 z-2 relative px-3 justify-between items-center cursor-pointer"
                                  >
                                    <p className="text-base">
                                      <span className="font-semibold ">
                                        Order ID : &nbsp;
                                      </span>
                                      <span>
                                        {data["Order_id"]
                                          ? "xxxxxxxxx" +
                                            data["Order_id"].slice(-3)
                                          : ""}
                                      </span>
                                    </p>

                                    {order === index ? (
                                      <FaCaretUp className="text-[20px]" />
                                    ) : (
                                      <FaCaretDown className="text-[20px]" />
                                    )}
                                  </div>
                                  {/* Toggle Content */}
                                  <div
                                    className={`flex relative flex-col justify-between px-3 gap-1 border-x-2 py-2 border-b font-semibold rounded-b-lg transition-transform duration-300 ease-in-out accordionItem ${
                                      order === index
                                        ? "is-expanded animateIn"
                                        : "animateOut"
                                    }`}
                                  >
                                    <div className="px-3 py-1.5 flex justify-between hover:bg-indigo-50 duration-200">
                                      <p className="">Series</p>
                                      <p className="font-semibold">
                                        {data["Security"]
                                          ? data["Security"].split(" ").pop()
                                          : ""}
                                      </p>
                                    </div>
                                    <div className="px-3 py-1.5 flex justify-between hover:bg-indigo-50 duration-200">
                                      <p>Quantity</p>
                                      <p className="font-semibold">
                                        {data["Units"] ? data["Units"] : ""}
                                      </p>
                                    </div>
                                    <div className="px-3 py-1.5 flex justify-between hover:bg-indigo-50 duration-200">
                                      <p>Price</p>
                                      <p className="font-semibold">
                                        ₹
                                        {data["Price_per_unit"]
                                          ? data[
                                              "Price_per_unit"
                                            ].toLocaleString("en-IN")
                                          : ""}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ))
                            ) : (
                              <>No Transactions Yet</>
                            )}
                          </div>
                        </div>
                      </>
                    ) : activeBox === "b" ? (
                      <>
                        <div className="mt-5 rounded border p-3 relative h-[295px]">
                          {/* Heading of Security */}
                          <p
                            className="bg-indigo-100
                            text-base font-bold px-5 py-1.5 rounded-md w-fit absolute -top-5"
                          >
                            RESIDEX
                          </p>
                          {/* Order ID */}
                          <div className="mt-4 overflow-y-scroll gap-1.5 flex flex-col h-52 text-[15px] text-black " >
                            {Array.isArray(filteredAuctionRESIDEX) &&
                            filteredAuctionRESIDEX.length > 0 ? (
                              filteredAuctionRESIDEX.map((data, index) => (
                                <div
                                  key={index}
                                  className="flex flex-col mt-1 px-3 py-1 "
                                >
                                  <div
                                    onClick={() => handleOrders(index)}
                                    className="flex bg-indigo-100 shadow-inner rounded-md py-1.5 z-2 relative px-3 justify-between items-center cursor-pointer"
                                  >
                                    <p className="text-base">
                                      <span className="font-semibold">
                                        Order ID : &nbsp;
                                      </span>
                                      <span>
                                        {data["Order_id"]
                                          ? "xxxxxxxxx" +
                                            data["Order_id"].slice(-3)
                                          : ""}
                                      </span>
                                    </p>

                                    {order === index ? (
                                      <FaCaretUp className="text-[20px]" />
                                    ) : (
                                      <FaCaretDown className="text-[20px]" />
                                    )}
                                  </div>
                                  {/* Toggle Content */}
                                  <div
                                    className={`flex relative flex-col justify-between px-3 gap-1 border-x-2 py-2 border-b font-semibold rounded-b-lg transition-transform duration-300 ease-in-out accordionItem ${
                                      order === index
                                        ? "is-expanded animateIn"
                                        : "animateOut"
                                    }`}
                                  >
                                    <div className="px-3 py-1.5 flex justify-between hover:bg-indigo-50 duration-200">
                                      <p className="">Series</p>
                                      <p className="font-semibold">
                                        {data["Security"]
                                          ? data["Security"].slice(13)
                                          : ""}
                                      </p>
                                    </div>
                                    <div className="px-3 py-1.5 flex justify-between hover:bg-indigo-50 duration-200">
                                      <p>Quantity</p>
                                      <p className="font-semibold">
                                        {data["Units"] ? data["Units"] : ""}
                                      </p>
                                    </div>
                                    <div className="px-3 py-1.5 flex justify-between hover:bg-indigo-50 duration-200">
                                      <p>Price</p>
                                      <p className="font-semibold">
                                        ₹
                                        {data["Price_per_unit"]
                                          ? data[
                                              "Price_per_unit"
                                            ].toLocaleString("en-IN")
                                          : ""}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ))
                            ) : (
                              <>No Transactions Yet</>
                            )}
                          </div>
                        </div>
                      </>
                    ) : activeBox === "c" ? (
                      <>
                        <div className="mt-5 rounded border p-3 relative h-[295px]">
                          {/* Heading of Security */}
                          <p
                            className="bg-indigo-100
                            text-base font-bold px-5 py-1.5 rounded-md w-fit absolute -top-5"
                          >
                            WALR
                          </p>
                          {/* Order ID */}
                          <div className="mt-4 overflow-y-scroll gap-1.5 flex flex-col h-52 text-[15px] text-black">
                            {Array.isArray(filteredP2PWALR) &&
                            filteredP2PWALR.length > 0 ? (
                              filteredP2PWALR.map((data, index) => (
                                <div
                                  key={index}
                                  className="flex flex-col mt-1 px-3 py-1"
                                >
                                  <div
                                    onClick={() => handleOrders(index)}
                                    className="flex bg-indigo-100 shadow-inner rounded-md py-1.5 z-2 relative px-3 justify-between items-center cursor-pointer"
                                  >
                                    <p className="text-base">
                                      <span className="font-semibold">
                                        Order ID : &nbsp;
                                      </span>
                                      <span>
                                        {data["Order_id"]
                                          ? "xxxxxxxxx" +
                                            data["Order_id"].slice(-3)
                                          : ""}
                                      </span>
                                    </p>

                                    {order === index ? (
                                      <FaCaretUp className="text-[20px]" />
                                    ) : (
                                      <FaCaretDown className="text-[20px]" />
                                    )}
                                  </div>
                                  {/* Toggle Content */}
                                  <div
                                    className={`flex relative flex-col justify-between px-3 gap-1 border-x-2 py-2 border-b font-semibold rounded-b-lg transition-transform duration-300 ease-in-out accordionItem ${
                                      order === index
                                        ? "is-expanded animateIn"
                                        : "animateOut"
                                    }`}
                                  >
                                    <div className="px-3 py-1.5 flex justify-between hover:bg-indigo-50 duration-200">
                                      <p className="">Series</p>
                                      <p className="font-semibold">
                                        {data["Security"]
                                          ? data["Security"].split(" ").pop()
                                          : ""}
                                      </p>
                                    </div>
                                    <div className="px-3 py-1.5 flex justify-between hover:bg-indigo-50 duration-200">
                                      <p>Quantity</p>
                                      <p className="font-semibold">
                                        {data["Units"] ? data["Units"] : ""}
                                      </p>
                                    </div>
                                    <div className="px-3 py-1.5 flex justify-between hover:bg-indigo-50 duration-200">
                                      <p>Price</p>
                                      <p className="font-semibold">
                                        ₹
                                        {data["Price_per_unit"]
                                          ? data[
                                              "Price_per_unit"
                                            ].toLocaleString("en-IN")
                                          : ""}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ))
                            ) : (
                              <>No Transactions Yet</>
                            )}
                          </div>
                        </div>
                      </>
                    ) : (
                      activeBox === "d" && (
                        <>
                          <div className="mt-5 rounded border p-3 relative h-[295px]">
                            {/* Heading of Security */}
                            <p
                              className="bg-indigo-100
                              text-base font-bold px-5 py-1.5 rounded-md w-fit absolute -top-5"
                            >
                              RESIDEX
                            </p>
                            {/* Order ID */}
                            <div className="mt-4 overflow-y-scroll gap-1.5 flex flex-col h-52 text-[15px] text-black">
                              {Array.isArray(filteredP2PRESIDEX) &&
                              filteredP2PRESIDEX.length > 0 ? (
                                filteredP2PRESIDEX.map((data, index) => (
                                  <div
                                    key={index}
                                    className="flex flex-col mt-1 px-3 py-1"
                                  >
                                    <div
                                      onClick={() => handleOrders(index)}
                                      className="flex bg-indigo-100 shadow-inner rounded-md py-1.5 z-2 relative px-3 justify-between items-center cursor-pointer"
                                    >
                                      <p className="text-base">
                                        <span className="font-semibold">
                                          Order ID : &nbsp;
                                        </span>
                                        <span>
                                          {data["Order_id"]
                                            ? "xxxxxxxxx" +
                                              data["Order_id"].slice(-3)
                                            : ""}
                                        </span>
                                      </p>

                                      {order === index ? (
                                        <FaCaretUp className="text-[20px]" />
                                      ) : (
                                        <FaCaretDown className="text-[20px]" />
                                      )}
                                    </div>
                                    {/* Toggle Content */}
                                    <div
                                      className={`flex relative flex-col justify-between px-3 gap-1 border-x-2 py-2 border-b font-semibold rounded-b-lg transition-transform duration-300 ease-in-out accordionItem ${
                                        order === index
                                          ? "is-expanded animateIn"
                                          : "animateOut"
                                      }`}
                                    >
                                      <div className="px-3 py-1.5 flex justify-between hover:bg-indigo-50 duration-200">
                                        <p className="">Series</p>
                                        <p className="font-semibold">
                                          {data["Security"]
                                            ? data["Security"].slice(13)
                                            : ""}
                                        </p>
                                      </div>
                                      <div className="px-3 py-1.5 flex justify-between hover:bg-indigo-50 duration-200">
                                        <p>Quantity</p>
                                        <p className="font-semibold">
                                          {data["Units"] ? data["Units"] : ""}
                                        </p>
                                      </div>
                                      <div className="px-3 py-1.5 flex justify-between hover:bg-indigo-50 duration-200">
                                        <p>Price</p>
                                        <p className="font-semibold">
                                          ₹
                                          {data["Price_per_unit"]
                                            ? data[
                                                "Price_per_unit"
                                              ].toLocaleString("en-IN")
                                            : ""}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                ))
                              ) : (
                                <>No Transactions Yet</>
                              )}
                            </div>
                          </div>
                        </>
                      )
                    )}
                  </div>

                  {/* Transaction Section */}
                  <div className="mx-auto rounded-md shadow-md border-[1px] h-[780px] md:h-[740px] w-full lg:w-[450px] xl:w-[600px] 2xl:w-fit overflow-hidden">
                    <div className="p-4 h-full">
                      <div className="flex justify-between items-center py-2 flex-wrap">
                        <p className="text-lg font-bold">Transaction History</p>
                        <div className="flex mt-2 lg:mt-0">
                          <div className="md:mx-2 flex flex-wrap items-center ">
                            <div className="flex items-center lg:mx-2 border border-1 rounded">
                              <span className="font-size1 border-end font-semibold px-1">
                                From
                              </span>
                              <input
                                type="date"
                                value={fromDate}
                                onChange={(e) => setFromDate(e.target.value)}
                                className="d-inline-block font-size2 ps-1"
                              />
                            </div>
                            <div className="flex border items-center border-1 rounded">
                              <span className="font-size1 border-end font-semibold px-[13px]">
                                To
                              </span>
                              <input
                                type="date"
                                value={toDate}
                                onChange={(e) => setToDate(e.target.value)}
                                className="d-inline-block font-size2 ps-1"
                              />
                            </div>
                            {/* View Button */}
                            <button
                              className="btn btn-sm p-1 mx-1 view-btn btn-secondary"
                              onClick={() => navigateToStatementPage()}
                            >
                              View
                            </button>
                          </div>

                          {/* Bar - Option */}
                          <div className="btn-group dropdown shadow-0">
                            <div
                              className="text-dark me-4 cursor-pointer-style"
                              data-mdb-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <BsFilterLeft size={30} />
                            </div>
                            <div className="dropdown-menu border rounded">
                              <ul className="left-0  list-unstyled m-0 p-0 d-flex flex-column justify-content-around align-items-center">
                                <li
                                  className="text-center cursor-pointer sort-btn w-full"
                                  onClick={() => {
                                    setSelectedTransactionType("All");
                                    setCurrentPage(1);
                                  }}
                                >
                                  All Transactions
                                </li>
                                <li
                                  className="text-center cursor-pointer sort-btn w-full"
                                  onClick={() => {
                                    setSelectedTransactionType("Bank");
                                    setCurrentPage(1);
                                  }}
                                >
                                  Bank Transfer
                                </li>
                                <li
                                  className="text-center cursor-pointer sort-btn w-full"
                                  onClick={() => {
                                    setSelectedTransactionType("Peer-2-Peer");
                                    setCurrentPage(1);
                                  }}
                                >
                                  Peer-2-Peer
                                </li>
                                <li
                                  className="text-center cursor-pointer sort-btn w-full"
                                  onClick={() =>
                                    setSelectedTransactionType("Primary")
                                  }
                                >
                                  Primary
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr className="m-0 p-0 my-2" />

                      {/* All Transaction History  */}
                      <div className="">
                        <div className="flex flex-col overflow-x-scroll">
                          <table className="table-responsive table min-w-[660px] table-hover table-borderless table-sm border-dark">
                            <thead className="">
                              <tr className="text-white bg-[#3B71CA] text-sm 2xl:text-base">
                                <th
                                  scope="col"
                                  className="text-sm leading-none fw-bolder text-center"
                                >
                                  Date
                                </th>
                                <th
                                  scope="col"
                                  className="text-sm leading-none fw-bolder text-center"
                                >
                                  Mode
                                </th>
                                <th
                                  scope="col"
                                  className="text-sm leading-none fw-bolder text-center"
                                >
                                  Credit (₹)
                                </th>
                                <th
                                  scope="col"
                                  className="text-sm leading-none fw-bolder text-center"
                                >
                                  Debit (₹)
                                </th>
                                <th
                                  scope="col"
                                  className="text-sm leading-none fw-bolder text-center"
                                >
                                  Balance (₹)
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <>
                                {Array.isArray(currentTransactions) &&
                                currentTransactions.length > 0 ? (
                                  currentTransactions.map((data, index) => (
                                    <tr
                                      key={index}
                                      className="shadow-md rounded-full hover:scale-105 duration-200"
                                    >
                                      <td className="text-center py-3 text-sm  font-semibold">
                                        {data.Date ? data.Date : ""}
                                      </td>
                                      <td className="text-center py-3 text-sm font-semibold">
                                        {data.Mode === "PMY"
                                          ? "Primary"
                                          : data.Mode === "P2P"
                                          ? "Peer-2-Peer"
                                          : data.Mode === "FEE"
                                          ? "Charges"
                                          : data.Mode === "FAIL"
                                          ? "Failure"
                                          : "Bank Transfer"}
                                      </td>
                                      <td className="text-success text-center py-3 text-sm font-semibold">
                                        {data.Action >= 0
                                          ? parseInt(
                                              data.Action
                                            ).toLocaleString("en-In")
                                          : 0}
                                      </td>
                                      <td className="text-center py-3 text-danger text-sm font-semibold">
                                        {data.Action >= 0
                                          ? 0
                                          : parseInt(
                                              Math.abs(data.Action)
                                            ).toLocaleString("en-In")}
                                      </td>
                                      <td className="text-center py-3 text-sm font-semibold">
                                        {data.Balance
                                          ? parseInt(
                                              data.Balance
                                            ).toLocaleString("en-In")
                                          : 0}
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td
                                      colSpan="5"
                                      className="text-sm lg:text-lg py-3"
                                    >
                                      No Transactions Yet
                                    </td>
                                  </tr>
                                )}
                              </>
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <div className="d-flex justify-content-end align-items-center mt-4">
                        {currentPage > 1 ? (
                          <>
                            <div
                              className="btn btn-sm p-0 btn-secondary"
                              onClick={() => handlePrevPage()}
                            >
                              <BiSkipPrevious size={20} color="#000" />
                            </div>
                            <div className="mx-2 my-0 font-size1">
                              {currentPage - 1}
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="">
                              <BiSkipPrevious size={20} color="#fff" />
                            </div>
                            <div className="mx-2 my-0 font-size1 text-light">
                              {currentPage}
                            </div>
                          </>
                        )}
                        <div
                          className="mx-1 my-0 font-size1 px-2 py-0 rounded"
                          style={{ backgroundColor: "#d8e5f9" }}
                        >
                          {currentPage}
                        </div>
                        {currentPage < totalPages ? (
                          <>
                            <div className="mx-2 my-0 font-size1">
                              {currentPage + 1}
                            </div>

                            <div
                              className="btn p-0 btn-sm btn-secondary"
                              onClick={() => handleNextPage()}
                            >
                              <BiSkipNext size={20} color="black" />
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="mx-2 my-0 font-size1 text-light">
                              {currentPage}
                            </div>

                            <div className="">
                              <BiSkipNext size={20} color="#fff" />
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
    {/* Modal for showing alert */}
    {showModal1 && (
        <AlertYellow
          message="You have not entered a valid amount in multiples of Rs. 100"
          onClose={() => setShowModal1(false)}
        />
      )}
      {showModal2 && (
        <AlertYellow
          message="Minimum amount for Wallet Top-up is Rs. 10,000"
          onClose={() => setShowModal2(false)}
        />
      )}
      {showModal3 && (
        <AlertRed
          message="You cannot withdraw funds used for Buy orders. Please Revoke or Cancel Open Orders and try again."
          onClose={() => setShowModal3(false)}
        />
      )}
      {showModal4 && (
        <AlertRed
          message="Minimum amount should be greater than Rs. 0"
          onClose={() => setShowModal4(false)}
        />
      )}
      {showModal5 && (
        <AlertYellow
          message="You have not entered a valid amount in multiples of Rs. 100"
          onClose={() => setShowModal5(false)}
        />
      )}
    </>
  );
}

export default MoneyWallet;
