import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ReactApexChart from "react-apexcharts";
import { FaArrowCircleRight } from "react-icons/fa";
import CheckAuth from "../../components/ServiceFunctions/CheckAuth.jsx";
import "./Ownership.css";
import Header from "../../components/Header/Header.jsx";
import secureLocalStorage from "react-secure-storage";
import { TbPointFilled } from "react-icons/tb";

function WALRHoldings() {
  const { isAuthenticated, pan } = CheckAuth();
  const [seriesDataYes, setSeriesDataYes] = useState([]);
  const [series, setSeries] = useState([]);
  const [seriesDataNo, setSeriesDataNo] = useState([]);
  const [category, setCategory] = useState([]);
  const [dataFetched, setDataFetched] = useState(false);
  const navigate = useNavigate();

  const handleClick = (portal, pro) => {
    if (portal === "PMY") {
      navigate("/PrimaryMarket/walr", {
        state: {
          pro: `WALR Series ${pro}`,
        },
      });
    } else {
      navigate("/TradingPortal", { state: { pro: pro } });
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      fetch(`https://api-scdndestates.com/api/primary-bid-box/`, {
        method: "POST",
        body: JSON.stringify({}),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to fetch data");
          }
          return response.json();
        })
        .then((data) => {
          setSeries(data.data);
          const seriesDataYes1 = [];
          const seriesDataNo1 = [];
          const categories = series.map((item, index) => Object.keys(item));
          categories.forEach((quarter) => {
            const data = ownershipData?.WALR[quarter];
            if (data) {
              seriesDataYes1.push(data?.CoverYes * 1000 || 0);
              seriesDataNo1.push(data?.CoverNo * 1000 || 0);
            } else {
              seriesDataYes1.push(0);
              seriesDataNo1.push(0);
            }
          });
          setCategory(categories);
          setSeriesDataYes(seriesDataYes1);
          setSeriesDataNo(seriesDataNo1);
          setDataFetched(true);
        })
        .catch((error) => {
          // console.error("Error: ", error);
        });
    }
  }, [dataFetched, isAuthenticated]);
  const storedData = secureLocalStorage.getItem("ownershipData");
  const [tableContent, setTableContent] = useState(null);
  const ownershipData = JSON.parse(storedData);

  function formattedDate(val) {
    const date = new Date(val);
    const options = { day: "2-digit", month: "2-digit", year: "2-digit" };
    const formattedDate = date.toLocaleDateString("en-IN", options);
    return formattedDate;
  }

  function getMaturityDate(series, productName) {
    const p = productName;
    const productData = series.find((item) => Object.keys(item)[0] === p);
    return productData ? formattedDate(productData[p]["Maturity Date"]) : "";
  }

  function getCouponDate(series, productName, cover) {
    const p = productName;
    const productData = series.find((item) => Object.keys(item)[0] === p);
    var couponpostReset = 0;
    if (cover === "Cover Yes") {
      couponpostReset = productData ? productData[p]["Coupon post reset"] : "";
    } else {
      const lastSeriesItem = series[series.length - 1];
      const lastSeriesKey = Object.keys(lastSeriesItem)[0];
      couponpostReset = lastSeriesItem[lastSeriesKey]["Coupon at Issue"];
    }
    return couponpostReset;
  }

  function getCouponIssue(series, productName) {
    const p = productName;
    const productData = series.find((item) => Object.keys(item)[0] === p);
    const couponIssue = productData ? productData[p]["Coupon at Issue"] : "";
    return couponIssue;
  }

  function getOptionPrice(series, productName, cover) {
    const p = productName;
    const productData = series.find((item) => Object.keys(item)[0] === p);
    var optionPrice = productData ? productData[p]["Option Price"] : "";
    if (cover === "Cover Yes") return optionPrice;
    else {
      optionPrice = 0;
    }
    return optionPrice;
  }

  function getTotalInterest(series, productName) {
    const p = productName;
    const productData = series.find((item) => Object.keys(item)[0] === p);
    var totalInterest = productData ? productData[p]["Total Interest"] : "";
    return totalInterest;
  }

  const formatRupees = (value) => {
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      maximumFractionDigits: 0,
    })
      .format(value)
      .replace("₹", "");
  };

  const handleBarClick = (
    event,
    chartContext,
    { dataPointIndex, seriesIndex }
  ) => {
    const seriesName = chartData.options.xaxis.categories[dataPointIndex]?.[0];
    const category = chartData.series[seriesIndex].name;
    const value = chartData.series[seriesIndex].data[dataPointIndex];
    setTableContent(
      <>
        <div className="m-0 p-0 ">
          <div className="p-3 border rounded-lg shadow-md bg-white">
            {/* Heading */}
            <div className="max-2xl:flex-col 2xl:flex-row flex items-center justify-between flex-wrap max-2xl:gap-y-2">
              <div className="flex items-center">
                <h5 className="font-bold text-xs">
                  WALR @RBI:{" "}
                  <span>
                    <a
                      className="text-[#008FFB] text-xs"
                      href="https://website.rbi.org.in/web/rbi/-/press-releases/lending-and-deposit-rates-of-scheduled-commercial-banks-april-2024"
                      target="blank"
                    >
                      website.rbi.org.in
                    </a>
                  </span>
                </h5>
              </div>
              <div className="flex items-center gap-2">
                <div className="font-bold text-xs">
                  Quantity:{" "}
                  <span className="font-bold border px-2 py-1 text-xs md:text-sm text-[#008FFB] rounded-md">
                    {isNaN(value / 1000) ? 0 : value / 1000} Units
                  </span>
                </div>
              </div>
            </div>
            <hr className="mt-2" />

            {/* WALR Data */}
            <div className="flex flex-col my-4 justify-between">
              <div className="lg:flex items-end justify-between">
                <div className="col-lg-4 col-md-4 col-sm-12 flex lg:flex-col text-sm 2xl:text-base justify-between items-center w-full lg:w-[120px] xl:w-[140px] px-2 mb-4 lg:mb-0">
                  <h6 className="fw-bold text-center">
                    Next <br /> Payment <br />
                    <span className="font-size1">( ₹ )</span>
                  </h6>
                  <div className="border-4 border-[#4c3fbb] m-2 d-flex align-items-center justify-content-center info-style rounded-circle">
                    <h3 className="text-[#4c3fbb] fw-bold text-xl">
                      {isNaN(
                        (getCouponDate(series, seriesName, category) * value) /
                          400
                      )
                        ? "0"
                        : (
                            (getCouponDate(series, seriesName, category) *
                              value) /
                            400
                          ).toLocaleString("en-IN")}
                    </h3>
                  </div>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-12 flex lg:flex-col text-sm 2xl:text-base justify-between items-center w-full lg:w-[120px] xl:w-[140px] px-2 mb-4 lg:mb-0">
                  <h6 className="fw-bold text-center">
                    Coupon <br />
                    at Issue <br />
                    <span className="font-size1">( % )</span>
                  </h6>
                  <div className="border-4 border-[#635DAD] m-2 d-flex align-items-center justify-content-center info-style rounded-circle">
                    <h3 className="text-[#655DAD] fw-bold text-xl">
                      {getCouponIssue(series, seriesName) === ""
                        ? 0
                        : parseFloat(
                            getCouponIssue(series, seriesName)
                          ).toFixed(2)}
                    </h3>
                  </div>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-12 flex lg:flex-col text-sm 2xl:text-base justify-between items-center w-full lg:w-[120px] xl:w-[140px] px-2 mb-4 lg:mb-0">
                  <h6 className="fw-bold text-center">
                    Coupon post
                    <br /> reset <br />
                    <span className="font-size1">( % )</span>
                  </h6>
                  <div className="border-4 border-[#776fcd] m-2 d-flex align-items-center justify-content-center info-style rounded-circle">
                    <h3 className="text-[#776fcd] fw-bold text-xl">
                      {getCouponDate(series, seriesName, category) === "" ||
                      isNaN(
                        parseFloat(getCouponDate(series, seriesName, category))
                      )
                        ? "0.00"
                        : parseFloat(
                            getCouponDate(series, seriesName, category)
                          ).toFixed(2)}
                    </h3>
                  </div>
                </div>
              </div>

              <div className="lg:flex items-end justify-between">
                <div className="col-lg-4 col-md-4 col-sm-12 flex lg:flex-col text-sm 2xl:text-base justify-between items-center w-full lg:w-[120px] xl:w-[140px] px-2 mb-4 lg:mb-0">
                  <h6 className="fw-bold text-center">
                    Maturity <br /> Date <br />{" "}
                    <span className="text-[13px] font-semibold">dd/mm/yy</span>
                  </h6>
                  <div className="border-4 border-[#20656b] m-2 d-flex align-items-center justify-content-center info-style rounded-circle">
                    <h6 className="text-[#20656b] fw-bold text-xl text-center">
                      {getMaturityDate(series, seriesName) === ""
                        ? 0
                        : getMaturityDate(series, seriesName)}
                    </h6>
                  </div>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-12 flex lg:flex-col text-sm 2xl:text-base justify-between items-center w-full lg:w-[120px] xl:w-[140px] px-2 mb-4 lg:mb-0">
                  <h6 className="fw-bold text-center">
                    Option <br /> Price
                    <br />
                    <span className="font-size1">( ₹ )</span>
                  </h6>
                  <div className="border-4 border-[#0D828D] m-2 d-flex align-items-center justify-content-center info-style rounded-circle">
                    <h3 className="text-[#0D828D] fw-bold text-xl">
                      {getOptionPrice(series, seriesName, category) === ""
                        ? 0
                        : parseFloat(
                            getOptionPrice(series, seriesName, category)
                          ).toFixed(2)}
                    </h3>
                  </div>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-12 flex lg:flex-col text-sm 2xl:text-base justify-between items-center w-full lg:w-[120px] xl:w-[140px] px-2 mb-4 lg:mb-0">
                  <h6 className="fw-bold text-center">
                    Excess <br />
                    Interest <br />
                    <span className="font-size1">( ₹ /unit )</span>
                  </h6>
                  <div className="border-4 border-[#35a6b0] m-2 d-flex align-items-center justify-content-center info-style rounded-circle">
                    <h3 className="text-[#35a6b0] fw-bold text-xl">
                      {getTotalInterest(series, seriesName) === ""
                        ? 0
                        : parseFloat(
                            getTotalInterest(series, seriesName)
                          ).toFixed(2)}
                    </h3>
                  </div>
                </div>
              </div>
            </div>

            <hr className="my-0 py-0" />
            {/* Buttons */}
            <div className="flex flex-wrap justify-between mt-2 gap-2">
              <button
                className="button shadow-sm"
                onClick={() => {
                  handleClick("PMY", seriesName);
                }}
                style={{ cursor: "pointer" }}
              >
                <i>
                  <img
                    className="w-6 brightness-150"
                    src="/images/PrimaryMarket.png"
                    alt=""
                  />
                </i>
                <p className="text-sm">SCDND auction</p>
                <i>
                  <FaArrowCircleRight size={16} />
                </i>
              </button>
              <button
                className="button shadow-sm"
                onClick={() => {
                  handleClick("P2P", seriesName);
                }}
                style={{ cursor: "pointer" }}
              >
                <i>
                  <img src="/images/stock.svg" alt="" className="w-6" />
                </i>
                <p>Trade P-2-P</p>
                <i>
                  <FaArrowCircleRight size={16} />
                </i>
              </button>
            </div>
          </div>
        </div>
      </>
    );
  };

  const chartData = {
    series: [
      { name: "Cover Yes", data: seriesDataYes },
      { name: "Cover No", data: seriesDataNo, color: "#8d80ec" },
    ],
    options: {
      chart: {
        type: "bar",
        height: 250,
        events: {
          dataPointSelection: (event, chartContext, config) => {
            handleBarClick(event, chartContext, config);
          },
        },
        stacked: true,
        toolbar: {
          tools: {
            download: false,
          },
        },
      },
      fill: {
        type: ["gradient", "pattern"],
        gradient: {
          type: "vertical",
          stops: [0, 33, 66, 100],
          colorStops: [
            { offset: 0, color: "#3B71CA" },
            { offset: 33, color: "#0590F8" }, // New color stop
            { offset: 66, color: "#0590F8" },
            { offset: 100, color: "#3B71CA" },
          ],
        },
        pattern: {
          style: "circles",
        },
      },

      plotOptions: {
        bar: {
          borderRadius: 2,
          horizontal: false,
          dataLabels: {
            total: {
              enabled: true,
              offsetX: -20,
              style: {
                colors: ["#333"],
              },
              formatter: function (val, opts) {
                const total = chartData.series.reduce(
                  (acc, series) => acc + series.data[opts.dataPointIndex],
                  0
                );
                return "₹" + formatRupees(total);
              },
            },
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: category,
        labels: {
          rotate: -45,
          rotateAlways: true,
        },
      },
      yaxis: {
        title: { text: "Amount (₹)" },
        labels: {
          formatter: function (value) {
            return formatRupees(value / 1000) + "K";
          },
        }, // Set the maximum value for the y-axis
      },
      responsive: [
        {
          breakpoint: 2300,
          options: {
            chart: {
              height: 500,
            },
          },
        },
        {
          breakpoint: 1300,
          options: {
            chart: {
              height: 500,
            },
          },
        },
        {
          breakpoint: 976,
          options: {
            chart: {
              height: 400,
            },
          },
        },
        {
          breakpoint: 576,
          options: {
            chart: {
              height: 350,
            },
          },
        },
      ],
    },
  };

  useEffect(() => {
    const defaultDataPointIndex = 0;
    const defaultSeriesIndex = 0;
    handleBarClick(null, null, {
      dataPointIndex: defaultDataPointIndex,
      seriesIndex: defaultSeriesIndex,
    });
  }, [pan]);

  return (
    <>
      <Header />
      <div className="after-header px-0 m-0  max-w-[1800px] mx-auto">
        {/* Path Navigation */}
        <div className="row w-[90%] mx-auto">
          <h6 className="pt-4">
            <a href="/">
              <span className="header-link">Home</span>
              <span className="text-secondary"> &gt; </span>
            </a>
            <a href="/ownership">
              <span className="header-link">Ownership</span>
              <span className="text-secondary"> &gt; </span>
            </a>
            <a href="#">
              <span className="text-dark text-decoration-underline">WALR</span>
            </a>
          </h6>
        </div>

        <div className="lg:flex justify-between bg-[#EDEDED] rounded-t-lg p-3 w-[90%] mx-auto gap-2">
          {/* Left Container */}
          <div className="w-full lg:w-[70%] bg-white rounded-lg overflow-scroll xl:overflow-y-hidden">
            <div className="min-w-[550px] max-h-[600px]">
              {dataFetched && (
                <div id="chart">
                  <ReactApexChart
                    className="w-full "
                    options={chartData.options}
                    series={chartData.series}
                    type="bar"
                    height="580"
                  />
                </div>
              )}
            </div>
          </div>
          {/* Right Container */}
          <div className="w-full lg:w-[450px] 2xl:[500px] pt-3 lg:-mt-4">
            <div>{tableContent}</div>
          </div>
        </div>

        <div className="rounded-b-lg m-0 bg-[#EDEDED] px-3 pb-3 w-[90%] mx-auto">
          <div className="bg-white rounded-lg border">
            <div className="text-[15px] font-semibold p-3 m-0 flex- flex-col gap-3">
              <div className="flex gap-1 items-start lg:items-center">
                <span className="">
                  <TbPointFilled />
                </span>
                <span className="m-0 p-0">
                  Your WALR holdings are a sum of 15 different Outstanding
                  Series, displayed as a histogram. Please click on it to see
                  the details and transactions of each series.
                </span>
              </div>

              <div className="flex gap-1 items-start lg:items-center">
                <span className="">
                  <TbPointFilled />
                </span>
                <span className="">
                  Redemptions of the older series of WALR are automatically
                  removed from the histograms simultaneously upon repayment of
                  the principal to your linked Bank Account.
                </span>
              </div>

              <div className="flex gap-1 items-start lg:items-center">
                <span className="">
                  <TbPointFilled />
                </span>
                <span>
                  The 'Call Duration' for each Series of WALR refers to the
                  remaining period of upside protection guaranteed by SCDND (if
                  RBI rates are revised higher).
                </span>
              </div>

              <div className="flex gap-1 items-start lg:items-center">
                <span className="">
                  <TbPointFilled />
                </span>
                <span>
                  After the 'Call Duration' expires, your Principal amount on
                  the relevant WALR Series will be automatically
                  redeemed/refunded to your Bank.
                </span>
              </div>

              <div className="flex gap-1 items-start">
                <span className="">
                  <TbPointFilled />
                </span>
                <span>
                  The 'Coupon post Reset' figure displays the current rate of
                  interest on which your quarterly payments will be calculated.
                  Please note that this will always be higher than the 'Coupon
                  at Issue' figure, whether or not RBI revises rates downwards
                  in the interim, as downside protection on your WALR Series is
                  guaranteed.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default WALRHoldings;
