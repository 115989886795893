import React, { useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa6";
import { IoIosArrowDropdown } from "react-icons/io";
import { IoIosArrowDropup } from "react-icons/io";
import { BsQuestionCircleFill } from "react-icons/bs";

function AccordionItem() {
  const productFAQ = [
    {
      id: 0,
      ques: "Does SCDND publicly disclose client-wise ownership/holding data of each series of WALR, and each city-based index of RESIDEX bonds?",
      ans: "No. The client-wise data, both ownership and transactions are strictly confidential and never shared with any third party. Only the series-wise (for WALR) or city-wise (for RESIDEX) aggregates are disclosed on our website.",
    },
    {
      id: 1,
      ques: "Is the ‘On-chain DeAs’ value displayed equal to the cumulative series/city-wise holdings of all the existing Clients of SCDND?",
      ans: "Yes, it is the total value of all the decentralized securities, broken up in a series-wise (for WALR) and city-wise (for RESIDEX), that is payable to all the clients i.e., the Assets under Management of SCDND. ",
    },
    {
      id: 2,
      ques: "Why does SCDND hold and disclose Collateral hypothecated against client funds?",
      ans: "SCDND successfully removes all counterparty/default risk from the perspective of their clients. Investors do not have to depend on any third-party credit-ratings or trust SCDND’s business model/ethics etc., as their money is fully backed and secured by assets (of higher value). Furthermore, the collateral cover is disclosed and updated in real-time, and always open to verifications/audits by the clients themselves.",
    },
    {
      id: 3,
      ques: "What kind of Assets are held by SCDND under this arrangement?",
      ans: (
        <>
          Only six asset-classes are used to maintain collateral, namely:{" "}
          <br />
          a) Gold, in the form of Sovereign Gold Bonds (issued & guaranteed by
          GOI) and Gold ETFs. <br />
          b) Nifty500, in the form of Nifty500 ETF (passive mutual funds) and
          Nifty500 Futures, both listed on BSE/NSE. <br />
          c) Rupee denominated listed corporate NCDs and State development loans. <br />
          d) US Treasury securities and Dollar/Euro/Yen currency swap contracts. <br />
          e) Cash (in INR) in Current A/Cs of commercial banks in India.
        </>
      ),
    },
    {
      id: 4,
      ques: "How can I independently audit/verify the outstanding DeAs and the Collateral balances of your Company as displayed on your website?",
      ans: (
        <>
          The WALR and RESIDEX Blockchains are public and open-source
          distributed ledger, and any node on the network can audit the total
          number of DeAs existing on each one (similar to the total no. of
          tokens in circulation at any point of time). The collateral
          are held in Escrow Demat and Bank Current A/C
          (for Cash). Any KYC compliant User can apply for accessing and
          auditing the real-time A/C statements to the Escrow Bank <br />(please find
          the details below); <br />a) Current A/C <br />ICICI Bank: 035505500654 <br />Bank of
          Baroda: 00300200001066 <br />b) Demat A/C <br /> ICICI Bank NSDL: IN303028-65117408 <br />
          ICICI Bank CDSL:1601430104284762 <br /> Bank of Baroda NSDL: IN300870-10472007 <br /> Bank of Baroda CDSL: 13018700003669961
        </>
      ),
    },
    {
      id: 5,
      ques: "What makes SCDND’s collateral superior to say a real-estate mortgage or future receivables usually maintained by Banks/NBFCs (against their secured bonds/debentures)?",
      ans: "Gold is extremely liquid, and its price is set internationally by central banks and large refiners, and the same for US Treasuries. Similarly, the index value of Nifty500 or Indian G-sec cannot be manipulated by any 'whales' due to it being internationally traded, widely used and its sheer market cap. Furthermore, all these assets are fungible, their prices are updated in real-time and publicly accessible, so there can be no dispute on its value during any debt recovery process.",
    },
    {
      id: 6,
      ques: "Does SCDND include (or create charge on) any other kinds of assets for maintenance of collateral if the cumulative value of the above becomes insufficient?",
      ans: "No. If the collateral cover falls below 105% of the outstanding liability at any time, SCDND immediately liquidates the entire collateral and repays/redeems WALR and/or Residex bonds owned by Clients, or alternatively puts up fresh collateral from its own funds (shareholder equity) in the same Escrow account, until the cover is restored.",
    },
    {
      id: 7,
      ques: "Where are the securities held as collateral kept? How can they be recovered in case of a default in repayment by SCDND?",
      ans: "All of the collateral is kept in dematerialized form in a segregated Custodian A/C, under a well-defined (and immutable) Escrow mechanism which stipulates real-time maintenance of cover based on clinet-side liabilities, liquidation of the assets in the event of default, and repayment of the proceeds directly to Clients.",
    },
    {
      id: 8,
      ques: "How is the Coverage Ratio calculated and what does it signify?",
      ans: "Coverage Ratio = Collateral/Liabilities. The historical data (with dates and descriptions) reflects the stability of SCDND’s business model even during periods of extreme stress and crisis events affecting the global economy, which may have damaged or destroyed other financial institutions and/or asset-values but had near-negligible effect on us.",
    },
  
  ];

  const [openIndex, setOpenIndex] = useState(-1);

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? -1 : index);
  };

  return (
    <div className="border rounded-lg p-3 relative transition-all duration-500 ease-in-out mt-20">
      <h2 className="font-bold text-lg bg-white text-black absolute -top-4 px-2 w-fit">
        Frequently Asked Questions
      </h2>
      <div className="mt-3 ">
        <div className="flex flex-col gap-0">
          {productFAQ.map((data, index) => (
            <div className="text-black" key={index}>
              <h2
                className="flex flex-row justify-between lg:ṭitems-center text-xs lg:text-base font-semibold p-3 cursor-pointer "
                onClick={() => toggleAccordion(index)}
              >
                <div className="flex lg:items-center gap-2">
                  <span className="text-base md:text-lg mt-0.5 md:mt-0">
                    <BsQuestionCircleFill  />
                  </span>
                  <span
                    className="font-semibold px-2 duration-500 ease-in-out"
                    style={{
                      color: openIndex === index ? "#339dfa" : "#212121",
                    }}
                  >
                    {data.ques}
                  </span>
                </div>
                <span className="duration-1000 ease-in-out">
                  {openIndex === index ? (
                    <IoIosArrowDropup
                      // size={25}
                      className="duration-1000 ease-in-out text-[#339dfa] text-lg md:text-lg lg:text-2xl"
                    />
                  ) : (
                    <IoIosArrowDropdown
                      // size={25}
                      className="duration-1000 ease-in-out text-[#339dfa] text-lg md:text-lg lg:text-2xl"
                    />
                  )}
                </span>
              </h2>
              <div
                style={{
                  maxHeight: openIndex === index ? "300px" : "0",
                }}
                className={`ml-7  border-l-2 border-[#339dfa]  overflow-hidden  duration-700 ease-in-out ${
                  openIndex === index ? "max-h-screen " : "max-h-0 "
                }`}
              >
                <p className=" py-1 pl-3 pr-4 text-[#212121] font-medium text-xs lg:text-base">
                  {data.ans}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default AccordionItem;
