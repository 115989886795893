import React, { useEffect } from "react";
import Header from "../../components/Header/Header.jsx";
import Welcome from "../../components/Welcome/Welcome.jsx";
import CircularNavigation from "./CircularNavigation";
import ProgressiveDisclosureTable from "../../components/WhatIs/ProgressiveDisclosureTable.jsx"
import ContactUs from "../../components/ContactUs/ContactUs.jsx";
import MarketAnimation from "../Home/MarketAnimation.jsx"

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <>
      <Header />
      <Welcome />
      <div className="" style={{ position: "relative" }}>
        <CircularNavigation  />
        <ContactUs />
        <MarketAnimation />
        <ProgressiveDisclosureTable />
      </div>
    </>
  );
}

export default Home;
