import React from 'react'

function AlertBlue({message, onClose}) {
  return (<>
  <div className="fixed left-0 w-full flex items-start justify-center z-[9999999] top-20 alert-animation">
    <div
    class="bg-blue-100 max-w-lg mx-3 border-l-4 border-blue-500 text-blue-900 p-2 rounded-r-lg flex items-center transition duration-300 ease-in-out hover:bg-blue-200 transform  scale-100 hover:scale-105 justify-between"
  >
    <div className="flex items-center justify-start">
    <svg className='w-[24px] h-[24px] fill-[#3b82f6]' viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" id="fi_13983966"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1_copy_2" data-name="Layer 1 copy 2"><g id="_5" data-name="5"><path d="m256 0c-141.38 0-256 114.62-256 256s114.62 256 256 256 256-114.62 256-256-114.62-256-256-256zm32.42 363.58a32.42 32.42 0 0 1 -32.42 32.42 32.42 32.42 0 0 1 -32.42-32.42v-129.69a32.42 32.42 0 0 1 32.42-32.42 32.42 32.42 0 0 1 32.42 32.42zm-32.42-182.74a32.42 32.42 0 1 1 32.42-32.42 32.41 32.41 0 0 1 -32.42 32.42z"></path></g></g></g></svg>
    <p class="pl-2 text-xs font-semibold">
    {message}
    </p></div>
    <button onClick={onClose} className="flex items-center ml-3" >
        <svg className="fill-gray-900 hover:fill-red-500 scale-100 hover:scale-90 duration-200 ease-in-out" height="20" viewBox="0 0 32 32" width="20" xmlns="http://www.w3.org/2000/svg" id="fi_2734822"><g id="Layer_22" data-name="Layer 22"><path d="m21 12.46-3.59 3.54 3.59 3.54a1 1 0 0 1 0 1.46 1 1 0 0 1 -.71.29 1 1 0 0 1 -.7-.29l-3.59-3.59-3.54 3.59a1 1 0 0 1 -.7.29 1 1 0 0 1 -.71-.29 1 1 0 0 1 0-1.41l3.54-3.59-3.54-3.54a1 1 0 0 1 1.41-1.41l3.54 3.54 3.54-3.54a1 1 0 0 1 1.46 1.41zm4.9 13.44a14 14 0 1 1 0-19.8 14 14 0 0 1 0 19.8zm-1.41-18.39a12 12 0 1 0 0 17 12 12 0 0 0 0-17z"></path></g></svg>
    </button>
  </div>
  </div></>
  )
}

export default AlertBlue;