import React from "react";
import Header from "../../components/Header/Header";
import { TbPointFilled } from "react-icons/tb";

function Disclosures() {
  return (
    <>
      <Header />
      <div className="after-header pb-5 max-w-[1800px] mx-auto">
        <div className="w-[90%] mx-auto">
          <div className="w-full flex flex-wrap gap-4 justify-start mt-4 ">
            <div className="justify-center border rounded-lg shadow-md w-full p-[1%]">
              <div className="col-12">
                <div className="row justify-content-center p-3 text-black">
                  <h4 className="text-center fw-bold mb-2 text-2xl flex items-center justify-center gap-2">
                    <span>
                      <svg
                        id="fi_3437346"
                        enableBackground="new 0 0 512 512"
                        height="512"
                        viewBox="0 0 512 512"
                        width="512"
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-8 h-fit"
                      >
                        <g>
                          <path
                            d="m256 21.996c-135.311 0-245.002 109.691-245.002 245.002 0 61.567 22.714 117.824 60.213 160.864l79.062 60.208c32.009 15.336 67.864 23.93 105.727 23.93 135.311 0 245.002-109.691 245.002-245.002s-109.691-245.002-245.002-245.002z"
                            fill="#4fabf7"
                          ></path>
                          <path
                            d="m271.868 421.246-.616-1.126c-3.565-6.514-1.153-14.761 5.361-18.326l.75-.41c-6.824 3.735-15.463 1.208-19.198-5.615-3.735-6.823-1.208-15.462 5.615-19.197-6.824 3.735-15.462 1.208-19.198-5.616-3.735-6.824-1.208-15.462 5.615-19.198-6.824 3.735-15.463 1.208-19.198-5.616-3.735-6.823-1.208-15.462 5.616-19.198l18.427-10.087-2.809-5.131c-2.063-3.769-5.643-6.474-9.832-7.43-15.315-3.493-31.214 2.683-40.155 15.598-8.054 11.96.048-2.625-20.368 44.198-1.317 3.124-3.652 5.71-6.626 7.339l-104.041 56.431c21.854 25.084 48.729 45.675 79.062 60.208l73.496-39.865c2.015-1.103 4.256-1.729 6.551-1.83l22.343-.981c2.295-.101 4.536-.727 6.551-1.83l29.538-16.17-.95.233c-6.021 1.909-12.793-.644-15.934-6.381z"
                            fill="#fcad6d"
                          ></path>
                          <path
                            d="m315.136 413.251-73.366-134.427-51.556 11.876 80.262 147.06c6.768 12.333 22.252 16.844 34.585 10.076 12.332-6.768 16.843-22.252 10.075-34.585z"
                            fill="#3c58a0"
                          ></path>
                          <path
                            d="m236.499 323.9c-5.683-10.413.143-23.374 11.702-26.037l3.517-.81-9.949-18.23-51.555 11.877 80.262 147.06c6.637 12.095 21.657 16.66 33.867 10.447z"
                            fill="#2a428c"
                          ></path>
                          <g fill="#f9ba8f">
                            <path d="m270.328 422.088-.616-1.126c-3.566-6.514-1.153-14.761 5.361-18.327l37.94-20.768c6.514-3.566 14.761-1.153 18.327 5.361l.616 1.126c3.566 6.514 1.153 14.761-5.361 18.327l-37.94 20.768c-6.514 3.566-14.761 1.153-18.327-5.361z"></path>
                            <path d="m256.625 396.61c-3.735-6.824-1.208-15.463 5.616-19.198l45.487-24.899c6.824-3.735 15.463-1.208 19.198 5.615 3.735 6.823 1.208 15.462-5.615 19.198l-45.487 24.899c-6.825 3.736-15.464 1.208-19.199-5.615z"></path>
                            <path d="m243.043 371.797c-3.735-6.824-1.208-15.463 5.615-19.198l48.317-26.448c6.824-3.735 15.463-1.208 19.198 5.615 3.735 6.824 1.208 15.463-5.615 19.198l-48.317 26.448c-6.824 3.735-15.463 1.208-19.198-5.615z"></path>
                            <path d="m296.652 310.204c-3.735-6.824-12.374-9.351-19.198-5.616l-42.379 23.197c-6.824 3.735-9.351 12.374-5.615 19.198 3.735 6.824 12.374 9.351 19.198 5.615l42.378-23.197c6.824-3.735 9.351-12.373 5.616-19.197z"></path>
                          </g>
                          <path
                            d="m176.855 370.401c.634 8.855 4.415 22.554 21.302 25.745 11.23 2.122 22.595-12.148 22.595-12.148 14.214-19.858 14.284-34.516 13.32-40.9-.084-.553.662-.803.931-.313l3.625 6.622c1.269 2.318 2.929 4.283 4.85 5.868 4.553 3.316 10.785 3.93 16.053 1.047 4.873-2.668 7.676-7.644 7.8-12.826.143-5.96-2.534-8.567-15.095-31.772-.951-1.736-2.35-3.43-4.102-4.757-.006-.004-.011-.009-.016-.013-1.641-1.24-3.589-2.175-5.713-2.66-15.315-3.493-31.214 2.683-40.155 15.598-8.054 11.96.048-2.625-20.368 44.198-1.071 2.538-2.82 4.709-5.027 6.311z"
                            fill="#fcad6d"
                          ></path>
                          <path
                            d="m249.65 336.53 6.462 11.805c1.702 3.109 5.454 4.414 8.678 3.174 1.588-2.376 2.471-5.162 2.539-8.012.127-5.294-2.083-7.753-8.614-19.933l-6.277 3.436c-3.388 1.854-4.642 6.143-2.788 9.53z"
                            fill="#f9dfcd"
                          ></path>
                          <g>
                            <path
                              d="m203.692 390.972c-.949-1.751-.608-3.986.944-5.367 25.783-22.94 21.965-48.437 21.924-48.692-.391-2.392 1.233-4.647 3.625-5.038 2.393-.39 4.649 1.233 5.037 3.625.201 1.235 4.597 30.549-24.751 56.662-1.811 1.611-4.585 1.449-6.196-.361-.231-.259-.425-.537-.583-.829z"
                              fill="#fd995b"
                            ></path>
                          </g>
                          <g>
                            <path
                              d="m165.894 286.691-28.867-125.32c-.192-.836-.279-1.67-.284-2.493-34.074 9.651-54.932 44.453-46.895 79.345s42.02 57.063 76.881 50.834c-.355-.742-.642-1.53-.835-2.366z"
                              fill="#e9efff"
                            ></path>
                          </g>
                          <path
                            d="m96.875 256.283c13.695 24.1 41.483 37.842 69.854 32.774-.356-.742-.643-1.53-.836-2.366l-8.772-38.081c-.767-.398-1.559-.862-2.375-1.401.002 0-25.128 26.536-57.871 9.074z"
                            fill="#d3dcfb"
                          ></path>
                          <path
                            d="m327.044 74.825c-26.265 30.774-60.721 53.299-99.212 65.077.048.173.106.341.147.518l28.867 125.32c.041.177.062.353.095.53 39.765-6.248 80.602-1.067 117.684 15.116l62.1 27.1c-3.651-15.852-62.043-269.347-65.695-285.199z"
                            fill="#e9efff"
                          ></path>
                          <path
                            d="m345.767 52.888 31.183 126.042c5.084 20.55-11.351 40.188-32.462 38.606-24.947-1.87-58.439-1.193-94.759 8.394 0 0-.443 1.108-1.479 2.49l8.597 37.32c.041.177.063.353.095.53 39.765-6.248 80.602-1.067 117.684 15.116l62.1 27.1c-3.651-15.852-62.043-269.347-65.695-285.199z"
                            fill="#d3dcfb"
                          ></path>
                          <path
                            d="m467.962 130.238c-29.815 1.651-49.413-12.027-49.413-12.027 2.345 10.182 17.018 73.881 19.379 84.13 0 0 11.639-20.873 39.172-32.432 4.423-1.857 6.915-6.578 5.838-11.253l-4.803-20.852c-1.077-4.675-5.383-7.831-10.173-7.566z"
                            fill="#e9efff"
                          ></path>
                          <path
                            d="m480.113 146.393c-5.18 2.094-9.766 4.306-13.741 6.482-10.622 5.813-23.85-.125-26.568-11.925-1.196-5.191-2.378-10.323-3.368-14.62-11.312-3.54-17.887-8.12-17.887-8.12 2.345 10.182 17.018 73.881 19.379 84.13 0 0 11.639-20.873 39.172-32.432 4.424-1.857 6.915-6.578 5.838-11.253z"
                            fill="#d3dcfb"
                          ></path>
                          <g>
                            <path
                              d="m453.543 325.817c-7.266 1.674-14.512-2.859-16.186-10.125l-68.91-299.158c-1.674-7.266 2.859-14.512 10.125-16.186s14.512 2.859 16.186 10.125l68.91 299.158c1.674 7.266-2.859 14.512-10.125 16.186z"
                              fill="#ff4155"
                            ></path>
                          </g>
                          <path
                            d="m463.668 309.631-27.214-118.145c-1.061 5.531-5.267 10.193-11.108 11.539-5.991 1.38-11.964-1.13-15.293-5.869l27.304 118.535c1.674 7.266 8.92 11.799 16.186 10.125 7.266-1.673 11.799-8.92 10.125-16.185z"
                            fill="#e80054"
                          ></path>
                          <path
                            d="m216.301 131.447-72.698 16.746c-6.117 1.409-9.934 7.51-8.525 13.627l28.867 125.32c1.409 6.117 7.51 9.933 13.627 8.524l72.698-16.746c6.117-1.409 9.934-7.51 8.524-13.627l-28.867-125.32c-1.408-6.116-7.509-9.933-13.626-8.524z"
                            fill="#ff4155"
                          ></path>
                          <path
                            d="m231.318 232.702-56.921 13.112s-6.789 9.889-19.649 1.395l9.198 39.931c1.409 6.117 7.51 9.933 13.627 8.524l72.698-16.746c6.117-1.409 9.933-7.51 8.524-13.627l-9.067-39.361s-4.493 11.262-18.41 6.772z"
                            fill="#e80054"
                          ></path>
                          <g>
                            <path
                              d="m180.274 148.75c-3.415 0-6.502-2.349-7.302-5.818l-4.967-21.565c-.93-4.036 1.589-8.063 5.625-8.992 4.035-.935 8.063 1.589 8.992 5.625l4.967 21.565c.93 4.036-1.589 8.063-5.625 8.992-.566.13-1.133.193-1.69.193z"
                              fill="#bec8f7"
                            ></path>
                          </g>
                          <g>
                            <path
                              d="m164.39 128.144c-3.415 0-6.502-2.349-7.302-5.818-.93-4.036 1.589-8.063 5.625-8.992l21.152-4.872c4.037-.933 8.062 1.588 8.992 5.625.93 4.036-1.589 8.063-5.625 8.992l-21.152 4.872c-.566.131-1.132.193-1.69.193z"
                              fill="#e9efff"
                            ></path>
                          </g>
                          <g>
                            <path
                              d="m156.636 168.708c-.93-4.036 1.589-8.063 5.625-8.992l42.304-9.744c4.036-.934 8.062 1.589 8.992 5.625s-1.589 8.063-5.625 8.992l-42.304 9.744c-4.027.93-8.061-1.582-8.992-5.625z"
                              fill="#cc0053"
                            ></path>
                          </g>
                          <g>
                            <path
                              d="m162.556 194.41c-.93-4.036 1.589-8.063 5.625-8.992l42.304-9.744c4.036-.93 8.062 1.589 8.992 5.625s-1.589 8.063-5.625 8.992l-42.304 9.744c-4.026.93-8.061-1.582-8.992-5.625z"
                              fill="#cc0053"
                            ></path>
                          </g>
                          <g>
                            <path
                              d="m168.476 220.112c-.93-4.036 1.588-8.063 5.625-8.992l42.304-9.745c4.037-.93 8.062 1.589 8.992 5.625s-1.588 8.063-5.625 8.992l-42.304 9.745c-4.026.93-8.06-1.582-8.992-5.625z"
                              fill="#cc0053"
                            ></path>
                          </g>
                          <g>
                            <path
                              d="m174.397 245.814c-.93-4.036 1.588-8.063 5.625-8.992l42.304-9.745c4.037-.932 8.063 1.589 8.992 5.625.93 4.036-1.588 8.063-5.625 8.992l-42.304 9.745c-4.026.93-8.061-1.581-8.992-5.625z"
                              fill="#cc0053"
                            ></path>
                          </g>
                          <g>
                            <path
                              d="m180.317 271.515c-.93-4.036 1.589-8.063 5.625-8.992l42.304-9.744c4.034-.934 8.063 1.588 8.992 5.625.93 4.036-1.589 8.063-5.625 8.992l-42.304 9.744c-4.026.93-8.061-1.581-8.992-5.625z"
                              fill="#cc0053"
                            ></path>
                          </g>
                          <path
                            d="m223.769 448.205c-68.117-7.677-46.915-77.801-46.915-77.804-.512.372-1.042.723-1.602 1.03l-104.041 56.431c21.854 25.084 48.729 45.675 79.063 60.208z"
                            fill="#fd995b"
                          ></path>
                        </g>
                      </svg>
                    </span>
                    <span>Disclosures</span>
                  </h4>
                  <h6 className="fw-bold mt-2">
                    SCDND Estates Private Limited
                  </h6>
                  <p className="text-justify mt-2 text-sm leading-relaxed ">
                    <div className="" type="1">
                      <p className="flex items-start gap-1.5 mt-1.5">
                        <span className="mt-1">
                          <TbPointFilled />
                        </span>
                        SCDND Estates Private Limited (hereinafter referred to
                        as “the Company”) is a financial technology company
                        based in Kolkata incorporated 2016. The company builds
                        financial technology platforms and provides financial
                        technology services to its clients.
                      </p>
                      <p className="flex items-start gap-1.5 mt-1.5">
                        <span className="mt-1">
                          <TbPointFilled />
                        </span>
                        A Decentralized Asset is an actively managed portfolio
                        of stocks, exchange-traded funds, REITs, Non-convertible
                        Debentures, Private Equity, Derivatives thereof and
                        other domestic and global securities (listed or
                        unlisted) that are curated to track a predefined
                        real-world economic statistics or data-survey based
                        index.
                      </p>
                      <p className="flex items-start gap-1.5 mt-1.5">
                        <span className="mt-1">
                          <TbPointFilled />
                        </span>
                        SCDND Estates is registered under Companies act 2013,
                        with its Objectives to operate in the asset management
                        sector. The Company bridges the gap between investors
                        and their money by removing intermediaries and
                        price-setters, through the use of Blockchain technology
                        and collateral-backed guarantee.
                      </p>
                      <p className="flex items-start gap-1.5 mt-1.5">
                        <span className="mt-1">
                          <TbPointFilled />
                        </span>
                        All returns and ratios displayed on the mobile and web
                        applications of the company (hereinafter referred to as
                        “Product”) are only for informational purposes and are
                        to help investors make an informed decision. They are
                        tools to communicate factual and verifiable return
                        information and should not be seen as advertisement or
                        promotion.
                      </p>
                      <p className="flex items-start gap-1.5 mt-1.5">
                        <span className="mt-1">
                          <TbPointFilled />
                        </span>
                        Unless otherwise stated, the percentage returns
                        displayed on the website for calculating product
                        performance are normalized on Rs. 1 lakhs or multiples
                        thereof.
                      </p>
                      <p className="flex items-start gap-1.5 mt-1.5">
                        <span className="mt-1">
                          <TbPointFilled />
                        </span>
                        The content and data available on scdndestates.com,
                        including but not limited to SCDND Estates Product
                        Performance, SCDND Estates Custodian account, SCDND
                        Estates Key ratio, SCDND Estates shareholdings, SCDND
                        Estates annual report are for information and
                        illustration purposes only. No content on our website or
                        application, including any posts, pictures or any
                        percentage returns should be considered an
                        advertisement, endorsement, solicitation, financial
                        advice, or offer to sell any products or services of the
                        Company or of any other third parties.
                      </p>
                      <p className="flex items-start gap-1.5 mt-1.5">
                        <span className="mt-1">
                          <TbPointFilled />
                        </span>
                        Charts and performance numbers on the platform do not
                        include any back tested data. Please refer to
                        the Product performance and Risk sensor to check how
                        returns are calculated on the platform. Data used for
                        the calculation of live returns and other information is
                        provided by exchange-approved third-party data vendors
                        and has neither been audited nor validated by the
                        Company.
                      </p>
                      <p className="flex items-start gap-1.5 mt-1.5">
                        <span className="mt-1">
                          <TbPointFilled />
                        </span>
                        SCDND Estates are not licensed to any exchange, trading
                        or settling venue outside India.
                      </p>
                      <p className="flex items-start gap-1.5 mt-1.5">
                        <span className="mt-1">
                          <TbPointFilled />
                        </span>
                        Prices shown on the scdndestates.com may be delayed. The
                        prices are sourced from an Regulatory authorities and
                        ministry of statistics published data, or from
                        equivalent institutions of other countries. The
                        references are detailed on the pages themselves. The
                        Company is not responsible for the accuracy and
                        correctness of the prices.
                      </p>
                      <p className="flex items-start gap-1.5 mt-1.5">
                        <span className="mt-1">
                          <TbPointFilled />
                        </span>
                        All information present on scdndestates.com is to help
                        investors in their decision-making process and shall not
                        be considered as a recommendation or solicitation of an
                        investment or investment strategy. Investors are
                        responsible for their investment decisions and are
                        responsible to validate all the information used to make
                        the investment decision. Investors should understand
                        that his/her investment decision is based on personal
                        investment needs and risk tolerance, and performance
                        information available on smallcase.com is one amongst
                        many other things that should be considered while making
                        an investment decision. Past performance does not
                        guarantee future returns and the performance of SCDND
                        Estates are subject to market risk. Investments in the
                        securities market are subject to market risks and
                        investors should read all the related documents
                        carefully before investing.
                      </p>
                      <p className="flex items-start gap-1.5 mt-1.5">
                        <span className="mt-1">
                          <TbPointFilled />
                        </span>
                        The Company ensures that all data, images, or other
                        material sourced from non-public channels are acquired
                        through appropriate licensing agreements with the
                        rightful owners. We guarantee that no third-party
                        trademarks or intellectual property rights have been
                        infringed upon.
                      </p>
                      <p className="flex items-start gap-1.5 mt-1.5">
                        <span className="mt-1">
                          <TbPointFilled />
                        </span>
                        While the Company endeavours to provide a seamless user
                        experience and adhere to Know Your Customer (KYC)
                        standards by integrating third-party APIs, we do not
                        assume responsibility for the integrity, security, or
                        reliability of these external services. The Company
                        reserves the right to modify APIs and service providers
                        without prior notification to users.
                      </p>
                      <p className="flex items-start gap-1.5 mt-1.5">
                        <span className="mt-1">
                          <TbPointFilled />
                        </span>
                        The Company and its representatives disclaim liability
                        for any instances of data breaches, server compromises,
                        system crashes, or other malfunctions attributed to the
                        cloud computing resources utilized for hosting and
                        database purposes.
                      </p>
                      <p className="flex items-start gap-1.5 mt-1.5">
                        <span className="mt-1">
                          <TbPointFilled />
                        </span>
                        An investment in securities is speculative in nature and
                        involves a high degree of risk, including the risk of
                        loss of capital. Any such investment is suitable only
                        for sophisticated investors who are familiar with, fully
                        comprehend and are willing to accept high risk, and have
                        carefully read the information memorandum. No assurance
                        is given by the Company that users of this website will
                        achieve their investment objectives or will receive a
                        return of their capital
                      </p>
                      <p className="flex items-start gap-1.5 mt-1.5">
                        <span className="mt-1">
                          <TbPointFilled />
                        </span>
                        Registered office of SCDND Estates Private Limited –{" "}
                        <br />
                        Flat 1/1A CLUBTOWN GREENS <br />
                         167 JESSORE ROAD <br /> KOLKATA – 700055
                      </p>
                      <p className="flex items-start gap-1.5 mt-1.5">
                        <span className="mt-1">
                          <TbPointFilled />
                        </span>
                        CIN: U47912DL2023PTC423725
                      </p>
                      <p className="flex flex-wrap items-center gap-1.5 mt-1.5">
                        <span className="mt-1">
                          <TbPointFilled />
                        </span>
                        <span>Support-Contact us at</span>
                        <a
                          href="mailto:helpdesk@scdndestates.com"
                          className="text-blue-700 font-semibold"
                        >
                          helpdesk@scdndestates.com
                        </a>
                        <span>
                          for any queries and assistance. All grievances should
                          be addressed to our grievance team at
                        </span>
                        <a
                          href="mailto:compliance@scdndesttes.com"
                          className="text-blue-700 font-semibold"
                        >
                          compliance@scdndesttes.com
                        </a>
                      </p>

                      <p className="flex items-start gap-1.5 mt-1.5">
                        <span className="mt-1">
                          <TbPointFilled />
                        </span>
                        The information and opinions contained herein have been
                        compiled or arrived at, based upon information obtained
                        in good faith from sources believed to be reliable. Such
                        information has not been independently verified and no
                        guarantee, representation of warranty, express or
                        implied, is made as to its accuracy, completeness or
                        correctness. All such information and opinions are
                        subject to change without notice
                      </p>
                      <p className="flex items-start gap-1.5 mt-1.5">
                        <span className="mt-1">
                          <TbPointFilled />
                        </span>
                        The Company does not endorse or promote any of the
                        opportunities that appear on this website nor makes any
                        recommendations regarding the same to any user of the
                        website. Listing of details of various assets/securities
                        may be arranged/referred and/or owned by the Company.
                      </p>
                    </div>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Disclosures;
