import React, { useState } from "react";
import { BiSkipNext, BiSkipPrevious } from "react-icons/bi";
import CheckAuth from "../../components/ServiceFunctions/CheckAuth";
import secureLocalStorage from "react-secure-storage";
import { MdPrint } from "react-icons/md";
import { FaFilePdf } from "react-icons/fa";

function ResidexHistory() {
  const { pan } = CheckAuth();
  const storedData = secureLocalStorage.getItem("couponData");
  const couponData = JSON.parse(storedData);

  // Extract and sort date keys
  const sortedDates = Object.keys(couponData)
    .filter((key) => key !== "pan")
    .sort((a, b) => new Date(b) - new Date(a));

  // Create a new sorted object
  const sortedCouponData = { pan: couponData.pan };
  sortedDates.forEach((date) => {
    sortedCouponData[date] = couponData[date];
  });

  // Extract managementfee entries
  const managementFeeEntries = [];
  for (const date in sortedCouponData) {
    if (
      sortedCouponData[date].RESIDEX &&
      sortedCouponData[date].RESIDEX.Management !== undefined
    ) {
      managementFeeEntries.push({
        date: date,
        amount: (sortedCouponData[date].RESIDEX.Management * 4 * 1000) / 5,
        fee: sortedCouponData[date].RESIDEX.Management,
        gst: sortedCouponData[date].RESIDEX.Management * 0.18,
      });
    }
  }

  const [selectedRow, setSelectedRow] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const handleSelectRow = (row) => {
    setSelectedRow(row);
  };

  const itemsPerPage = 5;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentTransactions = managementFeeEntries.slice(startIndex, endIndex);

  const handleNextPage = () => {
    const totalPages = Math.ceil(managementFeeEntries.length / itemsPerPage);
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handlePrint = () => {
    const allTransactions = managementFeeEntries.map((row) => ({
      ...row,
      amount: row.amount.toLocaleString("en-IN"),
      fee: row.fee.toLocaleString("en-IN"),
      gst: row.gst.toLocaleString("en-IN"),
    }));

    // Create a hidden iframe to print the table
    const iframe = document.createElement("iframe");
    iframe.style.position = "absolute";
    iframe.style.width = "0";
    iframe.style.height = "0";
    iframe.style.border = "none";
    document.body.appendChild(iframe);

    const content = `
      <html>
        <head>
          <style>
            @media print {
              .no-print {
                display: none;
              }
            }
          </style>
        </head>
        <body>
        <h5 className="fw-bold text-xl">RESIDEX Fee History</h5>
          <table border="1">
            <thead>
              <tr>
                <th>Period</th>
                <th>Amount (&#x20B9;)</th>
                <th>Fee (&#x20B9;)</th>
                <th>GST (&#x20B9;)</th>
              </tr>
            </thead>
            <tbody>
              ${allTransactions
                .map(
                  (row) => `
                    <tr>
                      <td>${row.date}</td>
                      <td>${row.amount}</td>
                      <td>${row.fee}</td>
                      <td>${row.gst}</td>
                    </tr>
                  `
                )
                .join("")}
            </tbody>
          </table>
          <script>
            window.print();
            setTimeout(() => {
              window.close();
            }, 0);
          </script>
        </body>
      </html>
    `;

    iframe.contentDocument.open();
    iframe.contentDocument.write(content);
    iframe.contentDocument.close();
  };

  const handleDownload = (date) => {
    fetch(`https://api-scdndestates.com/api/download-invoices/`, {
      method: "POST",
      body: JSON.stringify({
        pan: pan,
        product: "RESIDEX",
        date: date,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        return response.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "invoice_" + date + ".pdf";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        // console.error("Error: ", error);
      });
  };

  return (
    <div className="mt-3 border shadow-md rounded-lg md:rounded-xl p-3 h-[350px] overflow-y-scroll">
      <div className="flex flex-wrap justify-between items-center gap-2.5">
        <h5 className="fw-bold text-base 2xl:text-lg text-black">
          RESIDEX Fee History
        </h5>
        <div className="flex justify-between items-center w-full gap-4 md:w-fit">
          <div
            className="bg-[#3B71CA] rounded-full px-2.5 py-0.5 text-xs text-white flex items-center gap-1 w-fit cursor-pointer hover:scale-95 duration-200 no-print"
            onClick={handlePrint}
          >
            <MdPrint />
            <p className="font-semibold">Print</p>
          </div>
          <div className="d-flex justify-content-center align-items-center gap-2.5">
            <div
              className="btn btn-sm p-0 btn-secondary"
              onClick={handlePrevPage}
            >
              <BiSkipPrevious size={20} />
            </div>
            <div className="text-sm">Page {currentPage}</div>
            <div
              className="btn p-0 btn-sm btn-secondary"
              onClick={handleNextPage}
            >
              <BiSkipNext size={20} />
            </div>
          </div>
        </div>
      </div>
      <div className="row overflow-scroll px-2">
        <table className="w-full table-responsive my-3 table-borderless table-sm border-dark min-w-[500px]">
          <thead className="">
            <tr className="text-light bg-[#3B71CA] text-xs">
              <th scope="col" className="w-1/5 fw-bolder text-center">
                Period
              </th>
              <th scope="col" className="w-1/5 fw-bolder text-center">
                Amount (&#x20B9;)
              </th>
              <th scope="col" className="w-1/5 fw-bolder text-center">
                Fee (&#x20B9;)
              </th>
              <th scope="col" className="w-1/5 fw-bolder text-center">
                GST (&#x20B9;)
              </th>
              <th scope="col" className="w-1/5 fw-bolder text-center">
                Invoice
              </th>
            </tr>
          </thead>
          <tbody>
            {currentTransactions.map((row, index) => (
              <tr
                key={index}
                className={` ${
                  selectedRow === row ? "selected" : ""
                } shadow-md my-2 font-semibold border-2 border-white Residex-row-style text-xs hover:bg-zinc-100 duration-200`}
                onClick={() => handleSelectRow(row)}
              >
                <td className="w-1/5 text-center ">{row.date}</td>
                <td className="w-1/5 text-center ">
                  {Number(row.amount.toFixed(0)).toLocaleString("en-IN", {
                    minimumFractionDigits: 0,
                  })}
                </td>
                <td className="w-1/5 text-center ">
                  {Number(row.fee.toFixed(2)).toLocaleString("en-IN", {
                    minimumFractionDigits: 2,
                  })}
                </td>
                <td className="w-1/5 text-center ">
                  {Number(row.gst.toFixed(2)).toLocaleString("en-IN", {
                    minimumFractionDigits: 2,
                  })}
                </td>
                <td className="flex text-sm mx-auto text-[#3B71CA] cursor-pointer justify-center">
                  <FaFilePdf
                    size={18}
                    onClick={() => handleDownload(row.date)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ResidexHistory;
