import React from "react";
import Header from "../../components/Header/Header";
import { TbPointFilled } from "react-icons/tb";

function AssetManagement() {
  return (
    <>
      <Header />
      <div className="after-header pb-5 max-w-[1800px] mx-auto">
        <div className="w-[90%] mx-auto">
          <div className="w-full flex flex-wrap gap-4 justify-start mt-4 ">
            <div className="justify-center border rounded-lg shadow-md w-full p-[1%]">
              <div className="col-12">
                <div className="row justify-content-center p-3 text-black">
                  <h4 className="text-center fw-bold mb-2 text-xl md:text-2xl flex items-center justify-center gap-2">
                    <span>
                      <svg
                        id="fi_9539743"
                        enableBackground="new 0 0 512 512"
                        height="512"
                        viewBox="0 0 512 512"
                        width="512"
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-9 h-fit"
                      >
                        <g clipRule="evenodd" fillRule="evenodd">
                          <path
                            d="m385.656 83.279c-46.811-13.465-90.623-34.411-129.656-67.279-39.033 32.868-82.845 53.814-129.656 67.279 0 104.397 45.687 193.088 125.092 257.711 79.405-64.623 134.22-153.314 134.22-257.711z"
                            fill="#c90a4f"
                          ></path>
                          <circle
                            cx="256"
                            cy="150.478"
                            fill="#fcb500"
                            r="69.553"
                          ></circle>
                          <path
                            d="m224.414 152.728c-1.823-2.06-1.63-5.208.43-7.031s5.208-1.63 7.031.43l12.99 14.666 35.691-31.408c2.071-1.823 5.228-1.622 7.051.449s1.622 5.228-.449 7.051l-39.419 34.688-.01-.011c-2.06 1.813-5.201 1.619-7.021-.438z"
                            fill="#397399"
                          ></path>
                          <g>
                            <path
                              d="m125.596 448.751h99.912c1.209-26.597 3.695-49.657-2.75-66.913-21.167-46.915-51.571-74.348-101.115-88.138-15.896-5.729-28.145-31.223-28.579-38.176-7.505-58.317-10.314-80.39-10.6-82.847l-2.452-13.249c-3.101-9.644-6.858-13.443-7.351-13.848-4.529-5.627-13.735-8.773-17.54-8.158-3.028.754-5.083 1.436-7.782 5.294-4.003 4.899-3.128 21.824-3.093 23.488 2.121 41.439 4.176 63.118 4.354 71.267 2.72 56.023 8.994 77.19 12.833 92.12 4.041 11.263 11.004 20.306 20.962 29.244 9.837 8.554 34.899 28.605 37.186 31.746 7.994 10.476 6.654 53.615 6.015 58.17z"
                              fill="#efc89f"
                            ></path>
                            <path
                              d="m109.548 440.086h120.078c6.05 0 10.999 4.95 10.999 10.999v33.915c0 6.05-4.95 10.999-10.999 10.999h-120.078c-6.05 0-10.999-4.949-10.999-10.999v-33.915c0-6.049 4.949-10.999 10.999-10.999z"
                              fill="#009ac3"
                            ></path>
                          </g>
                          <g>
                            <path
                              d="m386.404 448.751c-65.997 0-99.3 0-99.912 0-1.209-26.597-3.695-49.657 2.75-66.913 21.167-46.915 51.571-74.348 101.115-88.138 15.896-5.729 28.145-31.223 28.579-38.176 7.505-58.317 10.314-80.39 10.6-82.847l2.452-13.249c3.101-9.644 6.858-13.443 7.35-13.848 4.529-5.627 13.735-8.773 17.54-8.158 3.028.754 5.082 1.436 7.782 5.294 4.003 4.899 3.128 21.824 3.094 23.488-2.122 41.439-4.176 63.118-4.354 71.267-2.721 56.023-8.994 77.19-12.833 92.12-4.041 11.263-11.004 20.306-20.962 29.244-9.836 8.554-34.899 28.605-37.186 31.746-7.994 10.476-6.654 53.615-6.015 58.17z"
                              fill="#efc89f"
                            ></path>
                            <path
                              d="m402.452 440.086h-120.078c-6.05 0-10.999 4.95-10.999 10.999v33.915c0 6.05 4.95 10.999 10.999 10.999h120.078c6.05 0 10.999-4.949 10.999-10.999v-33.915c0-6.049-4.949-10.999-10.999-10.999z"
                              fill="#009ac3"
                            ></path>
                          </g>
                        </g>
                      </svg>
                    </span>
                    <span>Asset Management Risk</span>
                  </h4>
                  <h6 className="fw-bolder px-2 pt-2 text-base">
                    General Risk
                  </h6>
                  <p className="m-0 text-sm px-2 leading-relaxed">
                    Investment in Decentralized Assets is risky, and investors
                    should not invest any funds in Decentralized assets unless
                    they can afford to take the risk attached to such
                    investments. Investors are advised to take an informed
                    decision and to read the risk factors carefully before
                    investing in this offering. For taking an investment
                    decision, investors must rely on their examination of the
                    Issue including the risks involved in it. <br />
                    These risks are not, and are not intended to be, a complete
                    list of all risks and considerations relevant to the
                    Decentralized assets or Investor’s decision to purchase such
                    securities. <br />
                    By giving your consent through our Platform or Services, you
                    agree to the terms of this Risk involved Policy and you
                    expressly give your voluntary consent to Continue to use and
                    disclosure of the Personal Information in accordance with
                    this Business Model.
                  </p>
                  <h6 className="fw-bolder px-2 pt-2 text-base">
                    RISK FACTORS
                  </h6>
                  <p className="m-0 text-sm px-2 leading-relaxed">
                    An investment in this type of security involves a certain
                    degree of risk. The investor should carefully consider all
                    the information contained in this Financial Risk Disclosure
                    Form, including the risks and uncertainties described below,
                    before making an investment decision. The risk factors set
                    forth below do not purport to be complete or comprehensive
                    in terms of all the risks that may arise in connection with
                    our business or any decision to purchase, own or dispose of
                    the Decentralized assets. Additional risks and uncertainties
                    not presently known to us or that we currently believe to be
                    immaterial may also have an adverse impact on our business,
                    results of operations, cash flows and financial condition.
                    The market prices of the Decentralized assets could decline
                    due to such risks and you may lose all or part of your
                    investment. The financial and other related implications of
                    the risks described in this section, have been disclosed to
                    the extent quantifiable as on the date of this FORM. It also
                    contains forward-looking statements that involve risks and
                    uncertainties. Our results could differ materially from
                    those anticipated in these forward-looking statements as a
                    result of certain factors, including events described below.{" "}
                    <br />
                    Unless otherwise stated or unless context requires
                    otherwise, the financial information used in this section is
                    derived from and should be read in conjunction with the
                    Annual Financial Statements.
                  </p>
                  <h6 className="fw-bolder px-2 pt-2 text-base">
                    INTERNAL RISKS
                  </h6>

                  <p className="m-0 text-sm px-2 leading-relaxed">
                    <ol type="1" className="text-sm mt-2">
                      <li>
                        <span className="fw-bold">
                          We require substantial capital for our business and
                          any disruption in our sources of capital or an
                          increase in our average cost of borrowings could have
                          an adverse effect on our business, results of
                          operations and financial condition.
                        </span>
                        <br />
                        Our business&#39;s liquidity and profitability are
                        significantly dependent on timely access to financing
                        and associated costs. Historically, we&#39;ve sourced
                        funding from various channels, including term loans,
                        external commercial borrowings, and working capital
                        facilities. As of March 31, 2023, and for the Financial
                        Years ending 2023, 2022, and 2021, our Current Ratio key
                        ratios, Debt-Equity Ratio, Debt Service Coverage Ratio,
                        Return on Capital employed ratio stood at -15.57%, -
                        43.36%, -103.98 %, -34.90%, -56.34, 176.81%, -34.90,
                        -56.34 and 176.81% respectively. Additionally, our total
                        borrowing as of March 31, 2023, and for the Financial
                        Years ending 2023, 2022, and 2021, amounted to
                        ₹6,32,92,250 crore, ₹6,42,25,000 crore and ₹6,04,90,000
                        crore respectively. We anticipate potential future
                        capital requirements, which may vary due to economic
                        conditions, unforeseen expenses, regulatory changes, or
                        operational delays. In instances where our capital needs
                        surpass available resources, we may seek additional debt
                        or equity capital, subjecting us to increased interest
                        costs and compliance obligations. Our ability to secure
                        funds on favourable terms and in a timely manner hinges
                        on multiple factors, including financial performance,
                        credit ratings, regulatory environments, and market
                        liquidity. Furthermore, ongoing financing requirements
                        may impose constraints on our flexibility and ability to
                        pursue growth initiatives. We cannot guarantee timely or
                        favourable financing, which may adversely affect our
                        business, financial condition, and results of
                        operations.
                      </li>
                      <br />
                      <li>
                        <span className="fw-bold">
                          The financing industry is becoming increasingly
                          competitive, which creates significant pricing
                          pressures for us to retain existing customers and
                          solicit new business. Our growth will depend on our
                          ability to compete effectively in this environment.
                        </span>
                        <br />
                        Our business operates in a highly competitive market and
                        we face significant competition from other players in
                        the financing industry. Many of our competitors are
                        large institutions, which may have a larger customer
                        base, funding sources, branch networks and capital base
                        compared to us. Some of our competitors may be more
                        flexible and better positioned to take advantage of
                        market opportunities. This competition is likely to
                        further intensify as a result of securitization and
                        regulatory changes. Our future success will depend, to a
                        large extent, on our ability to respond in a timely and
                        effective manner to these competitive pressures. <br />
                        Our credit business competes based on a number of
                        factors, including cost effective sources of funding,
                        successful implementation of new technologies and
                        securitization branches to manage operational costs.
                        Loans are becoming increasingly securitization and terms
                        such as variable (or floating) rate interest options,
                        lower processing fees and monthly reset periods are
                        becoming increasingly common in the Indian financial
                        sector, thereby increasing competition. <br />
                        Our insurance business competes for business based on
                        various factors, including product features, price,
                        coverage offered, quality of customer service,
                        distribution network, relationships with agents,
                        bancassurance partners and other intermediaries, brand
                        recognition, size of operations, financial strength and
                        credit ratings. In addition, life insurance products
                        also compete with certain other financial services
                        products which may not be exactly comparable but provide
                        slightly different alternatives to the customers. For
                        example, in the area of savings- oriented insurance
                        products, we compete with mutual fund companies, bank
                        fixed deposits and Government small saving schemes. Some
                        of our competitors may offer higher commissions or more
                        attractive rewards to agents and other distribution
                        intermediaries or offer similar insurance products at
                        lower pricing. If we are unable to effectively respond
                        to these various competitive pressures, it could result
                        in a decrease of market share, decrease in our margins
                        and spreads, reduced customer base, increase in pricing
                        of our products, increase in operating expenses, as well
                        as higher attrition rates among management and sales
                        staff, lower growth rates, or even losses, which could
                        have material adverse effect on our business, results of
                        operations, cash flows and financial condition.
                      </li>
                      <br />
                      <li>
                        <span className="fw-bold">
                          We have grown in the past but there can be no
                          assurance that our growth will continue at a similar
                          rate or that we will be able to manage our rapid
                          growth. If we are unable to implement or sustain our
                          growth strategy effectively it could adversely affect
                          our business, results of operations, cash flows and
                          financial condition.
                        </span>
                        <br />
                        Our consolidated profit for the year/ period
                        attributable to owners was ₹ 9,88,332 for the Fiscal
                        2023. If we are unable to implement or sustain our
                        growth strategy effectively it could adversely affect
                        our business, results of operations, cash flows and
                        financial condition.
                        <br />
                        Our growth strategy involves expanding our HNI,
                        corporate and institutional asset management,
                        market-making of hybrid securities, investment advisory,
                        and blockchain-based trading vertical. Diversifying
                        product portfolios to meet customer needs and scaling
                        our businesses is a key component. This strategy will
                        strain management, financial, and other resources,
                        necessitating continuous improvement in operational,
                        financial, and internal controls. Expansion presents
                        challenges in financial management, human resource
                        recruitment and retention, cultural preservation, and
                        administrative infrastructure development. <br />
                        Operational risks accompany our growth strategy,
                        particularly during the transition to asset-light
                        growth, which may initially reduce our credit book but
                        ultimately increase assets under management. Rapid
                        credit book growth exposes us to various risks,
                        including business, operational, NPA, fraud, regulatory,
                        and legal risks. Serving a large retail client base
                        expose us to additional risks across various areas,
                        including business, regulatory, legal, fraud,
                        compliance, and reputation.
                        <br />
                        The recent consolidation within the insurance or
                        financial services industries may lead to emergence of
                        larger competitors with competitive advantages. We may
                        face challenges sustaining growth due to competitive
                        pressures or other factors. Additionally, new product
                        launches may not meet market expectations, potentially
                        affecting returns. Business models may not succeed, and
                        investments in technology and customer interfaces may
                        not yield desired results. <br />
                        Our growth relies on recruiting and retaining skilled
                        personnel, maintaining effective risk management
                        policies, offering relevant products, and providing
                        high-quality client service. Failure to train employees
                        properly could lead to increased attrition, erosion of
                        service quality, and higher costs. Rapid credit book
                        growth without proper risk assessment may increase non-
                        performing loans, impacting asset quality and financial
                        condition. <br />
                        Rapid growth places demand on operational, credit,
                        financial, and risk controls, including preserving asset
                        quality, improving products and delivery channels,
                        recruiting skilled personnel, upgrading technology,
                        complying with regulations, and maintaining customer
                        satisfaction. Failure to implement these measures may
                        hinder expansion and adversely affect performance and
                        financial results
                      </li>{" "}
                      <br />
                      <li>
                        <span className="fw-bold">
                          Difficult conditions in financial markets can
                          adversely affect our business, which could materially
                          reduce our revenue and income.
                        </span>
                        <br />
                        Our financial services firm is highly susceptible to
                        fluctuations in both domestic and global financial
                        markets, as well as economic conditions in India. Any
                        prolonged or significant downturn or extreme volatility
                        in Indian capital markets could lead to decreased
                        revenue from our products and services, along with
                        reduced net investment income and fund management fees.
                        Past trading losses in various investments, including
                        equities, derivatives, fixed income securities, and
                        commodities, pose a risk to our invested capital and
                        could negatively impact our overall business,
                        operations, cash flows, and financial condition. <br />
                        Future market and economic conditions may worsen due to
                        factors beyond our control, such as rising interest
                        rates, inflation, terrorism, political uncertainty, or
                        adverse global/domestic events affecting liquidity and
                        investment inflows. Growth potential in recent ventures
                        like retail financing, insurance, and asset management
                        may be constrained during challenging market conditions.
                        Our capital business performance, including internal
                        liability management operations, may suffer due to
                        market and economic conditions. The NBFC industry&#39;s
                        liquidity issues since September 2018, triggered by the
                        collapse of a &#39;AAA rated&#39; entity, have escalated
                        borrowing costs, potentially exacerbating future
                        challenges. <br />
                        We cannot accurately quantify the impact of adverse
                        market conditions on our business and financial
                        condition. Quarterly operating results may fluctuate
                        significantly due to market volatility, rendering
                        period-to-period comparisons unreliable indicators of
                        future performance.
                      </li>
                      <br />
                      <li>
                        <span className="fw-bold">
                          High levels of defaults on our fixed-income assets and
                          the resultant non-performing assets could adversely
                          affect our business, financial condition, results of
                          operations and future financial performance.
                        </span>
                        <br />
                        Our investment business entails inherent risks
                        associated with defaults, including delays or failure to
                        repay principal and/or interest on loans and Debentures.
                        These risks stem from various factors, including
                        external influences beyond our control such as economic
                        developments, market fluctuations, regulatory changes,
                        and customer financial difficulties. Failure to manage
                        these risks effectively may lead to decreased revenue,
                        increased expenses, elevated delinquency rates, and
                        higher provisions for loan losses. Previous instances of
                        customer default have necessitated legal actions for
                        recovery. Default incidents may increase non-performing
                        assets (NPAs) and provisions, impacting our operations
                        and profitability. Although there have been improvements
                        in asset quality, there&#39;s no guarantee of sustained
                        trends. As our loan portfolio matures, default risks may
                        escalate. Our provisioning coverage ratio and recovery
                        rates may vary, affecting our financial performance.
                        Inadequate credit appraisal and monitoring processes
                        could further deteriorate asset quality, significantly
                        impacting our business, operations, and financial
                        health.
                      </li>{" "}
                      <br />
                      <li>
                        <span className="fw-bold">
                          We may not be able to recover the full value of
                          collateral or amounts sufficient to cover the
                          outstanding amounts due under defaulted loans on a
                          timely basis or at all, or the value of collateral may
                          decrease, which could adversely affect our results of
                          operations, cash flows and financial condition.
                        </span>
                        <br />
                        In instances of default, the debtor entity typically
                        undergoes a bankruptcy resolution process pursuant to
                        the Insolvency and Bankruptcy Code (IBC). Within this
                        framework, secured creditors possess collective
                        decision-making authority regarding the disposition of
                        the debtor company&#39;s assets, either through
                        liquidation or sale as a going concern to another
                        entity. Our company does not exert direct influence over
                        the determination of recovery amounts or the mechanism
                        for distribution.
                      </li>
                      <br />
                      <li>
                        <span className="fw-bold">
                          Any adverse development in the real estate sector
                          would negatively affect our results of operations.
                        </span>
                        <br />A substantial portion of our credit portfolio is
                        exposed to the real estate sector, through investments
                        in structured credit, mortgage bonds, asset-backed
                        securities collateralized by mortgages both commercial
                        and residential. <br />
                        We acknowledge that our mortgage-linked instruments are
                        directly linked to the health of India’s real estate
                        sector. Any significant downturn in property values may
                        impair our ability to recover collateral or fully recoup
                        principal and interest in case of default. Project
                        delays could further impact collateral value and our
                        enforcement capabilities. Failure to realize expected
                        collateral value may result in material adverse effects
                        on our business, operations, cash flows, and financial
                        health. <br />
                        While the SARFAESI Act enhances creditor rights by
                        expediting property foreclosure after a 60- day notice
                        period for non-performing loans, uncertainties persist
                        regarding full collateral realization, including
                        potential delays in enforcement actions, legal
                        proceedings, market downturns, collateral perfection
                        issues, and fraudulent borrower transfers. <br />
                        Additionally, we recognize the significant impact of the
                        Real Estate Regulation and Development Act, 2016 (RERA
                        Act) on the sector. RERA Act provisions, including
                        project registration, enhanced disclosures, and
                        penalties for non-compliance, are being progressively
                        implemented nationwide. Compliance may necessitate
                        increased resources and regulatory actions, potentially
                        impeding sector growth and adversely affecting our
                        business operations and financial position.
                      </li>{" "}
                      <br />
                      <li>
                        <span className="fw-bold">
                          SMEs to which we provide optionally convertible
                          debentures and preference shares (OCD and OCPS) may
                          not perform as expected and we may not be able to
                          control the non-performance of such businesses.
                        </span>
                        <br />
                        We extend loans to optionally convertible debentures and
                        preference shares (OCD and OCPS) and other business loan
                        portfolio. We do not manage, operate, or control these
                        businesses or entities and have no control over their
                        functions or operations. The decisions made by these
                        businesses&#39; management, majority shareholders, or
                        management teams may not align with our interests and
                        could lead to adverse outcomes. The repayment of loans
                        depends significantly on the borrower entity&#39;s
                        management team. Actions taken by our customers&#39;
                        management may result in significant losses, impacting
                        their ability to repay our loans and our financial
                        performance.&quot;
                      </li>{" "}
                      <br />
                      <li>
                        <span className="fw-bold">
                          We are exposed to fluctuations in the market values of
                          our investment and other asset portfolio.
                        </span>
                        <br />
                        Financial market instability can harm global economic
                        activity, including in India. Credit and capital market
                        deterioration may lead to volatility in investment
                        earnings and impairments to our asset portfolio,
                        including assets in our balance sheet management unit
                        crucial for liquidity. The value of investments is
                        subject to various uncontrollable factors, such as
                        domestic and international economic and political
                        conditions, inflation expectations, unforeseen events
                        like the COVID-19 pandemic, and RBI monetary policies.
                        Any investment value decline could adversely affect our
                        financial standing
                      </li>{" "}
                      <br />
                      <li>
                        <span className="fw-bold">
                          If the investments made by the funds we advise or
                          manage perform poorly we will suffer a decline in our
                          revenue and earnings, we may be obligated to repay
                          certain incentive fees we have previously received to
                          the third-party investors in such funds, and our
                          ability to raise capital for future funds may be
                          adversely affected.
                        </span>
                        <br />
                        Our revenue is generated from fees based on capital
                        committed to or drawn-down from funds, incentive fees,
                        carried interest, gains or losses on investments, and
                        transaction fees. Poor investment performance may reduce
                        our revenues and hinder future fund raising. Excess
                        carried interest may require repayment to investors.
                      </li>{" "}
                      <br />
                      <li>
                        <span className="fw-bold">
                          We face the threat of fraud and cyber-attacks, such as
                          hacking, phishing, trojans and other threats,
                          attempting to exploit our network to disrupt services
                          to customers and/or theft of sensitive internal
                          company data or customer information. This may cause
                          damage to our reputation and adversely impact our
                          business and financial results.
                        </span>
                        <br />
                        Our operational controls may not fully prevent adverse
                        impacts from cyber fraud, errors, hacking, and system
                        failures. Our mobile and internet-based customer
                        interfaces may be vulnerable to hacking or compromise by
                        third parties, leading to thefts and losses. Cyber
                        threats include phishing, hacking, data theft, and
                        persistent network attacks, with frequencies generally
                        increasing. Such threats could materially affect our
                        business, finances, and reputation. <br />
                        Significant system breakdowns or failures could harm
                        revenue and customer satisfaction. Despite having a
                        remote disaster recovery site, its failure or delayed
                        operation could disrupt our operations and damage our
                        reputation and finances. <br />
                        Customers might blame us for cyber incidents and
                        terminate their accounts, exposing us to regulatory
                        scrutiny and legal liability. Our use of cloud
                        environments poses additional security risks, as cloud
                        providers&#39; failures to protect our data could harm
                        our business, finances and reputation.
                      </li>{" "}
                      <br />
                      <li>
                        <span className="fw-bold">
                          We may experience difficulties in expanding our
                          business, organically and inorganically, into new
                          regions and markets in India and overseas.
                        </span>{" "}
                        <br />
                        We are actively exploring growth opportunities to expand
                        our business both domestically and internationally,
                        including through acquisitions, mergers, joint ventures,
                        and strategic investments. However, there are no
                        guarantees regarding our ability to identify suitable
                        targets, secure financing, or ensure the profitability
                        of such ventures. Integration challenges, regulatory
                        differences, and competition in new markets may pose
                        risks to our expansion efforts. Failure to effectively
                        manage these challenges could have adverse effects on
                        our business prospects, financial performance, and
                        overall condition.
                      </li>{" "}
                      <br />
                      <li>
                        <span className="fw-bold">
                          Changes to laws and regulations across the multiple
                          jurisdictions we operate in, may materially and
                          adversely affect our business and financial
                          performance.
                        </span>{" "}
                        <br />
                        Changes in laws, regulations, or interpretations
                        thereof, both domestically and internationally, may
                        significantly impact our business and financial
                        performance. New regulations could necessitate
                        additional approvals, licenses, or compliance measures,
                        potentially imposing burdensome conditions on our
                        operations. Uncertainties surrounding the implementation
                        of these changes may further exacerbate adverse effects
                        on our business, including but not limited to, results
                        of operations, cash flows, and financial condition.
                      </li>{" "}
                      <br />
                      <li>
                        <span className="fw-bold">
                          Certain Directors hold Equity Shares in the Issuer and
                          are therefore interested in the Issuer’s performance
                          in addition to their remuneration and reimbursement of
                          expenses.
                        </span>{" "}
                        <br />
                        Certain Directors have vested interests in the Issuer
                        beyond standard compensation and expense reimbursement,
                        tied to their shareholding in the Issuer. Consequently,
                        these Directors wield considerable influence,
                        potentially determining outcomes of shareholder voting,
                        whether requiring simple or special majority. Such
                        control may enable these Directors to pursue actions
                        that diverge from the Issuer&#39;s or minority
                        shareholders&#39; best interests, creating potential
                        conflicts of interest.
                      </li>{" "}
                      <br />
                      <li>
                        <span className="fw-bold">
                          We may not be able to manage the growth of our
                          business effectively or continue to grow our business
                          at a similar rate to what we have experienced in the
                          past.
                        </span>{" "}
                        <br />
                        Despite significant revenue growth, we may encounter
                        challenges in effectively managing this expansion and
                        achieving desired profitability within anticipated
                        timelines. Failure to do so could adversely affect our
                        business and operational results. Managing relationships
                        with a larger customer base, service providers, lenders,
                        and other stakeholders will be crucial as we expand. To
                        handle growth, timely implementation and enhancement of
                        operational systems, processes, and controls are
                        imperative. Failure to do so may impact customer
                        satisfaction, hinder recruitment efforts, and impede
                        business operations. Our growth sustainability hinges on
                        retaining key management personnel, effective risk
                        management, and managerial training to address emerging
                        challenges. External factors, including economic
                        conditions, regulatory policies, inflation, and
                        competition, also influence our growth trajectory. We
                        cannot guarantee the adequacy of our existing or future
                        management, operational, and financial systems to
                        support future operations or foster beneficial business
                        relationships.
                      </li>{" "}
                      <br />
                      <li>
                        <span className="fw-bold">
                          We require certain statutory and regulatory approvals
                          and licenses for conducting our business. Failure to
                          obtain or renew approvals, licenses, registrations and
                          permits to operate our business in a timely manner, or
                          at all, may adversely affect our business, financial
                          condition, results of operations and cash flows.
                        </span>
                        &nbsp;We are obligated to secure and uphold various
                        approvals and licenses from governmental and regulatory
                        bodies, including the RBI and SEBI, for our business
                        operations. However, we cannot guarantee that these
                        approvals will not be subject to suspension or
                        revocation due to non-compliance or alleged
                        non-compliance. Additionally, there is no assurance of
                        timely approval or renewal by the relevant authorities.
                        Failure to obtain or renew such approvals may result in
                        non-compliance with applicable laws and regulatory
                        action.
                      </li>{" "}
                      <br />
                      <h6 className="fw-bolder pt-2 text-base">
                        EXTERNAL RISKS
                      </h6>
                      <h6 className="fw-bolder pt-2 text-base">
                        Risks Relating to India
                      </h6>
                      <br />
                      <li>
                        <span className="fw-bold">
                          Political, economic or other factors that are beyond
                          our control may have an adverse effect on our business
                          and results of operations.
                        </span>
                        <br />
                        The performance and growth of our business are
                        contingent upon prevailing economic, political, and
                        market conditions, both domestically and globally.
                        Situated solely in India with all assets and employees
                        located therein, our operations are inherently
                        influenced by economic fluctuations within the country.
                        Various external factors pose risks to our business,
                        including but not limited to:
                        <ul>
                          <li>
                            Interest rate hikes, potentially limiting capital
                            access and increasing borrowing costs, thereby
                            constraining our growth and profitability.
                          </li>
                          <li>
                            Political instability arising from governmental or
                            economic policy changes, potentially impacting
                            economic conditions and leading to social unrest.
                          </li>
                          <li>
                            Changes in government or deregulation policies may
                            adversely affect economic conditions and our
                            specific business operations.
                          </li>
                          <li>
                            Civil unrest, terrorist activities, regional
                            conflicts, or natural disasters could disrupt
                            operations.
                          </li>
                          <li>
                            Regulatory or economic developments within or
                            affecting India&#39;s financial services sectors.
                          </li>
                          <li>
                            Contagious diseases like COVID-19 and other
                            influenza strains could disrupt economic activity.
                          </li>
                        </ul>
                        Any slowdown in the Indian economy or specific sectors
                        thereof may negatively affect our business, financial
                        condition, and results of operations. Our performance is
                        intricately linked to the overall economic performance
                        of India and the regional markets in which we operate.
                      </li>
                      <br />
                      <li>
                        <span className="fw-bold">
                          If the rate of inflation in India increases, our
                          financial condition and results of operations may be
                          adversely affected.
                        </span>{" "}
                        <br />
                        Inflation volatility poses a risk to our business,
                        particularly in India where historically high inflation
                        has led to increased interest rates and operating costs,
                        including finance costs and expenses such as salaries.
                        This could also slow down economic growth and impact
                        credit expansion, potentially hampering our business
                        growth and profitability. Difficulty in cost estimation
                        and control may arise due to inflation fluctuations,
                        potentially leading to increased operating expenses that
                        cannot be fully passed on to customers, thereby
                        adversely affecting our financial condition. Although
                        past government measures have aimed to mitigate high
                        inflation, future trends remain uncertain, with the
                        possibility of adverse effects on our financial
                        condition and operational results.
                      </li>
                      <br />
                    </ol>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AssetManagement;
