import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ReactApexChart from "react-apexcharts";
import { FaArrowCircleRight } from "react-icons/fa";
import CheckAuth from "../../components/ServiceFunctions/CheckAuth.jsx";
import "./Ownership.css";
import Header from "../../components/Header/Header.jsx";
import secureLocalStorage from "react-secure-storage";
import { TbPointFilled } from "react-icons/tb";

function RESIDEXHoldings() {
  const { pan } = CheckAuth();
  const [citiesData1, setCitiesData1] = useState([]);
  const [cities, setCities] = useState([]);
  const [citiesData2, setCitiesData2] = useState([]);
  const [category, setCategory] = useState([]);
  const [dataFetched, setDataFetched] = useState(false);
  const navigate = useNavigate();

  const handleClick = (portal, pro) => {
    if (portal === "PMY") {
      navigate("/PrimaryMarket/residex", {
        state: {
          pro: `RESIDEX City ${pro}`,
        },
      });
    } else {
      navigate("/TradingPortal", { state: { pro: pro } });
    }
  };

  useEffect(() => {
    fetch(`https://api-scdndestates.com/api/primary-bid-box/`, {
      method: "POST",
      body: JSON.stringify({}),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        return response.json();
      })
      .then((data) => {
        setCities(data.data2 || []);
        const cityData1 = [];
        const cityData2 = [];

        cities.forEach((cityObj) => {
          const [city, value] = Object.entries(cityObj)[0];
          const data = ownershipData?.RESIDEX[city];
          if (data) {
            cityData1.push(data?.Index * value?.Price || 0);
            cityData2.push(
              data?.Futures * value?.["3 mth Premium"] * 1000 || 0
            );
          } else {
            cityData1.push(0);
            cityData2.push(0);
          }
        });
        const categories = cities.map((item, index) => Object.keys(item) || []);
        setCategory(categories);
        setCitiesData1(cityData1);
        setCitiesData2(cityData2);
        setDataFetched(true);
      })
      .catch((error) => {
        // console.error("Error: ", error);
      });
  }, [dataFetched]);

  const [tableContent, setTableContent] = useState(null);
  const storedData2 = secureLocalStorage.getItem("couponData");
  const couponData = JSON.parse(storedData2);
  const storedData1 = secureLocalStorage.getItem("kycData");
  const kycData = JSON.parse(storedData1);
  const storedData3 = secureLocalStorage.getItem("blockchainData");
  const blockchainData = JSON.parse(storedData3);
  const relationshipDate = kycData?.["Relationship_start"];
  const storedData = secureLocalStorage.getItem("ownershipData");
  const ownershipData = JSON.parse(storedData);

  function getHoldingPeriod(productName, category) {
    if (category === "Index") {
      var Days = [];
      var residex_units_calculated = [];
      const sortedCouponData = { pan: couponData?.pan };
      if (couponData) {
        const dateKeys = Object.keys(couponData).filter((key) => key !== "pan");
        const updatedDateKeys = dateKeys.map((key) => {
          const date = new Date(key);
          const options = { day: "2-digit", month: "2-digit", year: "2-digit" };
          const formattedDate = date.toLocaleDateString("en-IN", options);
          sortedCouponData[formattedDate] = couponData[key];
          return formattedDate;
        });
        updatedDateKeys.sort((a, b) => {
          const dateA = new Date(a);
          const dateB = new Date(b);
          return dateA - dateB;
        });
        Object.entries(sortedCouponData).forEach(([key, value], index) => {
          if (key !== "pan") {
            let totalBuy = 0;
            let totalSell = 0;
            let totalSum = 0;
            let totalFutures = 0;
            let totalIndexes = 0;
            if (value && value.RESIDEX) {
              totalFutures += value.RESIDEX.Futures || 0;
              totalIndexes += value.RESIDEX.Index || 0;
            }
            const startDate = new Date(relationshipDate);
            const [day, month, year] = key.split("/");
            const endDate = new Date(`20${year}-${month}-${day}`);
            const today = new Date();
            //  calculating days
            const timeDifference = today.getTime() - endDate.getTime();
            const daysDifference =
              timeDifference > 0
                ? Math.floor(timeDifference / (1000 * 60 * 60 * 24))
                : 0;

            Days.push({ [`D${index}`]: daysDifference / 91 });
            //
            function isDateInRange(date, startDate, endDate) {
              const dateStart = new Date(date.setHours(0, 0, 0, 0));
              const start = new Date(startDate.setHours(0, 0, 0, 0));
              const end = new Date(endDate.setHours(0, 0, 0, 0));

              return dateStart >= start && dateStart <= end;
            }

            blockchainData.filter((item, index) => {
              const itemDate = new Date(item.Timestamp);
              if (
                item.Buyer === couponData.pan &&
                item["Security"] === "RESIDEX City " + productName &&
                isDateInRange(itemDate, startDate, endDate)
              ) {
                totalBuy += item["Units"];
              }
            });

            blockchainData.filter((item, index) => {
              const itemDate = new Date(item.Timestamp);
              if (
                item.Seller === couponData.pan &&
                item["Security"] === "RESIDEX City " + productName &&
                isDateInRange(itemDate, startDate, endDate)
              ) {
                totalSell += item["Units"];
              }
            });
            totalSum = totalBuy - totalSell;
            if (totalSum !== 0) {
              residex_units_calculated.push({ [`X${index}`]: totalSum });
            }
          }
        });
      }

      let sumProd = 0;
      if (Days.length > 0 && Days.length === residex_units_calculated.length) {
        for (let i = 0; i < Days.length; i++) {
          const dayValue = Object.values(Days[i])[0];
          const residexValue = Object.values(residex_units_calculated[i])[0];
          sumProd += dayValue * residexValue;
        }
        return sumProd / ownershipData?.RESIDEX?.[productName]?.["Index"];
      } else {
        return 0;
      }
    } else {
      const today = new Date();
      const currentYear = today.getFullYear();
      let endDate;
      const currentMonth = today.getMonth() + 1;

      if (currentMonth >= 4 && currentMonth <= 6) {
        // Q1: April to June
        endDate = new Date(currentYear, 5, 30); // June 30
      } else if (currentMonth >= 7 && currentMonth <= 9) {
        // Q2: July to September
        endDate = new Date(currentYear, 8, 30); // September 30
      } else if (currentMonth >= 10 && currentMonth <= 12) {
        // Q3: October to December
        endDate = new Date(currentYear, 11, 31); // December 31
      } else {
        // Q4: January to March (of the next year)
        endDate = new Date(currentYear + 1, 2, 31); // March 31 (next year)
      }

      // Calculate the time difference
      const timeDifference = endDate.getTime() - today.getTime();
      const daysDifference =
        timeDifference > 0
          ? Math.floor(timeDifference / (1000 * 60 * 60 * 24))
          : 0;
      return daysDifference / 91;
    }
  }

  const startDate = new Date(relationshipDate);

  function getNextPayment(cities, productName, cover, units) {
    const p = productName;
    const productData = cities.find((item) => Object.keys(item)[0] === p);
    var nextPayment = 0;
    if (cover === "Index") {
      nextPayment = productData ? productData[p]["Price"] * 0.03 * units : 0;
    } else {
      nextPayment = 0;
    }

    nextPayment = parseInt(nextPayment);
    return nextPayment;
  }

  function getTotalInterestResidex(cities, productName, cover) {
    const p = productName;
    const productData = cities.find((item) => Object.keys(item)[0] === p);
    var nextPayment = 0;
    const data = ownershipData?.RESIDEX[p];
    if (cover === "Index") {
      let investedValueResidex = 0;
      blockchainData.forEach((item) => {
        const itemDate = new Date(item.Timestamp);
        const endDate = new Date();
        if (itemDate >= startDate && itemDate <= endDate) {
          if (
            item.Buyer === pan &&
            item.Security.includes(productName) &&
            !Number.isInteger(item.Price_per_unit)
          ) {
            investedValueResidex += item.Units * item.Price_per_unit;
          } else if (
            item.Seller === pan &&
            item.Security.includes(productName) &&
            !Number.isInteger(item.Price_per_unit)
          ) {
            investedValueResidex -= item.Units * item.Price_per_unit;
          }
        }
      });
      nextPayment = productData ? productData[p]["Price"] : 0;
      nextPayment = nextPayment - investedValueResidex / data?.Index;
    } else {
      let investedValueResidex = 0;
      blockchainData.forEach((item) => {
        const itemDate = new Date(item.Timestamp);
        const endDate = new Date();
        if (itemDate >= startDate && itemDate <= endDate) {
          if (
            item.Buyer === pan &&
            item.Security.includes(productName) &&
            Number.isInteger(item.Price_per_unit)
          ) {
            investedValueResidex += (item.Units * item.Price_per_unit) / 1000;
          } else if (
            item.Seller === pan &&
            item.Security.includes(productName) &&
            Number.isInteger(item.Price_per_unit)
          ) {
            investedValueResidex -= (item.Units * item.Price_per_unit) / 1000;
          }
        }
      });
      nextPayment = investedValueResidex / data?.Futures;
    }
    return nextPayment;
  }

  function getCAGR(cities, productName) {
    const p = productName;
    const productData = cities.find((item) => Object.keys(item)[0] === p);
    const couponIssue = productData ? productData[p]["CAGR"] : "";
    return couponIssue;
  }

  function getLatestNav(cities, productName) {
    const p = productName;
    const productData = cities.find((item) => Object.keys(item)[0] === p);
    const couponIssue = productData ? productData[p]["Price"] : "";
    return couponIssue;
  }

  function get3MTHPremium(cities, productName, cover) {
    const p = productName;
    const productData = cities.find((item) => Object.keys(item)[0] === p);
    var _3mthPremium = productData ? productData[p]["3 mth Premium"] : "";
    if (cover === "Futures") return _3mthPremium * 1000;
    else {
      _3mthPremium = 0;
    }
    return _3mthPremium;
  }

  const formatRupees = (value) => {
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      maximumFractionDigits: 0,
    })
      .format(value)
      .replace("₹", "");
  };

  const handleBarClick = (
    event,
    chartContext,
    { dataPointIndex, seriesIndex }
  ) => {
    const seriesName = chartData.options.xaxis.categories[dataPointIndex]?.[0];
    const data = ownershipData?.RESIDEX[seriesName];
    const category = chartData?.series[seriesIndex]?.name;
    const value = data?.[category];
    setTableContent(
      <>
        <div className="row m-0 p-0 ">
          <div className="p-3 border rounded-lg shadow-3 bg-white">
            {/* Heading */}
            <div className="max-2xl:flex-col 2xl:flex-row flex items-center justify-between flex-wrap max-2xl:gap-y-2">
              <div className="flex items-center">
                <h5 className="font-bold text-xs">
                  RESIDEX @NHB:{" "}
                  <span>
                    <a
                      className="text-[#008FFB] text-xs"
                      href="https://residex.nhbonline.org.in/2017-18/NHB_Residex.aspx"
                      target="blank"
                    >
                      residex.nhbonline.org.in
                    </a>
                  </span>
                </h5>
              </div>
              <div className="flex items-center gap-2">
                <div className="font-bold text-xs">
                  Quantity:{" "}
                  <span className="font-bold border px-2 py-1 text-xs md:text-sm text-[#008FFB] rounded-md">
                    {isNaN(value) ? 0 : value} units
                  </span>
                </div>
              </div>
            </div>
            <hr className="mt-2" />

            {/* RESIDEX Data */}
            <div className="flex flex-col my-4 justify-between">
              <div className="lg:flex items-end justify-between">
                <div className="col-lg-4 col-md-4 col-sm-12 flex lg:flex-col text-sm 2xl:text-base justify-between items-center w-full lg:w-[120px] xl:w-[140px] px-2 mb-4 lg:mb-0">
                  <h6 className="fw-bold text-center">
                    Next <br /> Payment <br />
                    <span className="font-size1">( ₹ )</span>
                  </h6>
                  <div className="border-4 border-[#4c3fbb] m-2 d-flex align-items-center justify-content-center info-style rounded-circle">
                    <h3 className="text-[#4c3fbb] fw-bold text-xl">
                      {getNextPayment(
                        cities,
                        seriesName,
                        category,
                        value / 1000
                      ).toLocaleString("en-IN")}
                    </h3>
                  </div>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-12 flex lg:flex-col text-sm 2xl:text-base justify-between items-center w-full lg:w-[120px] xl:w-[140px] px-2 mb-4 lg:mb-0">
                  <h6 className="fw-bold text-center">
                    CAGR from <br />
                    inception <br />
                    <span className="font-size1">( % )</span>
                  </h6>
                  <div className="border-4 border-[#635DAD] m-2 d-flex align-items-center justify-content-center info-style rounded-circle">
                    <h3 className="text-[#655DAD] fw-bold text-xl">
                      {getCAGR(cities, seriesName) === ""
                        ? 0
                        : parseFloat(getCAGR(cities, seriesName))?.toFixed(2)}
                    </h3>
                  </div>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-12 flex lg:flex-col text-sm 2xl:text-base justify-between items-center w-full lg:w-[120px] xl:w-[140px] px-2 mb-4 lg:mb-0">
                  <h6 className="fw-bold text-center">
                    Latest
                    <br /> NAV <br />
                    <span className="font-size1">( ₹ )</span>
                  </h6>
                  <div className="border-4 border-[#776fcd] m-2 d-flex align-items-center justify-content-center info-style rounded-circle">
                    <h3 className="text-[#776fcd] fw-bold text-xl">
                      {getLatestNav(cities, seriesName) === ""
                        ? 0
                        : parseFloat(getLatestNav(cities, seriesName))?.toFixed(
                            2
                          )}
                    </h3>
                  </div>
                </div>
              </div>

              <div className="lg:flex items-end justify-between">
                <div className="col-lg-4 col-md-4 col-sm-12 flex lg:flex-col text-base 2xl:text-base justify-between items-center w-full lg:w-[120px] xl:w-[140px] px-2 mb-4 lg:mb-0">
                  <h6 className="fw-bold text-center">
                    Holding <br />
                    Period <br />
                    <span className="font-size1">(quarters)</span>
                  </h6>
                  <div className="border-4 border-[#20656b] m-2 d-flex align-items-center justify-content-center info-style rounded-circle">
                    <h6 className="text-[#20656b] fw-bold text-xl text-center">
                      {getHoldingPeriod(seriesName, category)?.toFixed(2)}
                    </h6>
                  </div>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-12 flex lg:flex-col text-base 2xl:text-base justify-between items-center w-full lg:w-[120px] xl:w-[140px] px-2 mb-4 lg:mb-0">
                  <h6 className="fw-bold text-center">
                    3 month <br />
                    Premium
                    <br />
                    <span className="font-size1">( ₹ )</span>
                  </h6>
                  <div className="border-4 border-[#0D828D] m-2 d-flex align-items-center justify-content-center info-style rounded-circle">
                    <h3 className="text-[#0D828D] fw-bold text-xl">
                      {get3MTHPremium(cities, seriesName, category) === ""
                        ? 0
                        : get3MTHPremium(cities, seriesName, category)}
                    </h3>
                  </div>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-12 flex lg:flex-col text-sm 2xl:text-base justify-between items-center w-full lg:w-[120px] xl:w-[140px] px-2 mb-4 lg:mb-0">
                  <h6 className="fw-bold text-center">
                    Unrealized <br /> Gain <br />
                    <span className="font-size1">( ₹ /unit )</span>
                  </h6>
                  <div className="border-4 border-[#35a6b0] m-2 d-flex align-items-center justify-content-center info-style rounded-circle">
                    <h3 className="text-[#35a6b0] fw-bold text-xl text-center">
                      {getTotalInterestResidex(cities, seriesName, category) ===
                      ""
                        ? 0
                        : 0}
                    </h3>
                  </div>
                </div>
              </div>
            </div>

            <hr className="my-0 py-0" />
            {/* Buttons */}
            <div className="flex flex-wrap justify-between mt-2 gap-2">
              <button
                className="button shadow-sm"
                onClick={() => {
                  handleClick("PMY", seriesName);
                }}
                style={{ cursor: "pointer" }}
              >
                <i>
                  <img
                    className="right-sec-icon w-[30px] h-[30px] brightness-150 "
                    src="/images/PrimaryMarket.png"
                    alt=""
                  />
                </i>
                <p>SCDND auction</p>

                <i>
                  <FaArrowCircleRight size={16} />
                </i>
              </button>

              <button
                className="button shadow-sm"
                onClick={() => {
                  handleClick("P2P", seriesName);
                }}
                style={{ cursor: "pointer" }}
              >
                <i>
                  <svg
                    id="fi_2737448"
                    enableBackground="new 0 0 512.279 512.279"
                    className="right-sec-icon w-10"
                    viewBox="0 0 512.279 512.279"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g>
                      <g>
                        <path
                          d="m342.009 472.028-14.573-16.978h-141.801l-15.366 16.978-28.24 32.75h228.22z"
                          fill="#efedef"
                        ></path>
                        <path
                          d="m261.435 455.05h-75.8l-15.366 16.978-28.24 32.75h75.8l28.24-32.75z"
                          fill="#e5e1e5"
                        ></path>
                        <path
                          d="m170.269 360.018h171.74v112.01h-171.74z"
                          fill="#c9bfc8"
                        ></path>
                        <path
                          d="m342.009 360.018h-96.145-75.595v112.01h75.595v-3.763c0-10.856 8.801-19.657 19.657-19.657h76.488z"
                          fill="#baafb9"
                        ></path>
                        <path
                          d="m484.748 403.513 19.111-20.095v-264.19c0-22.19-17.99-40.18-40.18-40.18h-415.08c-22.19 0-40.18 17.99-40.18 40.18v264.19l34.877 20.095z"
                          fill="#99e6fc"
                        ></path>
                        <g>
                          <g id="XMLID_1052_">
                            <path
                              d="m175.073 383.418v-264.19c0-22.19 17.99-40.18 40.18-40.18h-166.654c-22.19 0-40.18 17.99-40.18 40.18v264.19l34.877 20.095h137.164c-3.42-5.913-5.387-12.772-5.387-20.095z"
                              fill="#62dbfb"
                            ></path>
                            <g>
                              <path
                                d="m8.419 383.418h495.44c0 22.2-17.99 40.19-40.18 40.19h-415.08c-22.19 0-40.18-17.99-40.18-40.19z"
                                fill="#efedef"
                              ></path>
                            </g>
                            <path
                              d="m175.073 383.418h-166.654c0 22.2 17.99 40.19 40.18 40.19h166.651c-22.189-.002-40.177-17.991-40.177-40.19z"
                              fill="#e5e1e5"
                            ></path>
                          </g>
                        </g>
                        <path
                          d="m377.602 227.604h-26.258c-3.73 0-6.754-3.024-6.754-6.754v-88.294c0-3.73 3.024-6.754 6.754-6.754h26.258c3.73 0 6.754 3.024 6.754 6.754v88.294c0 3.73-3.024 6.754-6.754 6.754z"
                          fill="#eb5569"
                        ></path>
                        <path
                          d="m289.152 195.641h-26.258c-3.73 0-6.754-3.024-6.754-6.754v-137.294c0-3.73 3.024-6.754 6.754-6.754h26.258c3.73 0 6.754 3.024 6.754 6.754v137.294c0 3.73-3.024 6.754-6.754 6.754z"
                          fill="#6cf5c2"
                        ></path>
                        <path
                          d="m200.701 210.482h-26.258c-3.73 0-6.754-3.024-6.754-6.754v-88.294c0-3.73 3.024-6.754 6.754-6.754h26.258c3.73 0 6.754 3.024 6.754 6.754v88.294c.001 3.73-3.023 6.754-6.754 6.754z"
                          fill="#eb5569"
                        ></path>
                        <path
                          d="m112.251 251.324h-26.258c-3.73 0-6.754-3.024-6.754-6.754v-88.294c0-3.73 3.024-6.754 6.754-6.754h26.258c3.73 0 6.754 3.024 6.754 6.754v88.294c.001 3.73-3.023 6.754-6.754 6.754z"
                          fill="#6cf5c2"
                        ></path>
                      </g>
                      <g>
                        <path d="m463.678 71.542h-91.705v-30.101c0-4.142-3.357-7.5-7.5-7.5s-7.5 3.358-7.5 7.5v30.101h-31.067c-4.143 0-7.5 3.358-7.5 7.5s3.357 7.5 7.5 7.5h31.067v31.758h-5.629c-7.86 0-14.255 6.395-14.255 14.254v88.294c0 7.86 6.395 14.254 14.255 14.254h5.629v59.501c0 4.142 3.357 7.5 7.5 7.5s7.5-3.358 7.5-7.5v-59.501h5.629c7.859 0 14.254-6.395 14.254-14.254v-88.294c0-7.86-6.395-14.254-14.254-14.254h-5.629v-31.758h91.705c18.021 0 32.684 14.662 32.684 32.684v256.695h-480.445v-256.694c0-18.022 14.662-32.684 32.684-32.684h131.472v14.637h-5.629c-7.86 0-14.254 6.395-14.254 14.254v88.294c0 7.86 6.395 14.254 14.254 14.254h5.629v66.273c0 4.142 3.358 7.5 7.5 7.5s7.5-3.358 7.5-7.5v-66.273h5.629c7.86 0 14.254-6.395 14.254-14.254v-88.294c0-7.86-6.395-14.254-14.254-14.254h-5.629v-14.638h31.067c4.142 0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5h-31.067v-3.704c0-4.142-3.358-7.5-7.5-7.5s-7.5 3.358-7.5 7.5v3.704h-131.472c-26.293 0-47.684 21.391-47.684 47.684v264.195c0 26.293 21.391 47.684 47.684 47.684h114.169v38.134l-24.173 28.04h-9.595c-4.142 0-7.5 3.358-7.5 7.5s3.358 7.5 7.5 7.5h253.583c4.143 0 7.5-3.358 7.5-7.5s-3.357-7.5-7.5-7.5h-8.904l-24.173-28.04v-38.134h114.17c26.293 0 47.684-21.391 47.684-47.684v-264.194c0-26.293-21.391-47.685-47.684-47.685zm-86.822 148.562h-24.766v-86.803h24.767v86.803zm-176.9-17.122h-24.767v-86.802h24.767zm263.722 213.123h-151.791c-4.143 0-7.5 3.358-7.5 7.5s3.357 7.5 7.5 7.5h22.621v33.421h-18.682c-4.143 0-7.5 3.358-7.5 7.5s3.357 7.5 7.5 7.5h22.745l15.305 17.753h-195.474l15.305-17.753h22.745c4.142 0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5h-18.682v-33.421h102.154c4.143 0 7.5-3.358 7.5-7.5s-3.357-7.5-7.5-7.5h-231.323c-15.441 0-28.406-10.766-31.806-25.184h478.688c-3.399 14.418-16.364 25.184-31.805 25.184z"></path>
                        <path d="m79.239 218.963c-4.142 0-7.5 3.358-7.5 7.5v18.106c0 7.86 6.394 14.254 14.254 14.254h5.629v39.941c0 4.142 3.358 7.5 7.5 7.5s7.5-3.358 7.5-7.5v-39.941h5.629c7.86 0 14.254-6.394 14.254-14.254v-88.294c0-7.86-6.395-14.255-14.254-14.255h-5.629v-11.996c0-4.142-3.358-7.5-7.5-7.5s-7.5 3.358-7.5 7.5v11.996h-5.629c-7.86 0-14.254 6.395-14.254 14.255v38.226c0 4.142 3.358 7.5 7.5 7.5s7.5-3.358 7.5-7.5v-37.48h12.374.01s.006 0 .01 0h12.374v86.803h-24.768v-17.36c0-4.143-3.358-7.501-7.5-7.501z"></path>
                        <path d="m262.893 203.141h5.63v39.94c0 4.142 3.357 7.5 7.5 7.5s7.5-3.358 7.5-7.5v-39.94h5.629c7.859 0 14.254-6.395 14.254-14.255v-58.827c0-4.142-3.357-7.5-7.5-7.5s-7.5 3.358-7.5 7.5v58.082h-24.767v-135.803h24.767v45.759c0 4.142 3.357 7.5 7.5 7.5s7.5-3.358 7.5-7.5v-46.505c0-7.859-6.395-14.254-14.254-14.254h-5.629v-29.838c0-4.142-3.357-7.5-7.5-7.5s-7.5 3.358-7.5 7.5v29.838h-5.63c-7.86 0-14.254 6.395-14.254 14.254v137.294c0 7.861 6.394 14.255 14.254 14.255z"></path>
                        <path d="m407.755 205.617c1.355 2.514 3.939 3.942 6.608 3.942 1.201 0 2.421-.29 3.553-.9l34.547-18.627-2.485 6.522c-1.475 3.871.468 8.204 4.339 9.679.879.334 1.781.493 2.669.493 3.021 0 5.87-1.84 7.01-4.832l8.444-22.166c1.475-3.871-.468-8.204-4.339-9.679l-23.527-8.963c-3.87-1.474-8.204.468-9.679 4.339s.468 8.204 4.339 9.679l5.455 2.078-33.892 18.274c-3.646 1.966-5.008 6.515-3.042 10.161z"></path>
                        <path d="m45.263 352.16c1.218 2.846 3.987 4.551 6.898 4.551.984 0 1.985-.195 2.948-.607l96.778-41.419 8.795 23.043c.77 2.017 2.371 3.604 4.394 4.356 2.022.751 4.272.596 6.172-.429l159.884-86.205c3.646-1.966 5.008-6.515 3.042-10.161-1.965-3.645-6.513-5.008-10.161-3.042l-152.364 82.15-8.531-22.353c-.728-1.907-2.201-3.435-4.08-4.231-1.879-.797-4.001-.792-5.878.01l-103.953 44.49c-3.807 1.631-5.573 6.039-3.944 9.847z"></path>
                      </g>
                    </g>
                  </svg>
                </i>
                <p>Trade P-2-P</p>
                <i>
                  <FaArrowCircleRight size={16} />
                </i>
              </button>
            </div>
          </div>
        </div>
      </>
    );
  };

  const chartData = {
    series: [
      { name: "Index", data: citiesData1, color: "#4A998F" },
      { name: "Futures", data: citiesData2, color: "#35a6b07c" },
    ],
    options: {
      chart: {
        type: "bar",
        events: {
          dataPointSelection: (event, chartContext, config) => {
            handleBarClick(event, chartContext, config);
          },
        },
        stacked: true,
        toolbar: {
          tools: {
            download: false,
          },
        },
      },
      fill: {
        type: ["solid", "pattern"],
        // gradient: {
        //   type: "vertical",
        //   stops: [0, 50, 100],
        //   colorStops: [
        //     { offset: 0, color: "#A8E6E2" }, // Lighter shade of #5FC0B3
        //     { offset: 50, color: "#5FC0B3" }, // Original color
        //     { offset: 100, color: "#4A998F" }, // Darker shade of #5FC0B3
        //   ],
        // },
        pattern: {
          style: "circles",
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 2,
          horizontal: false,
          dataLabels: {
            total: {
              enabled: true,
              offsetX: -20,
              style: {
                colors: ["#333"],
              },
              formatter: function (val, opts) {
                const total = chartData.series.reduce(
                  (acc, series) => acc + series.data[opts.dataPointIndex],
                  0
                );
                return "₹" + formatRupees(total);
              },
            },
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: category,
        labels: {
          rotate: -45,
          rotateAlways: true,
        },
      },
      yaxis: {
        title: { text: "Amount (₹)" },
        labels: {
          formatter: function (value) {
            return formatRupees(value / 1000) + "K";
          },
        }, // Set the maximum value for the y-axis
      },
    },
  };

  useEffect(() => {
    const defaultDataPointIndex = 0;
    const defaultCitiesIndex = 0;
    handleBarClick(null, null, {
      dataPointIndex: defaultDataPointIndex,
      seriesIndex: defaultCitiesIndex,
    });
  }, [pan]);

  return (
    <>
      <Header />
      <div className="after-header px-0 m-0  max-w-[1800px] mx-auto">
        {/* Path Navigation */}
        <div className="row w-[90%] mx-auto">
          <h6 className="pt-4">
            <a href="/">
              <span className="header-link">Home</span>
              <span className="text-secondary"> &gt; </span>
            </a>
            <a href="/ownership">
              <span className="header-link">Ownership</span>
              <span className="text-secondary"> &gt; </span>
            </a>
            <a href="#">
              <span className="text-dark text-decoration-underline">
                RESIDEX
              </span>
            </a>
          </h6>
        </div>

        <div className="lg:flex justify-between bg-[#EDEDED] rounded-t-lg p-3 gap-2 w-[90%] mx-auto">
          {/* Left Container */}
          <div className="w-full lg:w-[70%] bg-white rounded-lg overflow-scroll xl:overflow-y-hidden">
            <div className="min-w-[550px] max-h-[600px]">
              {dataFetched && (
                <div id="chart">
                  <ReactApexChart
                    className="w-full "
                    options={chartData.options}
                    series={chartData.series}
                    type="bar"
                    height="580"
                  />
                </div>
              )}
            </div>
          </div>

          {/* Right Container */}
          <div className="w-full lg:w-[450px] 2xl:[500px] pt-3 lg:-mt-4">
            <div>{tableContent}</div>
          </div>
        </div>

        <div className="rounded-b-lg m-0 bg-[#EDEDED] px-3 pb-3 w-[90%] mx-auto">
          <div className="bg-white rounded-lg border">
            <div className="text-[15px] font-semibold p-3 m-0 flex- flex-col gap-3">
              <div className="flex gap-1 items-start lg:items-center">
                <span className="">
                  <TbPointFilled />
                </span>
                <span className="m-0 p-0">
                  Your Residex holdings are a sum of 15 different Cities,
                  displayed as a histogram. Please click on it to see the
                  details and transactions of each series.
                </span>
              </div>

              <div className="flex gap-1 items-start lg:items-center">
                <span className="">
                  <TbPointFilled />
                </span>
                <span className="">
                  All prices of Residex are based on{" "}
                  <span>
                    <a
                      className="text-primary"
                      href="https://residex.nhbonline.org.in/2017-18/NHB_Residex.aspx"
                    >
                      {" "}
                      NHB Residex{" "}
                    </a>
                  </span>
                  (Base Year FY2017-18) of "HPI@ Market Prices of Under
                  Construction Properties".
                </span>
              </div>

              <div className="flex gap-1 items-start lg:items-center">
                <span className="">
                  <TbPointFilled />
                </span>
                <span>
                  The 'Annualized Return' is calculated as a weighted average of
                  the amount purchased for each City (in Rs.) multiplied by the
                  period of time each one is held. Please note that this
                  automatically includes all intermittent buying/selling of each
                  Residex, whether through the Primary Market or the Trading
                  Portal.
                </span>
              </div>

              <div className="flex gap-1 items-start lg:items-center">
                <span className="">
                  <TbPointFilled />
                </span>
                <span>
                  The 'Acquisition Price' is the average purchase price (net of
                  sales/redemptions) over the entire period of holding the
                  particular City Residex.
                </span>
              </div>

              <div className="flex gap-1 items-start">
                <span className="">
                  <TbPointFilled />
                </span>
                <span>
                  The Dividend is fixed at 3% per year, disbursed quarterly and
                  calculated on the current/latest price of the City Residex as
                  published by NHB.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RESIDEXHoldings;
