import React, { useState, useEffect } from "react";
import { BsArrowBarRight } from "react-icons/bs";
import Chart from "react-apexcharts";

const casesData = {
  "Nov 16": {
    title: "Demonetization",
    points: [
      "Oil crosses 100$",
      "Food prices jumped 20-30%",
      "Rubel crashes by 50%",
      "Gold Rises 4%",
    ],
    imageUrl: "/images/demn.png",
    link: "walrbonds/performance/",
  },
  "Oct 18": {
    title: "ILFS Crisis",
    points: [
      "Oil crosses 100$",
      "Food prices jumped 20-30%",
      "Rubel crashes by 50%",
      "Gold Rises 4%",
    ],
    imageUrl: "/images/ilfs.png",
    link: "https://www.bloomberg.com/graphics/covid-vaccine-tracker-global-distribution/",
  },
  "Feb 22": {
    title: "Russia-Ukraine WAR",
    points: [
      "Oil crosses 100$",
      "Food prices jumped 20-30%",
      "Rubel crashes by 50%",
      "Gold Rises 4%",
    ],
    imageUrl: "/images/rus-ukr.jpeg",
    link: "https://www.bloomberg.com/graphics/covid-vaccine-tracker-global-distribution/",
  },
  "Mar 20": {
    title: "Covid-19",
    points: [
      "Oil crosses 100$",
      "Food prices jumped 20-30%",
      "Rubel crashes by 50%",
      "Gold Rises 4%",
    ],
    imageUrl: "/images/covid.png",
    link: "https://www.bloomberg.com/graphics/covid-vaccine-tracker-global-distribution/",
  },
  "May 22": {
    title: "US Inflationary 9 Care",
    points: [
      "Oil crosses 100$",
      "Food prices jumped 20-30%",
      "Rubel crashes by 50%",
      "Gold Rises 4%",
    ],
    imageUrl: "/images/us-9.jpg",
    link: "https://www.bloomberg.com/graphics/covid-vaccine-tracker-global-distribution/",
  },
  "Aug 23": {
    title: "Israel-Palestine War",
    points: [
      "Oil crosses 100$",
      "Food prices jumped 20-30%",
      "Rubel crashes by 50%",
      "Gold Rises 4%",
    ],
    imageUrl: "/images/Isr-pal-war.png",
    link: "https://www.bloomberg.com/graphics/covid-vaccine-tracker-global-distribution/",
  },
};

const CollateralChart = () => {
  const [tableContent, setTableContent] = useState(
    <a
      href={casesData["Aug 23"].link}
      className="w-full md:w-1/2 h-max bg-[#dbedf9] rounded-xl p-4 md:flex justify-between transition duration-300 ease-in-out"
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className="w-full md:w-2/3">
        <h5 className="text-lg font-bold">Case: {casesData["Aug 23"].title}</h5>
        <ul className="text-sm pl-2">
          {casesData["Aug 23"].points.map((point, index) => (
            <li key={index} className="font-medium">
              {point}
            </li>
          ))}
        </ul>
        <div className="mt-2 font-semibold text-[#5ab2ff] hover:text-[#3aa0ff] flex items-center">
          See the news &nbsp;
          <BsArrowBarRight size={15} />
        </div>
      </div>
      <div className="object-cover md:w-1/3 h-full rounded-xl">
        <img
          className="rounded-lg"
          src={casesData["Aug 23"].imageUrl}
          alt={casesData["Aug 23"].title}
        />
      </div>
    </a>
  );

  const handleClick = (event, chartContext, config) => {
    if (config.dataPointIndex !== -1) {
      const clickedLabel = options.labels[config.dataPointIndex];
      const caseInfo = casesData[clickedLabel];

      if (caseInfo) {
        setTableContent(
          <a
            href={caseInfo.link}
            className="w-full md:w-1/2 h-max bg-[#dbedf9] rounded-xl p-4 md:flex justify-between transition duration-300 ease-in-out"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="w-full md:w-2/3">
              <h5 className="text-lg font-bold">Case: {caseInfo.title}</h5>
              <ul className="text-sm pl-2">
                {caseInfo.points.map((point, index) => (
                  <li key={index} className="font-medium">
                    {point}
                  </li>
                ))}
              </ul>
              <div className="mt-2 font-semibold text-[#5ab2ff] hover:text-[#3aa0ff] flex items-center">
                See the news &nbsp;
                <BsArrowBarRight size={15} />
              </div>
            </div>
            <div className="object-cover md:w-1/3 h-full rounded-xl">
              <img
                className="rounded-lg"
                src={caseInfo.imageUrl}
                alt={caseInfo.title}
              />
            </div>
          </a>
        );
      }
    }
  };

  function generateDateLabels(startYear, startMonth, endYear, endMonth) {
    const labels = [];
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    for (let year = startYear; year <= endYear; year++) {
      for (
        let month = year === startYear ? startMonth : 0;
        month <= (year === endYear ? endMonth : 11);
        month++
      ) {
        labels.push(`${months[month]} ${year.toString().slice(-2)}`);
      }
    }

    return labels;
  }

  // Generate labels from April 2016 (2016, 3) to June 2024 (2024, 5)
  const labels = generateDateLabels(2016, 3, 2024, 10);

  const series = [
    {
      name: "WALR",
      data: [
        100, 109, 108, 107, 112, 110, 109, 108, 107, 112, 110, 105, 109, 108,
        107, 112, 110, 109, 108, 107, 112, 110, 100, 109, 108, 107, 112, 110,
        109, 108, 107, 112, 110, 105, 109, 108, 107, 112, 110, 109, 108, 107,
        112, 110, 100, 109, 108, 107, 112, 110, 109, 108, 100, 109, 108, 107,
        112, 110, 109, 108, 107, 112, 110, 105, 109, 108, 107, 112, 110, 109,
        108, 107, 112, 110, 100, 109, 108, 107, 112, 110, 109, 108, 107, 112,
        110, 110, 109, 108, 107, 112, 110, 105, 109, 108, 107, 112, 110, 109,
        108, 107, 112, 110, 109,
      ].reverse(),
    },
    {
      name: "RESIDEX",
      data: [
        100, 115, 114, 122, 119, 111, 109, 104, 105, 119, 115, 120, 123, 114,
        112, 108, 107, 112, 115, 120, 121, 122, 100, 115, 114, 122, 119, 111,
        109, 104, 105, 119, 115, 120, 123, 114, 112, 108, 107, 112, 115, 120,
        121, 122, 100, 115, 114, 122, 119, 111, 109, 104, 105, 119, 115, 120,
        123, 114, 112, 108, 107, 112, 115, 120, 121, 122, 100, 115, 114, 122,
        119, 111, 109, 104, 105, 119, 115, 120, 123, 114, 112, 108, 107, 112,
        115, 120, 121, 122, 100, 115, 114, 122, 119, 111, 109, 104, 105, 119,
        111, 109, 104, 105, 119,
      ].reverse(),
    },
  ];

  const options = {
    legend: { position: "top" },
    colors: ["#0590F8", "#009688"],
    chart: {
      type: "line",
      id: "areachart-2",
      events: {
        mouseMove: (event, chartContext, config) => {
          handleClick(event, chartContext, config);
        },
        click: (event, chartContext, config) => {
          handleClick(event, chartContext, config);
        },
      },
      animations: { enabled: false },
    },

    responsive: [
      {
        breakpoint: 2300,
        options: {
          chart: {
            width: 900,
            height: 500,
          },
        },
      },
      {
        breakpoint: 1300,
        options: {
          chart: {
            width: 900,
            height: 450,
          },
        },
      },
      {
        breakpoint: 976,
        options: {
          chart: {
            width: 900,
            height: 350,
          },
        },
      },
      {
        breakpoint: 576,
        options: {
          chart: {
            width: 900,
            height: 350,
          },
        },
      },
    ],
    // it will modify the y-axis according to need
    yaxis: {
      min: 80,
      max: 160,
      tickAmount: 4,
      labels: { formatter: (value) => value + "%" },
    },
    annotations: {
      yaxis: [{ y: 100, borderColor: "red", label: { borderColor: "red" } }],
      xaxis: Object.keys(casesData).map((label) => ({
        x: label,
        borderColor: "orange",
        strokeDashArray: 5,
        label: { borderColor: "orange", text: casesData[label].title },
      })),
    },
    dataLabels: { enabled: false },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    labels: labels.reverse(),
    xaxis: {
      tickAmount: 12,
      type: "date",
      labels: { rotate: -45, rotateAlways: true },
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function (value) {
          return value + "%"; // Format the tooltip values
        },
      },
    },
  };

  return (
    <div className="m-0 p-2 w-full lg:min-w-[700px] xl:max-w-[970px] border-2 rounded-xl bg-[#f5fbff] overflow-hidden">
      <div className="flex flex-col w-full px-2">
        <div id="chart" className="p-2 w-full overflow-x-scroll">
          <div className="flex justify-center">
            <span className="text-danger">*</span>
            <span className="font-size1">
              Hover on the Graph to see the details.
            </span>
          </div>
          <Chart
            className="w-max h-full"
            options={options}
            series={series}
            type="line"
            width="1000"
            height="400"
          />
        </div>
        <div className="mt-2 w-full flex-col items-center justify-center text-sm font-semibold bg-[#f5fbff]">
          <div className="w-full flex justify-center items-center transition duration-300 ease-in-out overflow-x-scroll">
            {tableContent && tableContent}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollateralChart;
