import React from "react";
import Blogs from "../../components/Blogs/Blogs.jsx";
import Header from "../../components/Header/Header.jsx";
import "./PitchDeck.css";

const PitchDeck = () => {
  const handleDownload = () => {
    // Replace with the actual PDF file path
    const pdfUrl = "./PDFs/Pitch Deck.pdf";
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = "SCDND Estates- Investor Presentation.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div>
      <Header />
      
      
      
      
      <div className="flex justify-center">
        <div className="flex flex-col items-center justify-center relative w-[90%] ">
          <div class="overflow-hidden bg-white after-header z-[10000] max-lg:mt-3 lg:mt-5">
            <div className="w-full flex justify-center max-md:mb-4 md:mb-5 xl:mb-20"><p className="font-bold leading-5 md:leading-7 w-full text-center  mx-0 text-base md:text-xl lg:text-2xl 2xl:text-3xl">
                    
                    <span className="text-sky-400 ">
                      {" "}
                      Beyond Mutual Funds
                    </span>- Constructing  synthetic <br className="max-md:hidden" /> ETFs using public data-surveys
                  </p></div>
            <div class="mx-auto w-[100%] px-6 lg:px-8">
              <div class="mx-auto max-xl:flex-col-reverse xl:flex-row flex xl:space-x-8 max-xl:gap-y-5">
                <div class="xl:pr-3  flex flex-col justify-between items-start w-full object-cover xl:w-[50%]">
                  <div class="">
                    
                    <p class=" text-xs md:text-sm xl:text-base font-semibold text-neutral-800 flex items-start">
                      <span class="text-xs w-max h-max font-semibold inline-block mt-1 uppercase fill-sky-400 mr-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-4 h-4"
                          id="Layer_1"
                          data-name="Layer 1"
                          viewBox="0 0 24 24"
                        >
                          <path d="m23.126,9.868h0l-2.151-2.154v-1.718c0-1.651-1.342-2.995-2.991-2.995h-1.716l-2.151-2.153c-1.131-1.131-3.101-1.131-4.231,0l-2.151,2.153h-1.716c-1.65,0-2.991,1.343-2.991,2.995v1.718l-2.152,2.154c-1.165,1.168-1.165,3.067,0,4.235l2.151,2.154v1.718c0,1.651,1.342,2.995,2.991,2.995h1.716l2.151,2.153c.565.565,1.317.877,2.116.877s1.55-.312,2.115-.877l2.151-2.153h1.716c1.65,0,2.991-1.343,2.991-2.995v-1.718l2.152-2.154c1.165-1.168,1.165-3.067,0-4.235Zm-4.922.343l-5.054,4.995c-.614.61-1.423.916-2.231.916s-1.613-.305-2.229-.913l-2.599-2.499c-.392-.389-.396-1.021-.007-1.414.39-.391,1.021-.396,1.415-.007l2.598,2.498c.453.449,1.19.45,1.644,0l5.055-4.996c.394-.39,1.026-.386,1.415.007s.385,1.025-.007,1.414Z" />
                        </svg>
                      </span>
                      <span>
                        Our Company's core business idea is to break the link
                        between (listed and liquid) underlying stocks/bonds and
                        the ETF itself, thereby opening up the road to
                        financialization of a wide range of physical (and
                        illiquid) assets from real estate to private credit to
                        MSME equity investments.
                      </span>
                    </p>
                    <p class="max-xl:mt-2 xl:mt-2 2xl:mt-4 text-xs md:text-sm xl:text-base font-semibold text-neutral-800 flex items-start">
                      <span class="text-xs w-max h-max font-semibold inline-block mt-1 uppercase fill-sky-400 mr-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-4 h-4"
                          id="Layer_1"
                          data-name="Layer 1"
                          viewBox="0 0 24 24"
                        >
                          <path d="m23.126,9.868h0l-2.151-2.154v-1.718c0-1.651-1.342-2.995-2.991-2.995h-1.716l-2.151-2.153c-1.131-1.131-3.101-1.131-4.231,0l-2.151,2.153h-1.716c-1.65,0-2.991,1.343-2.991,2.995v1.718l-2.152,2.154c-1.165,1.168-1.165,3.067,0,4.235l2.151,2.154v1.718c0,1.651,1.342,2.995,2.991,2.995h1.716l2.151,2.153c.565.565,1.317.877,2.116.877s1.55-.312,2.115-.877l2.151-2.153h1.716c1.65,0,2.991-1.343,2.991-2.995v-1.718l2.152-2.154c1.165-1.168,1.165-3.067,0-4.235Zm-4.922.343l-5.054,4.995c-.614.61-1.423.916-2.231.916s-1.613-.305-2.229-.913l-2.599-2.499c-.392-.389-.396-1.021-.007-1.414.39-.391,1.021-.396,1.415-.007l2.598,2.498c.453.449,1.19.45,1.644,0l5.055-4.996c.394-.39,1.026-.386,1.415.007s.385,1.025-.007,1.414Z" />
                        </svg>
                      </span>
                      <span>
                        The underlying securities in our model can be
                        significantly different (and vary through time) from the
                        index or benchmark, as long as cumulatively their price
                        moves in tandem.
                      </span>
                    </p>
                    <p class="max-xl:mt-2 xl:mt-2 2xl:mt-4 text-xs md:text-sm xl:text-base font-semibold text-neutral-800 flex items-start">
                      <span class="text-xs w-max h-max font-semibold inline-block mt-1 uppercase fill-sky-400 mr-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-4 h-4"
                          id="Layer_1"
                          data-name="Layer 1"
                          viewBox="0 0 24 24"
                        >
                          <path d="m23.126,9.868h0l-2.151-2.154v-1.718c0-1.651-1.342-2.995-2.991-2.995h-1.716l-2.151-2.153c-1.131-1.131-3.101-1.131-4.231,0l-2.151,2.153h-1.716c-1.65,0-2.991,1.343-2.991,2.995v1.718l-2.152,2.154c-1.165,1.168-1.165,3.067,0,4.235l2.151,2.154v1.718c0,1.651,1.342,2.995,2.991,2.995h1.716l2.151,2.153c.565.565,1.317.877,2.116.877s1.55-.312,2.115-.877l2.151-2.153h1.716c1.65,0,2.991-1.343,2.991-2.995v-1.718l2.152-2.154c1.165-1.168,1.165-3.067,0-4.235Zm-4.922.343l-5.054,4.995c-.614.61-1.423.916-2.231.916s-1.613-.305-2.229-.913l-2.599-2.499c-.392-.389-.396-1.021-.007-1.414.39-.391,1.021-.396,1.415-.007l2.598,2.498c.453.449,1.19.45,1.644,0l5.055-4.996c.394-.39,1.026-.386,1.415.007s.385,1.025-.007,1.414Z" />
                        </svg>
                      </span>
                      <span>
                        Given our contractual market-making obligations and
                        collateral-backed guarantee, both the liquidity and
                        counterparty risk are eliminated completely.
                      </span>
                    </p>
                  </div>
                  <div class="mt-3">
                    <div
                      class="bg-[#363636] group pl-4 pr-6 py-2 flex items-center justify-start w-max rounded-lg gap-x-2 cursor-pointer mt-3 max-md:scale-75 md:scale-90 md:-left-2 relative max-md:-left-8"
                      onClick={handleDownload}
                    >
                      <div class="downloadBtn bg-[#363636] group-hover:bg-sky-300">
                        <svg
                          class="pdfSvgIcon fill-sky-300 group-hover:fill-white"
                          viewBox="0 0 384 512"
                          height="14px"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"></path>
                        </svg>
                        <span class="pdficon2 fill-sky-300 group-hover:fill-white border-l-2 border-r-2 border-b-2 border-sky-300 group-hover:border-white"></span>
                      </div>
                      <div class="text-base font-semibold text-white">
                        Download
                      </div>
                    </div>
                  </div>
                </div>
                <div class="w-full object-cover xl:w-[50%] flex justify-center items-center">
                  <div
                    id="carouselExampleIndicators"
                    className="carousel slide relative "
                    data-mdb-ride="carousel"
                    data-mdb-carousel-init
                  >
                    {/*  */}

                    <div className="carousel-indicators carousel-indicators2" >
                      <div className="carousel-dots">
                        <button
                          type="button"
                          data-mdb-target="#carouselExampleIndicators"
                          data-mdb-slide-to="0"
                          className="active"
                          aria-current="true"
                          aria-label="Slide 1"
                        ></button>
                        <button
                          type="button"
                          data-mdb-target="#carouselExampleIndicators"
                          data-mdb-slide-to="1"
                          aria-label="Slide 2"
                        ></button>
                        <button
                          type="button"
                          data-mdb-target="#carouselExampleIndicators"
                          data-mdb-slide-to="2"
                          aria-label="Slide 3"
                        ></button>
                        <button
                          type="button"
                          data-mdb-target="#carouselExampleIndicators"
                          data-mdb-slide-to="3"
                          aria-label="Slide 4"
                        ></button>
                        <button
                          type="button"
                          data-mdb-target="#carouselExampleIndicators"
                          data-mdb-slide-to="4"
                          aria-label="Slide 5"
                        ></button>
                      </div>
                    </div>

                    <div className="carousel-inner ">
                      <div className="carousel-item active">
                        <img
                          src="/images/PitchDeck-Content.png"
                          className="rounded-xl "
                          alt="PitchDeck- 1"
                        />
                      </div>
                      <div className="carousel-item">
                        <img
                          src="/images/PitchDeck-2.png"
                          className="rounded-xl "
                          alt="PitchDeck- 2"
                        />
                      </div>
                      <div className="carousel-item">
                        <img
                          src="/images/PitchDeck-3.png"
                          className="rounded-xl "
                          alt="PitchDeck- 3"
                        />
                      </div>
                      <div className="carousel-item">
                        <img
                          src="/images/PitchDeck-4.png"
                          className="rounded-xl "
                          alt="PitchDeck- 4"
                        />
                      </div>
                      <div className="carousel-item">
                        <img
                          src="/images/PitchDeck-5.png"
                          className="rounded-xl "
                          alt="PitchDeck- 5"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="btn-conteiner2 m-0 p-0 mt-5 ">
                <div href="" class="btn-scroll2">
                  <span class="icon-scroll2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 66 43"
                      className="w-8 h-8 lg:w-12 lg:h-12 rotate-90"
                    >
                      <g
                        fill-rule="evenodd"
                        fill="none"
                        strokeWidth="1"
                        stroke="none"
                        id="scroll"
                      >
                        <path
                          fill="#faef9e"
                          d="M40.1543933,3.89485454 L43.9763149,0.139296592 C44.1708311,-0.0518420739 44.4826329,-0.0518571125 44.6771675,0.139262789 L65.6916134,20.7848311 C66.0855801,21.1718824 66.0911863,21.8050225 65.704135,22.1989893 C65.7000188,22.2031791 65.6958657,22.2073326 65.6916762,22.2114492 L44.677098,42.8607841 C44.4825957,43.0519059 44.1708242,43.0519358 43.9762853,42.8608513 L40.1545186,39.1069479 C39.9575152,38.9134427 39.9546793,38.5968729 40.1481845,38.3998695 C40.1502893,38.3977268 40.1524132,38.395603 40.1545562,38.3934985 L56.9937789,21.8567812 C57.1908028,21.6632968 57.193672,21.3467273 57.0001876,21.1497035 C56.9980647,21.1475418 56.9959223,21.1453995 56.9937605,21.1432767 L40.1545208,4.60825197 C39.9574869,4.41477773 39.9546013,4.09820839 40.1480756,3.90117456 C40.1501626,3.89904911 40.1522686,3.89694235 40.1543933,3.89485454 Z"
                          id="scroll-icon-one2"
                        ></path>
                        <path
                          fill="#faef9e"
                          d="M20.1543933,3.89485454 L23.9763149,0.139296592 C24.1708311,-0.0518420739 24.4826329,-0.0518571125 24.6771675,0.139262789 L45.6916134,20.7848311 C46.0855801,21.1718824 46.0911863,21.8050225 45.704135,22.1989893 C45.7000188,22.2031791 45.6958657,22.2073326 45.6916762,22.2114492 L24.677098,42.8607841 C24.4825957,43.0519059 24.1708242,43.0519358 23.9762853,42.8608513 L20.1545186,39.1069479 C19.9575152,38.9134427 19.9546793,38.5968729 20.1481845,38.3998695 C20.1502893,38.3977268 20.1524132,38.395603 20.1545562,38.3934985 L36.9937789,21.8567812 C37.1908028,21.6632968 37.193672,21.3467273 37.0001876,21.1497035 C36.9980647,21.1475418 36.9959223,21.1453995 36.9937605,21.1432767 L20.1545208,4.60825197 C19.9574869,4.41477773 19.9546013,4.09820839 20.1480756,3.90117456 C20.1501626,3.89904911 20.1522686,3.89694235 20.1543933,3.89485454 Z"
                          id="scroll-icon-two2"
                        ></path>
                        <path
                          fill="#faef9e"
                          d="M0.154393339,3.89485454 L3.97631488,0.139296592 C4.17083111,-0.0518420739 4.48263286,-0.0518571125 4.67716753,0.139262789 L25.6916134,20.7848311 C26.0855801,21.1718824 26.0911863,21.8050225 25.704135,22.1989893 C25.7000188,22.2031791 25.6958657,22.2073326 25.6916762,22.2114492 L4.67709797,42.8607841 C4.48259567,43.0519059 4.17082418,43.0519358 3.97628526,42.8608513 L0.154518591,39.1069479 C-0.0424848215,38.9134427 -0.0453206733,38.5968729 0.148184538,38.3998695 C0.150289256,38.3977268 0.152413239,38.395603 0.154556228,38.3934985 L16.9937789,21.8567812 C17.1908028,21.6632968 17.193672,21.3467273 17.0001876,21.1497035 C16.9980647,21.1475418 16.9959223,21.1453995 16.9937605,21.1432767 L0.15452076,4.60825197 C-0.0425130651,4.41477773 -0.0453986756,4.09820839 0.148075568,3.90117456 C0.150162624,3.89904911 0.152268631,3.89694235 0.154393339,3.89485454 Z"
                          id="scroll-icon-three2"
                        ></path>
                      </g>
                    </svg>
                  </span>
                </div>
              </div>
        </div>
        
      </div>
      <div className="after-header " style={{ position: "relative" }}>
        <Blogs />
      </div>{" "}
    </div>
  );
};

export default PitchDeck;
